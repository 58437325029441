import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Offcanvas, Alert } from 'react-bootstrap';
import { uploadCommerceDocument, getCommerceDocuments, deleteCommerceDocument } from '../../services/ComerciosService'; // Importar los servicios necesarios

const DocumentCanvas = forwardRef((props, ref) => {
    const [showCanvas, setShowCanvas] = useState(false);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [documentType, setDocumentType] = useState('commerce');
    const [documentNumber, setDocumentNumber] = useState(''); // Número de documento
    const [commerceId, setCommerceId] = useState(null);
    const [personType, setPersonType] = useState('juridica'); // Tipo de persona
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState(''); // Variantes como success, danger
    const [showAlert, setShowAlert] = useState(false); // Estado para controlar el fade-out de la alerta
    const [uploadedFiles, setUploadedFiles] = useState([]); // Lista de archivos cargados, inicialmente vacía

    useImperativeHandle(ref, () => ({
        showDocumentModal(id, type) {
            setCommerceId(id);
            setDocumentType(type);
            // Consumir el servicio para obtener los documentos del comercio
            fetchCommerceDocuments(id);
            setShowCanvas(true);
        }
    }));

    // Función para consumir el servicio de obtener documentos
    const fetchCommerceDocuments = async (id) => {
        try {
            const response = await getCommerceDocuments(id); // Consumir el servicio para obtener los documentos
            setUploadedFiles(response.data); // Asignar los documentos obtenidos al estado
        } catch (error) {
            console.error("Error al obtener los documentos:", error);
        }
    };

    // Función para eliminar un documento
    const handleDeleteDocument = async (documentId) => {
        try {
            await deleteCommerceDocument(commerceId, documentId); // Consumir el servicio de eliminación
            setAlertMessage('Documento eliminado exitosamente');
            setAlertVariant('success');
            // Filtrar el documento eliminado de la lista
            setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.id !== documentId));
        } catch (error) {
            setAlertMessage('Error al eliminar el documento.');
            setAlertVariant('danger');
        }
    };

    // Efecto para ocultar la alerta automáticamente después de 3 segundos con transición suave
    useEffect(() => {
        if (alertMessage) {
            setShowAlert(true); // Mostrar alerta con la clase 'show'

            const timeout = setTimeout(() => {
                setShowAlert(false); // Desaparecer alerta suavemente
                setTimeout(() => setAlertMessage(''), 300); // Esperar el fade-out antes de borrar el mensaje
            }, 3000); // 3 segundos

            // Limpiar el timeout si el componente se desmonta antes de los 3 segundos
            return () => clearTimeout(timeout);
        }
    }, [alertMessage]);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!file || (documentType === 'documento_identidad' && !documentNumber)) return;

        setLoading(true);
        try {
            const response = await uploadCommerceDocument(commerceId, { documentType, documentNumber }, file);
            setAlertMessage('Documento subido exitosamente');
            setAlertVariant('success');
            
            // Añadir el nuevo archivo cargado a la lista de archivos subidos
            const newFile = {
                id: response.data.id,
                documentType,
                documentUrl: response.data.documentUrl, // Asegúrate de que response.data tenga esta propiedad
            };
            setUploadedFiles((prevFiles) => [...prevFiles, newFile]);
        } catch (error) {
            setAlertMessage('Error al subir el documento. Intente nuevamente.');
            setAlertVariant('danger');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Offcanvas show={showCanvas} onHide={() => setShowCanvas(false)} className="offcanvas-end customeoff" placement='end'>
                <div className="offcanvas-header">
                    <h5 className="modal-title">Subir {documentType === 'commerce' ? 'Documentos Comercio' : 'Documentos DIAN'}</h5>
                    <button type="button" className="btn-close" onClick={() => setShowCanvas(false)}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div className="offcanvas-body">
                    <div className="container-fluid">
                        <form onSubmit={handleSubmit}>

                            {/* Selector para tipo de persona */}
                            <div className="mb-3">
                                <label htmlFor="personTypeSelect" className="form-label">Tipo de Persona</label>
                                <select
                                    id="personTypeSelect"
                                    className="form-control"
                                    value={personType}
                                    onChange={(e) => setPersonType(e.target.value)}
                                >
                                    <option value="">Seleccione una opción</option>
                                    <option value="juridica">Jurídica</option>
                                    <option value="natural">Natural</option>
                                </select>
                            </div>

                            {/* Selector para tipo de documento basado en el tipo de persona */}
                            <div className="mb-3">
                                <label htmlFor="documentTypeSelect" className="form-label">Tipo de Documento</label>
                                <select
                                    id="documentTypeSelect"
                                    className="form-control"
                                    value={documentType}
                                    onChange={(e) => setDocumentType(e.target.value)}
                                >
                                    <option value="">Seleccione una opción</option>
                                    {personType === 'juridica' && <option value="camara_comercio">Cámara de Comercio</option>}
                                    <option value="rut">RUT</option>
                                    <option value="documento_identidad">Documento de Identidad</option>
                                </select>
                            </div>

                            {/* Mostrar número de documento solo si es "Documento de Identidad" */}
                            {documentType === 'documento_identidad' && (
                                <div className="mb-3">
                                    <label htmlFor="documentNumber" className="form-label">Número del Documento</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="documentNumber"
                                        value={documentNumber}
                                        onChange={(e) => setDocumentNumber(e.target.value)}
                                    />
                                </div>
                            )}

                            {/* Input de archivo */}
                            <div className="mb-3">
                                <label htmlFor="fileInput" className="form-label">Seleccionar Documento</label>
                                <input type="file" className="form-control" id="fileInput" onChange={handleFileChange} required />
                            </div>

                            {/* Mostrar alerta dentro del formulario, justo encima del botón */}
                            {alertMessage && (
                                <Alert variant={alertVariant} className={`fade ${showAlert ? 'show' : ''}`}>
                                    {alertMessage}
                                </Alert>
                            )}

                            {/* Botones */}
                            <div className="mb-5 mt-5">
                                <button type="submit" className="btn btn-primary" disabled={loading}>
                                    {loading ? 'Subiendo...' : 'Subir Documento'}
                                </button>
                                <button type="button" className="btn btn-secondary ms-2" onClick={() => setShowCanvas(false)}>
                                    Cancelar
                                </button>
                            </div>
                        </form>

                        {/* Mostrar archivos subidos */}
                        <h5 className="mt-4">Archivos ya subidos:</h5>
                        <ul>
                            {uploadedFiles && uploadedFiles.length > 0 ? (
                                uploadedFiles.map((file, index) => (
                                    <li key={index}>
                                        <a
                                            className="text-danger-2"
                                            onClick={() => handleDeleteDocument(file.id)} // Eliminar documento
                                        >
                                            <i className="fa fa-trash"></i>
                                        </a>
                                        <span className="mx-2">|</span>
                                        <a href={file.documentUrl} target="_blank" rel="noopener noreferrer" className="text-capitalize">
                                            <i className="fa fa-file" style={{ marginRight: '8px' }}></i> 
                                            {file.documentType.replace(/_/g, ' ')}
                                        </a>
                                    </li>
                                ))
                            ) : (
                                <p>No se han subido archivos todavía.</p>
                            )}
                        </ul>
                    </div>
                </div>
            </Offcanvas>
        </>
    );
});

export default DocumentCanvas;
