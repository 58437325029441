import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { deleteSalesPoint } from '../../../../services/PuntosVentaService';

const PuntosDeVentaGridTab = ({ puntosVenta = [], openEditModal, onSalesPointDeleted }) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [salesPointToDelete, setSalesPointToDelete] = useState(null);
    const [showResultModal, setShowResultModal] = useState(false);
    const [resultMessage, setResultMessage] = useState('');
    const [resultVariant, setResultVariant] = useState('success');

    const handleDeleteClick = (salesPointId) => {
        setSalesPointToDelete(salesPointId);
        setShowDeleteModal(true);
    };

    const confirmDeleteSalesPoint = async () => {
        try {
            await deleteSalesPoint(salesPointToDelete);
            setResultMessage('Punto de venta eliminado exitosamente.');
            setResultVariant('success');
            onSalesPointDeleted(); // Recargar los puntos de venta
        } catch (error) {
            setResultMessage('Error al eliminar el punto de venta. Intente nuevamente.');
            setResultVariant('danger');
            console.error('Error al eliminar el punto de venta:', error);
        } finally {
            setShowDeleteModal(false);
            setShowResultModal(true);
        }
    };

    return (
        <>
            <div className="row">
                {/* Aseguramos que puntosVenta esté definido y sea un array */}
                {Array.isArray(puntosVenta) && puntosVenta.length > 0 ? (
                    puntosVenta.map((puntoVenta, index) => (
                        <div className="col-xl-3 col-lg-4 col-sm-6" key={index}>
                            <div className="card">
                                <div className="card-body">
                                    <div className="card__text">
                                        <h4 className="mb-0">{puntoVenta.name}</h4>
                                        <p className='mb-0'>Ubicación: {puntoVenta.location || "-"}</p>
                                    </div>
                                    <div className="d-flex justify-content-center mt-3">
                                        <button
                                            className="btn btn-info btn-sm me-3"
                                            onClick={() => openEditModal(puntoVenta.id)}
                                        >
                                            Editar
                                        </button>
                                        <button
                                            className="btn btn-danger btn-sm"
                                            onClick={() => handleDeleteClick(puntoVenta.id)}
                                        >
                                            Eliminar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div>No hay puntos de venta disponibles.</div>
                )}
            </div>

            {/* Modal de confirmación de eliminación */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar eliminación</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Estás seguro de que deseas eliminar este punto de venta? Esta acción no se puede deshacer.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={confirmDeleteSalesPoint}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal de resultado de la eliminación */}
            <Modal show={showResultModal} onHide={() => setShowResultModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{resultVariant === 'success' ? 'Éxito' : 'Error'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {resultMessage}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowResultModal(false)}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PuntosDeVentaGridTab;
