import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Tab, Nav } from 'react-bootstrap';
import Select from 'react-select';
import UsuariosComercioCanvas from '../../constant/UsuariosComercioCanvas';
import UsuariosComercioEditCanvas from '../../constant/UsuariosComercioEditCanvas';
import AssignToSalesPointCanvas from '../../constant/UsuariosComercioAssignToSalesPointCanvas';
import UsuariosComercioGridTab from '../AppsMenu/UsuariosComercio/GridTab';
import UsuariosComercioListTab from '../AppsMenu/UsuariosComercio/ListTab';
import { getAllUserAssignments, getAllUserAssignmentsByCommerce, getAllUserAssignmentsBySalesPoint, deleteUserAndAssignments } from '../../../services/UsuariosComercioService';
import { getComercios } from '../../../services/ComerciosService';
import { getSalesPoints } from '../../../services/PuntosVentaService';
import { SVGICON } from '../../constant/theme';

const UsuariosComercioAdmin = () => {
    const assignUserRef = useRef();
    const editUserRef = useRef();
    const assignToSalesPointRef = useRef();
    const [userAssignments, setUserAssignments] = useState([]);
    const [commerces, setCommerces] = useState([]);
    const [salesPoints, setSalesPoints] = useState([]);
    const [selectedCommerce, setSelectedCommerce] = useState(null);
    const [selectedSalesPoint, setSelectedSalesPoint] = useState(null);
    const userId = JSON.parse(localStorage.getItem('userDetails')).userData.id;

    useEffect(() => {
        const loadCommerces = async () => {
            try {
                const response = await getComercios(userId);
                const activeCommerces = response.data.map(commerce => ({
                    value: commerce.id,
                    label: commerce.name,
                }));
                setCommerces(activeCommerces);
        
                // Si solo hay un comercio, seleccionarlo automáticamente
                if (activeCommerces.length === 1) {
                    const selectedCommerce = activeCommerces[0];
                    setSelectedCommerce(selectedCommerce);
                    loadSalesPoints(selectedCommerce.value); // Cargar puntos de venta del comercio seleccionado
                }
            } catch (error) {
                console.log('Error al obtener comercios:', error);
                setCommerces([]);
            }
        };        

        const loadInitialUserAssignments = async () => {
            await loadUserAssignments(); // Carga inicial de todas las asignaciones de usuarios
        };

        loadCommerces();
        loadInitialUserAssignments(); // Cargar asignaciones al iniciar
    }, [userId]);

    useEffect(() => {
        if (selectedCommerce) {
            loadSalesPoints();
            loadUserAssignmentsByCommerce();
        } else {
            loadUserAssignments(); // Cargar todas las asignaciones si no hay comercio seleccionado
        }
    }, [selectedCommerce]);

    useEffect(() => {
        if (selectedSalesPoint) {
            loadUserAssignmentsBySalesPoint();
        } else if (selectedCommerce) {
            loadUserAssignmentsByCommerce(); // Si se deselecciona el punto de venta, cargar por comercio
        } else {
            loadUserAssignments(); // Si no hay nada seleccionado, cargar todas las asignaciones
        }
    }, [selectedSalesPoint]);

    const loadSalesPoints = async () => {
        try {
            const response = await getSalesPoints(selectedCommerce.value);
            const points = response.data.map(salesPoint => ({
                value: salesPoint.id,
                label: salesPoint.name,
            }));
            setSalesPoints(points);
        } catch (error) {
            console.error('Error al obtener los puntos de venta:', error);
            setSalesPoints([]);
        }
    };

    const loadUserAssignments = async () => {
        try {
            const response = await getAllUserAssignments();
            console.log("Asignaciones de usuarios obtenidas:", response.data);
            setUserAssignments(response.data || []);
        } catch (error) {
            console.log('Error al obtener las asignaciones de usuarios', error);
            setUserAssignments([]);
        }
    };

    const loadUserAssignmentsByCommerce = async () => {
        try {
            const response = await getAllUserAssignmentsByCommerce(selectedCommerce.value);
            console.log("Asignaciones de usuarios por comercio obtenidas:", response.data);
            setUserAssignments(response.data || []);
        } catch (error) {
            console.log('Error al obtener las asignaciones de usuarios por comercio', error);
            setUserAssignments([]);
        }
    };

    const loadUserAssignmentsBySalesPoint = async () => {
        try {
            const response = await getAllUserAssignmentsBySalesPoint(selectedSalesPoint.value);
            console.log("Asignaciones de usuarios por punto de venta obtenidas:", response.data);
            setUserAssignments(response.data || []);
        } catch (error) {
            console.log('Error al obtener las asignaciones de usuarios por punto de venta', error);
            setUserAssignments([]);
        }
    };

    const handleUserAssignmentCreated = () => {
        loadUserAssignments(); // Cargar nuevamente todas las asignaciones después de crear una nueva
    };

    const handleUserAssignmentUpdated = () => {
        loadUserAssignments(); // Cargar nuevamente todas las asignaciones después de actualizar una
    };

    const [isLoading, setIsLoading] = useState(false);

    const handleUserAssignmentDeleted = async (assignmentId) => {
        if (isLoading || !assignmentId) return; // Evitar múltiples llamadas y validar assignmentId
        console.log('Deleting assignment: ', assignmentId);
        try {
            setIsLoading(true); // Bloquear nuevas ejecuciones
            await deleteUserAndAssignments(assignmentId);
            console.log('Asignación eliminada, recargando asignaciones...');
            await loadUserAssignments(); // Recargar la tabla después de eliminar una asignación
        } catch (error) {
            console.error('Error al eliminar la asignación:', error);
        } finally {
            setIsLoading(false); // Liberar el bloqueo
        }
    };

    const openEditModal = (id) => {
        if (id) {
            editUserRef.current.showEditAssignmentModal(id);
        }
    };

    // Cambiar la función openAssignToSalesPointModal para aceptar tanto userId como roleId
    const openAssignToSalesPointModal = (userId, roleId) => {
        if (userId && roleId) {
            assignToSalesPointRef.current.showAssignToSalesPointModal(userId, roleId);
        }
    };


    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <Tab.Container defaultActiveKey={'Grid'}>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h4 className="heading mb-0">Asignaciones de Usuarios a Comercios</h4>
                            <div className="d-flex align-items-center">
                                <Nav as="ul" className="nav nav-pills mix-chart-tab user-m-tabe" id="pills-tab">
                                    <Nav.Item as="li" className="nav-item" role="presentation">
                                        <Nav.Link as="button" className="nav-link" eventKey={'List'}>
                                            {SVGICON.List}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as="li" className="nav-item">
                                        <Nav.Link as="button" className="nav-link" eventKey={'Grid'}>
                                            {SVGICON.GridDots}
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Link
                                    to="#"
                                    className="btn btn-primary btn-sm ms-2"
                                    onClick={() => assignUserRef.current.showUserAssignmentModal()}
                                >
                                    + Asignar Usuario
                                </Link>
                            </div>
                        </div>
                        <div className='row mt-3 mb-4 pe-0'>
                            <div className='col-md-3 align-self-center'>
                                <label className="form-label my-0">Filtrar por Comercio</label>
                            </div>
                            <div className='col-md-9 px-0'>
                                <Select
                                    value={selectedCommerce}
                                    onChange={setSelectedCommerce}
                                    options={commerces}
                                    placeholder="Seleccionar Comercio"
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isClearable
                                />
                            </div>
                        </div>
                        {selectedCommerce && (
                            <div className='row mt-0 mb-4 pe-0'>
                                <div className='col-md-3 align-self-center'>
                                    <label className="form-label my-0">Filtrar por Punto de Venta</label>
                                </div>
                                <div className='col-md-9 px-0'>
                                    <Select
                                        value={selectedSalesPoint}
                                        onChange={setSelectedSalesPoint}
                                        options={salesPoints}
                                        placeholder="Seleccionar Punto de Venta"
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isClearable
                                    />
                                </div>
                            </div>
                        )}
                        <div className="col-xl-12 active-p">
                            <Tab.Content>
                                <Tab.Pane eventKey={'Grid'}>
                                    <UsuariosComercioGridTab
                                        userAssignments={userAssignments}
                                        setUserAssignments={setUserAssignments}  // Pasar setUserAssignments como prop
                                        tableName={"Usuarios Asignados"}
                                        openEditModal={openEditModal}
                                        openAssignToSalesPointModal={openAssignToSalesPointModal} // Nueva prop
                                        onUserAssignmentDeleted={handleUserAssignmentDeleted}
                                    />
                                </Tab.Pane>
                                <Tab.Pane eventKey={'List'}>
                                    <UsuariosComercioListTab
                                        userAssignments={userAssignments}
                                        setUserAssignments={setUserAssignments}  // Pasar setUserAssignments como prop
                                        tableName={"Usuarios Asignados"}
                                        openEditModal={openEditModal}
                                        openAssignToSalesPointModal={openAssignToSalesPointModal} // Nueva prop
                                        onUserAssignmentDeleted={handleUserAssignmentDeleted}
                                    />
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </Tab.Container>
                </div>
            </div>
            <UsuariosComercioCanvas
                ref={assignUserRef}
                Title="Asignar Usuario a Comercio"
                onUserAssignmentCreated={handleUserAssignmentCreated}
            />
            <UsuariosComercioEditCanvas
                ref={editUserRef}
                onUserAssignmentUpdated={handleUserAssignmentUpdated}
            />
            <AssignToSalesPointCanvas // Nuevo componente para asignar usuarios a puntos de venta
                ref={assignToSalesPointRef}
                onUserAssignmentUpdated={handleUserAssignmentUpdated}
            />
        </>
    );
};

export default UsuariosComercioAdmin;
