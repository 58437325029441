import React, { useState, useMemo } from 'react';
import { useTable, useFilters, useGlobalFilter, usePagination } from 'react-table';
import { Modal, Button } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { GlobalFilter } from './GlobalFilter'; // Asegúrate de tener este componente
import VentaDetalleModal from './VentaDetalleModal'; // Importa como default, sin llaves

const VentasListTab = ({ sales }) => {
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [modalTitle, setModalTitle] = useState('');

    // Función para exportar a Excel
    const exportToExcel = () => {
        const dataToExport = sales.map((venta) => ({
            ID: venta.id || 'Sin ID',
            Fecha: venta.date ? new Date(venta.date).toLocaleString() : 'Sin Fecha',
            Comercio: venta.commerceDetails?.name || 'Sin Comercio',
            'Punto de Venta': venta.salesPointDetails?.name || 'Sin Punto de Venta',
            Cliente: venta.customerDetails?.nombre || 'Sin Cliente',
            Total: formatearMoneda(venta.total) || 'Sin Total'
        }));

        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Ventas');

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'ventas.xlsx');
    };

    // Definimos las columnas de la tabla
    const columns = useMemo(() => [
        {
            Header: 'No. Factura',
            accessor: 'saleNumberInterna',
            Filter: DefaultColumnFilter,
        },
        {
            Header: 'Fecha',
            accessor: row => row?.date ? new Date(row.date).toLocaleString() : 'Sin Fecha',
            Filter: DefaultColumnFilter,
        },
        {
            Header: 'Comercio',
            accessor: row => row?.commerceDetails?.name || 'Sin Comercio',
            Filter: DefaultColumnFilter,
        },
        {
            Header: 'Punto de Venta',
            accessor: row => row?.salesPointDetails?.name || 'Sin Punto de Venta',
            Filter: DefaultColumnFilter,
        },
        {
            Header: 'Cliente',
            accessor: row => row?.customerDetails?.nombre || 'Sin Cliente',
            Filter: DefaultColumnFilter,
        },
        {
            Header: 'Total',
            accessor: row => formatearMoneda(row?.total) || 'Sin Total',
            Cell: ({ value }) => (
                <div className="text-end">
                    {value}
                </div>
            ), // Alinear a la derecha usando "text-end"
            Filter: DefaultColumnFilter,
        },
        {
            Header: 'Factura',
            accessor: row => (
                row?.invoiceUrl 
                ? <Button onClick={() => openModal('Factura', row.invoiceUrl)} className="btn btn-info btn-sm">Ver Factura</Button> 
                : 'No disponible'
            ),
            Filter: () => null,
        },
        {
            Header: 'QR',
            accessor: row => (
                row?.qrUrl 
                ? <Button onClick={() => openModal('QR', row.qrUrl)} className="btn btn-info btn-sm">Ver QR</Button> 
                : 'No disponible'
            ),
            Filter: () => null,
        },
        {
            Header: 'Detalle Venta', // Nueva columna para ver detalles
            Cell: ({ row }) => (
                <Button onClick={() => openModal('Detalle Venta', row.original)} className="btn btn-info btn-sm">
                    Ver Detalles
                </Button>
            ),
            Filter: () => null,
        }
    ], [sales]);

    const data = useMemo(() => sales, [sales]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        state,
        setGlobalFilter,
    } = useTable({
        columns,
        data,
        initialState: { pageSize: 10 }
    }, useFilters, useGlobalFilter, usePagination);

    const { globalFilter } = state;

    const openModal = (title, venta) => {
        setModalTitle(title);
        setModalContent(venta);  // Pasamos el objeto venta completo al modal
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setModalContent(null);
    };

    return (
        <>
            <div className='row mt-3 mb-4 pe-0'>
                <div className='col-md-3 align-self-center'>
                    <label className="form-label my-0">Buscar...</label>
                </div>
                <div className='col-md-9 px-0'>
                    <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                </div>
            </div>
            <div className="card">
                <div className="card-body p-0">
                    <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                        <div className="tbl-caption d-flex justify-content-between align-items-center">
                            <h4 className="heading mb-0">Ventas</h4>
                            <Button className="btn btn-primary light btn-sm me-2" onClick={exportToExcel}>
                                <i className="fa-solid fa-file-excel" /> Exportar Reporte
                            </Button>
                        </div>
                        <div className="dataTables_wrapper no-footer">
                            <table {...getTableProps()} className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0">
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th {...column.getHeaderProps()}>
                                                    {column.render('Header')}
                                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.length > 0 ? (
                                        page.map(row => {
                                            prepareRow(row);
                                            return (
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map(cell => (
                                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                    ))}
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td className='text-center my-5 py-5' colSpan={columns.length}>No hay ventas disponibles</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className="d-sm-flex text-center justify-content-between align-items-center">
                            <div className="dataTables_info">
                                Mostrando {page.length > 0 ? 1 : 0} a {Math.min(page.length, 10)} de {sales.length} entradas
                            </div>
                            <div className="dataTables_paginate paging_simple_numbers justify-content-center me-3">
                                <Button className="btn-sm paginate_button previous me-1" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                    <i className="fa-solid fa-angle-left" />
                                </Button>
                                <Button className="btn-sm paginate_button next" onClick={() => nextPage()} disabled={!canNextPage}>
                                    <i className="fa-solid fa-angle-right" />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal para Factura, QR y Detalle Venta */}
            <Modal show={showModal} onHide={closeModal} className='modal-lg' scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalTitle === 'Detalle Venta' && modalContent ? (
                        <VentaDetalleModal venta={modalContent} />
                    ) : modalTitle === 'Factura' && modalContent ? (
                        <iframe src={modalContent} width="100%" height="500px" className="w-100" title="Factura"></iframe>
                    ) : modalTitle === 'QR' && modalContent ? (
                        <img src={modalContent} width="100%" alt="QR" />
                    ) : (
                        <p>No se pudo cargar el contenido.</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default VentasListTab;

// Componente de filtro por defecto para columnas
const DefaultColumnFilter = ({ column: { filterValue, preFilteredRows, setFilter } }) => {
    const count = preFilteredRows.length;
    return (
        <input
            value={filterValue || ''}
            onChange={e => setFilter(e.target.value || undefined)}
            placeholder={`Buscar (${count})...`}
            className="form-control"
        />
    );
};

// Función para formatear los valores como moneda
const formatearMoneda = (valor) => {
    if (valor === null || valor === undefined) return '-';
    return Number(valor).toLocaleString('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
};