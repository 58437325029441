import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Offcanvas, Alert } from 'react-bootstrap';
import { updateSalesPoint, getSalesPointById } from '../../services/PuntosVentaService'; // Servicios de edición y obtención de punto de venta

const PuntosVentaEditCanvas = forwardRef((props, ref) => {
    const [salesPointId, setSalesPointId] = useState(null);
    const [name, setName] = useState('');
    const [location, setLocation] = useState('');
    const [editSalesPoint, setEditSalesPoint] = useState(false);
    const [loading, setLoading] = useState(false); // Estado para el botón de carga
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState(''); // Variantes como success, danger
    const [showAlert, setShowAlert] = useState(false); // Estado para controlar el fade-out de la alerta

    useImperativeHandle(ref, () => ({
        showEditSalesPointModal(id) {
            // Limpiar los estados del formulario antes de cargar los datos del punto de venta
            resetFormFields();
            setSalesPointId(id);
            fetchSalesPointDetails(id); // Obtener detalles del punto de venta para edición
            setEditSalesPoint(true);
        }    
    }));

    const resetFormFields = () => {
        setName('');
        setLocation('');
    };

    const fetchSalesPointDetails = async (id) => {
        try {
            const response = await getSalesPointById(id);
            const salesPoint = response.data;
            setName(salesPoint.name || '');
            setLocation(salesPoint.location || '');
        } catch (error) {
            console.error('Error al obtener los detalles del punto de venta:', error);
            setAlertMessage('Hubo un error al cargar los detalles del punto de venta.');
            setAlertVariant('danger');
            setShowAlert(true);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = {
            name,
            location,
        };

        try {
            const response = await updateSalesPoint(salesPointId, formData); // Llamada al servicio para actualizar el punto de venta
            console.log('Punto de venta actualizado:', response.data);
            setAlertMessage('Punto de venta actualizado exitosamente');
            setAlertVariant('success');
            
            // Llama a la función de actualización pasada desde el padre
            props.onSalesPointUpdated(response.data);

            setTimeout(() => {
                setEditSalesPoint(false); // Cerrar el modal
                resetFormFields(); // Limpiar el formulario después de cerrar el modal
            }, 1000); // Dar un pequeño tiempo antes de cerrar
        } catch (error) {
            console.error('Error actualizando el punto de venta:', error);
            setAlertMessage('Hubo un error actualizando el punto de venta. Intente nuevamente.');
            setAlertVariant('danger');
        } finally {
            setLoading(false);
        }
    };

    // Efecto para ocultar la alerta automáticamente después de 3 segundos con transición suave
    useEffect(() => {
        if (alertMessage) {
            setShowAlert(true); // Mostrar alerta con la clase 'show'

            const timeout = setTimeout(() => {
                setShowAlert(false); // Desaparecer alerta suavemente
                setTimeout(() => setAlertMessage(''), 300); // Esperar el fade-out antes de borrar el mensaje
            }, 3000); // 3 segundos

            // Limpiar el timeout si el componente se desmonta antes de los 3 segundos
            return () => clearTimeout(timeout);
        }
    }, [alertMessage]);

    return (
        <>
            <Offcanvas show={editSalesPoint} onHide={() => setEditSalesPoint(false)} className="offcanvas-end customeoff" placement='end'>
                <div className="offcanvas-header">
                    <h5>Editar Punto de Venta</h5>
                    <button type="button" className="btn-close" onClick={() => setEditSalesPoint(false)}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div className="offcanvas-body">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className="form-label">Nombre del Punto de Venta <span className="text-danger">*</span></label>
                            <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} required />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Ubicación</label>
                            <input type="text" className="form-control" value={location} onChange={(e) => setLocation(e.target.value)} />
                        </div>

                        {/* Mostrar alerta dentro del formulario, justo encima del botón */}
                        {alertMessage && (
                            <Alert variant={alertVariant} className={`fade ${showAlert ? 'show' : ''}`}>
                                {alertMessage}
                            </Alert>
                        )}

                        {/* Botón de actualizar punto de venta */}
                        <div className="mb-5 mt-3">
                            <button type="submit" className="btn btn-primary" disabled={loading}>
                                {loading ? 'Actualizando...' : 'Actualizar Punto de Venta'}
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary ms-2"
                                onClick={() => setEditSalesPoint(false)}
                            >
                                Cancelar
                            </button>
                        </div>
                    </form>
                </div>
            </Offcanvas>
        </>
    );
});

export default PuntosVentaEditCanvas;