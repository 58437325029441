import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Modal, Button } from 'react-bootstrap';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { updateProducto, deleteProducto } from '../../../../services/ProductosService';

const ListTab = ({ productos, tableName, openEditModal, onProductoDeleted }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [checked, setChecked] = useState([]);
    const [allChecked, setAllChecked] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [productoToDelete, setProductoToDelete] = useState(null);
    const [showResultModal, setShowResultModal] = useState(false);
    const [resultMessage, setResultMessage] = useState('');
    const [resultVariant, setResultVariant] = useState('success');
    const [editedQuantities, setEditedQuantities] = useState({}); // Estado para almacenar las cantidades editadas

    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = productos.slice(firstIndex, lastIndex);
    const totalPages = Math.ceil(productos.length / recordsPerPage);
    const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

    const changePage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const prePage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleChecked = (id) => {
        setChecked((prevChecked) =>
            prevChecked.includes(id)
                ? prevChecked.filter((item) => item !== id)
                : [...prevChecked, id]
        );
    };

    const handleCheckedAll = () => {
        if (allChecked) {
            setChecked([]);
        } else {
            setChecked(records.map((producto) => producto.id));
        }
        setAllChecked(!allChecked);
    };

    const exportToExcel = () => {
        const dataToExport = productos.map((producto) => ({
            Producto: producto.name,
            Código: producto.barcode,
            Cantidad: producto.quantity,
            'Precio Compra': producto.purchasePrice,
            'Precio Venta': producto.salePrice,
            'Fecha Creación': format(new Date(producto.createdAt), 'dd/MM/yyyy HH:mm:ss'),
        }));

        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Productos');

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'productos.xlsx');
    };

    const handleDeleteClick = (productoId) => {
        setProductoToDelete(productoId);
        setShowDeleteModal(true);
    };

    const confirmDeleteProducto = async () => {
        try {
            await deleteProducto(productoToDelete);
            setResultMessage('Producto eliminado exitosamente.');
            setResultVariant('success');
            setChecked(checked.filter(id => id !== productoToDelete));
            onProductoDeleted(); // Llamar a la función para recargar la tabla
        } catch (error) {
            setResultMessage('Error al eliminar el producto. Intente nuevamente.');
            setResultVariant('danger');
            console.error('Error al eliminar el producto:', error);
        } finally {
            setShowDeleteModal(false);
            setShowResultModal(true);
        }
    };

    const handleQuantityChange = (id, newQuantity) => {
        setEditedQuantities((prevQuantities) => ({
            ...prevQuantities,
            [id]: newQuantity
        }));
    };

    const handleUpdateStock = async (productoId) => {
        const newQuantity = editedQuantities[productoId];
        if (!newQuantity || isNaN(newQuantity)) return;

        try {
            await updateProducto(productoId, { quantity: newQuantity });
            setResultMessage('Stock actualizado exitosamente.');
            setResultVariant('success');
            setShowResultModal(true);
            onProductoDeleted(); // Recargar productos después de la actualización
        } catch (error) {
            setResultMessage('Error al actualizar el stock. Intente nuevamente.');
            setResultVariant('danger');
            setShowResultModal(true);
        }
    };

    return (
        <>
            <div className="card">
                <div className="card-body p-0">
                    <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                        <div className="tbl-caption d-flex justify-content-between align-items-center">
                            <h4 className="heading mb-0">{tableName}</h4>
                            <button className="btn btn-primary light btn-sm me-2" onClick={exportToExcel}>
                                <i className="fa-solid fa-file-excel" /> Exportar Reporte
                            </button>
                        </div>
                        <div id="producto-tbl_wrapper" className="dataTables_wrapper no-footer">
                            <table id="products-tbl" className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0">
                                <thead>
                                    <tr>
                                        <th>
                                            <div className="form-check custom-checkbox ms-0">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={allChecked}
                                                    onChange={handleCheckedAll}
                                                />
                                                <label className="form-check-label" />
                                            </div>
                                        </th>
                                        <th>Producto</th>
                                        <th>Código</th>
                                        <th>Cantidad</th>
                                        <th>Precio Compra</th>
                                        <th>Precio Venta</th>
                                        <th>Fecha Creación</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {records.map((producto) => (
                                        <tr key={producto.id}>
                                            <td>
                                                <div className="form-check custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={checked.includes(producto.id)}
                                                        onChange={() => handleChecked(producto.id)}
                                                    />
                                                    <label className="form-check-label" />
                                                </div>
                                            </td>
                                            <td className="text-capitalize">{producto.name}</td>
                                            <td>{producto.barcode || "-"}</td>
                                            <td className='d-flex'>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    style={{ maxWidth: '80px'}}
                                                    value={editedQuantities[producto.id] ?? producto.quantity}
                                                    onChange={(e) => handleQuantityChange(producto.id, e.target.value)}
                                                />
                                                <button
                                                    className="btn btn-primary btn-sm ms-2"
                                                    onClick={() => handleUpdateStock(producto.id)}
                                                >
                                                    <i className="fa-sharp fa-light fa-circle-check"></i>
                                                </button>
                                            </td>
                                            <td className='text-end'>{formatearMoneda(producto.purchasePrice) || "-"}</td>
                                            <td className='text-end'>{formatearMoneda(producto.salePrice) || "-"}</td>
                                            <td>{format(new Date(producto.createdAt), 'dd/MM/yyyy HH:mm:ss')}</td>
                                            <td>
                                                <button className="btn btn-info btn-sm me-2" onClick={() => openEditModal(producto.id)}>Editar</button>
                                                <button className="btn btn-danger btn-sm" onClick={() => handleDeleteClick(producto.id)}>Eliminar</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            <div className="d-sm-flex text-center justify-content-between align-items-center">
                                <div className="dataTables_info">
                                    Mostrando {firstIndex + 1} a {Math.min(lastIndex, productos.length)} de {productos.length} entradas
                                </div>
                                <div className="dataTables_paginate paging_simple_numbers justify-content-center">
                                    <Link className="paginate_button previous" to="#" onClick={prePage}>
                                        <i className="fa-solid fa-angle-left" />
                                    </Link>
                                    <span>
                                        {pageNumbers.map((number) => (
                                            <Link
                                                className={`paginate_button ${currentPage === number ? 'current' : ''}`}
                                                key={number}
                                                to="#"
                                                onClick={() => changePage(number)}
                                            >
                                                {number}
                                            </Link>
                                        ))}
                                    </span>
                                    <Link className="paginate_button next" to="#" onClick={nextPage}>
                                        <i className="fa-solid fa-angle-right" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar eliminación</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Estás seguro de que deseas eliminar este producto? Esta acción no se puede deshacer.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancelar</Button>
                    <Button variant="danger" onClick={confirmDeleteProducto}>Eliminar</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showResultModal} onHide={() => setShowResultModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{resultVariant === 'success' ? 'Éxito' : 'Error'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{resultMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowResultModal(false)}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

const formatearMoneda = (valor) => {
    if (valor === null || valor === undefined) return '-';
    return Number(valor).toLocaleString('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
  };

export default ListTab;
