import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { format } from 'date-fns';
import { deleteUserAndAssignments } from '../../../../services/UsuariosComercioService';

const UsuariosComercioListTab = ({ userAssignments = [], setUserAssignments, tableName, openEditModal, onUserAssignmentDeleted, openAssignToSalesPointModal }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [checked, setChecked] = useState([]);
    const [allChecked, setAllChecked] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [assignmentToDelete, setAssignmentToDelete] = useState(null);
    const [showResultModal, setShowResultModal] = useState(false);
    const [resultMessage, setResultMessage] = useState('');
    const [resultVariant, setResultVariant] = useState('success');
    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = userAssignments.slice(firstIndex, lastIndex);
    const totalPages = Math.ceil(userAssignments.length / recordsPerPage);
    const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

    const changePage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const prePage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleChecked = (id) => {
        setChecked((prevChecked) =>
            prevChecked.includes(id)
                ? prevChecked.filter((item) => item !== id)
                : [...prevChecked, id]
        );
    };

    const handleCheckedAll = () => {
        if (allChecked) {
            setChecked([]);
        } else {
            setChecked(records.map((assignment) => assignment.id));
        }
        setAllChecked(!allChecked);
    };

    const handleDeleteClick = (assignmentId) => {
        if (assignmentId) {
            setAssignmentToDelete(assignmentId);
            setShowDeleteModal(true);
        }
    };

    const confirmDeleteAssignment = async () => {
        if (!assignmentToDelete) return;

        try {
            const updatedUser = await deleteUserAndAssignments(assignmentToDelete);
            setResultMessage('Usuario inhabilitado/habilitado exitosamente.');
            setResultVariant('success');
            setChecked(checked.filter(id => id !== assignmentToDelete));
            
            // Actualizar el estado de `userAssignments` para reflejar el cambio
            setUserAssignments(prevAssignments => 
                prevAssignments.map(assignment => 
                    assignment.user.id === assignmentToDelete ? { ...assignment, user: { ...assignment.user, status: updatedUser.data.status } } : assignment
                )
            );

            onUserAssignmentDeleted(); // Llamar a la función para recargar la tabla
        } catch (error) {
            setResultMessage('Error al procesar la habilitación/inhibición. Intente nuevamente.');
            setResultVariant('danger');
            console.error('Error al procesar la habilitación/inhibición:', error);
        } finally {
            setShowDeleteModal(false);
            setShowResultModal(true);
        }
    };

    return (
        <>
            <div className="card">
                <div className="card-body p-0">
                    <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                        <div className="tbl-caption d-flex justify-content-between align-items-center">
                            <h4 className="heading mb-0">{tableName}</h4>
                        </div>
                        <div id="usuario-comercio-tbl_wrapper" className="dataTables_wrapper no-footer">
                            <table id="projects-tbl" className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0">
                                <thead>
                                    <tr>
                                        <th>
                                            <div className="form-check custom-checkbox ms-0">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={allChecked}
                                                    onChange={handleCheckedAll}
                                                />
                                                <label className="form-check-label" />
                                            </div>
                                        </th>
                                        <th>Usuario</th>
                                        <th>Status</th>
                                        <th>Rol</th>
                                        <th>Comercio</th>
                                        <th>Punto de Venta</th>
                                        <th>Fecha Asignación</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {records.length > 0 ? (
                                        records.map((assignment) => (
                                            <tr key={assignment.id}>
                                                <td>
                                                    <div className="form-check custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            checked={checked.includes(assignment.id)}
                                                            onChange={() => handleChecked(assignment.id)}
                                                        />
                                                        <label className="form-check-label" />
                                                    </div>
                                                </td>
                                                <td>{assignment.user?.name || "No disponible"}</td>
                                                <td>
                                                    {assignment.user?.status ? (
                                                        <span className="badge light badge-outline-success">Habilitado</span>
                                                    ) : (
                                                        <span className="badge light badge-outline-danger">Inhabilitado</span>
                                                    )}
                                                </td>
                                                <td>{assignment.user?.role?.role || "No disponible"}</td>
                                                <td>{assignment.commerce?.name || "No disponible"}</td>
                                                <td>
                                                    {assignment.salesPoints && assignment.salesPoints.length > 0 ? (
                                                        assignment.salesPoints.map((point) => (
                                                            <div key={point.id}>
                                                                {point.name} ({point.location || '-'})
                                                            </div>
                                                        ))
                                                    ) : (
                                                        "No asignado"
                                                    )}
                                                </td>
                                                <td>{format(new Date(assignment.createdAt), 'dd/MM/yyyy HH:mm:ss')}</td>
                                                <td>
                                                    <button
                                                        className="btn btn-info btn-sm me-2"
                                                        onClick={() => openEditModal(assignment.id)}
                                                    >
                                                        Editar
                                                    </button>
                                                    <button
                                                        className={`btn btn-${assignment.user?.status ? 'danger' : 'success'} btn-sm`}
                                                        onClick={() => handleDeleteClick(assignment.user.id)}
                                                    >
                                                        {assignment.user?.status ? 'Inhabilitar' : 'Habilitar'}
                                                    </button>
                                                    <button
                                                        className="btn btn-primary btn-sm ms-2"
                                                        onClick={() => openAssignToSalesPointModal(assignment.user?.id, assignment.user?.role?.id)}
                                                    >
                                                        Asignar a Punto de Venta
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="8" className="text-center">
                                                No hay asignaciones de usuarios disponibles.
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                            <div className="d-sm-flex text-center justify-content-between align-items-center">
                                <div className="dataTables_info">
                                    Mostrando {firstIndex + 1} a {Math.min(lastIndex, userAssignments.length)} de {userAssignments.length} entradas
                                </div>
                                <div className="dataTables_paginate paging_simple_numbers justify-content-center">
                                    <Button className="paginate_button previous" onClick={prePage}>
                                        <i className="fa-solid fa-angle-left" />
                                    </Button>
                                    <span>
                                        {pageNumbers.map((number) => (
                                            <Button
                                                className={`paginate_button ${currentPage === number ? 'current' : ''}`}
                                                key={number}
                                                onClick={() => changePage(number)}
                                            >
                                                {number}
                                            </Button>
                                        ))}
                                    </span>
                                    <Button className="paginate_button next" onClick={nextPage}>
                                        <i className="fa-solid fa-angle-right" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar cambio de estado</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Estás seguro de que deseas cambiar el estado de este usuario?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={confirmDeleteAssignment}>
                        Cambiar estado
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showResultModal} onHide={() => setShowResultModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{resultVariant === 'success' ? 'Éxito' : 'Error'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{resultMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowResultModal(false)}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default UsuariosComercioListTab;
