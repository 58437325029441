import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { deleteUserAndAssignments } from '../../../../services/UsuariosComercioService';

const UsuariosComercioGridTab = ({ userAssignments = [], setUserAssignments, tableName, openEditModal, onUserAssignmentDeleted, openAssignToSalesPointModal }) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [assignmentToDelete, setAssignmentToDelete] = useState(null);
    const [showResultModal, setShowResultModal] = useState(false);
    const [resultMessage, setResultMessage] = useState('');
    const [resultVariant, setResultVariant] = useState('success');

    const handleDeleteClick = (assignmentId) => {
        if (assignmentId) {
            setAssignmentToDelete(assignmentId);
            setShowDeleteModal(true);
        }
    };

    const confirmDeleteAssignment = async () => {
        if (!assignmentToDelete) return;

        try {
            const updatedUser = await deleteUserAndAssignments(assignmentToDelete);
            setResultMessage('Usuario inhabilitado/habilitado exitosamente.');
            setResultVariant('success');

            // Actualizar el estado de `userAssignments` para reflejar el cambio
            setUserAssignments(prevAssignments => 
                prevAssignments.map(assignment => 
                    assignment.user.id === assignmentToDelete ? { ...assignment, user: { ...assignment.user, status: updatedUser.data.status } } : assignment
                )
            );

            onUserAssignmentDeleted(); // Llamar a la función para recargar la tabla
        } catch (error) {
            setResultMessage('Error al procesar la habilitación/inhibición. Intente nuevamente.');
            setResultVariant('danger');
            console.error('Error al procesar la habilitación/inhibición:', error);
        } finally {
            setShowDeleteModal(false);
            setShowResultModal(true);
        }
    };

    return (
        <>
            <div className="row">
                {userAssignments.length > 0 ? (
                    userAssignments.map((assignment, index) => (
                        <div className="col-xl-3 col-lg-4 col-sm-6" key={index}>
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="text-center mb-4">{assignment.user?.name || "Usuario no definido"}</h4>
                                    <hr></hr>
                                    <p className="mb-0"><strong>Email:</strong> {assignment.user?.email || "No disponible"}</p>
                                    <p className="mb-0"><strong>Teléfono:</strong> {assignment.user?.phone || "No disponible"}</p>
                                    <p className="mb-0"><strong>Rol:</strong> {assignment.user?.role?.role || "No disponible"}</p>
                                    <p className="mb-0"><strong>Comercio:</strong> {assignment.commerce.name}</p>
                                    <p>
                                        <strong>Punto(s) de Venta:</strong><br></br>{" "}
                                        <span
                                            dangerouslySetInnerHTML={{
                                            __html: assignment.salesPoints?.map(point => `- ${point.name}`).join('<br>') || "No asignado"
                                            }}
                                        />
                                    </p>
                                    <hr></hr>
                                    <div className="d-flex justify-content-center mt-4">
                                        <Button
                                            variant="info"
                                            className="me-3 btn-sm"
                                            onClick={() => assignment.user && openEditModal(assignment.id)}
                                        >
                                            Editar
                                        </Button>
                                        <Button
                                            variant={assignment.user?.status ? "danger" : "success"}
                                            onClick={() => assignment.user && handleDeleteClick(assignment.user.id)}
                                            className="btn-sm"
                                        >
                                            {assignment.user?.status ? "Inhabilitar" : "Habilitar"}
                                        </Button>
                                    </div>
                                    <div className="d-flex justify-content-center mt-3">
                                        <Button
                                            variant="primary"
                                            className="btn-sm"
                                            onClick={() => assignment.user && openAssignToSalesPointModal(assignment.user?.id, assignment.user?.role?.id)}
                                        >
                                            Asignar a Punto de Venta
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="col-12 text-center">
                        <p>No hay asignaciones de usuarios disponibles.</p>
                    </div>
                )}
            </div>

            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar cambio de estado</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Estás seguro de que deseas cambiar el estado de este usuario?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={confirmDeleteAssignment}>
                        Cambiar estado
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showResultModal} onHide={() => setShowResultModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{resultVariant === 'success' ? 'Éxito' : 'Error'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{resultMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowResultModal(false)}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default UsuariosComercioGridTab;
