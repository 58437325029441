import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';  // Importa moment

import { getSalesByCommerce, getSalesBySalesPoint } from '../../../services/VentaService';
import { getComercios } from '../../../services/ComerciosService';
import { getSalesPoints } from '../../../services/PuntosVentaService';
import VentasListTab from '../AppsMenu/Ventas/ListTab';
import VentasResumen from '../AppsMenu/Ventas/VentasResumen'; // Componente de resumen de ventas

const ListaVentasAdmin = () => {
    const [sales, setSales] = useState([]);
    const [commerces, setCommerces] = useState([]);
    const [salesPoints, setSalesPoints] = useState([]);
    const [selectedCommerce, setSelectedCommerce] = useState(null);
    const [selectedSalesPoint, setSelectedSalesPoint] = useState(null);
    const [startDate, setStartDate] = useState(moment().startOf('day'));  // Usa moment para la fecha de inicio por defecto
    const [endDate, setEndDate] = useState(moment().endOf('day'));  // Usa moment para la fecha de fin por defecto
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));

    const userId = userDetails?.userData.id;
    const userRole = userDetails?.userData.role.role;

    // Función para cargar ventas al iniciar la página
    useEffect(() => {
        if (userRole === 'administrador') {
            loadCommerces();
        } else if (userRole === 'usuario-comercio') {
            const commerceId = JSON.parse(localStorage.getItem('selectedCommerce')).value;
            const salesPointId = JSON.parse(localStorage.getItem('selectedSalesPoint')).value;
            loadSalesBySalesPoint(commerceId, salesPointId, startDate.utc(), endDate.utc()); // Cargar ventas con fechas en UTC
        }
    }, [userId, userRole, startDate, endDate]); // Reaccionar a cambios en las fechas

    const loadCommerces = async () => {
        try {
            const response = await getComercios(userId);
            const activeCommerces = response.data.map(commerce => ({
                value: commerce.id,
                label: commerce.name
            }));

            if (activeCommerces.length === 1) {
                const selectedCommerce = activeCommerces[0];
                setSelectedCommerce(selectedCommerce);
                loadSalesByCommerce(selectedCommerce.value, startDate.utc(), endDate.utc()); // Cargar ventas del comercio con fechas en UTC
            }

            setCommerces(activeCommerces);
        } catch (error) {
            console.error('Error al cargar comercios:', error);
        }
    };

    const loadSalesPoints = async (commerceId) => {
        try {
            const response = await getSalesPoints(commerceId);
            const activeSalesPoints = response.data.map(salesPoint => ({
                value: salesPoint.id,
                label: salesPoint.name
            }));
            setSalesPoints(activeSalesPoints);
        } catch (error) {
            console.error('Error al cargar puntos de venta:', error);
        }
    };

    const loadSalesByCommerce = async (commerceId, startDate, endDate) => {
        console.log("Loading sales by DATA", commerceId, startDate, endDate);
        try {
            // Convertimos las fechas a UTC antes de enviarlas al backend
            const response = await getSalesByCommerce(commerceId, moment(startDate), moment(endDate));
            setSales(response.data || []);
        } catch (error) {
            console.error('Error al cargar ventas del comercio:', error);
        }
    };

    const loadSalesBySalesPoint = async (commerceId, salesPointId, startDate, endDate) => {
        try {
            // Convertimos las fechas a UTC antes de enviarlas al backend
            const response = await getSalesBySalesPoint(commerceId, salesPointId, moment(startDate), moment(endDate));
            setSales(response.data || []);
        } catch (error) {
            console.error('Error al cargar ventas:', error);
        }
    };

    // Función cuando se selecciona un comercio
    const handleCommerceChange = (selectedCommerce) => {
        setSelectedCommerce(selectedCommerce);
        setSelectedSalesPoint(null); // Reiniciar el punto de venta seleccionado cuando cambia el comercio
        loadSalesPoints(selectedCommerce.value);
        loadSalesByCommerce(selectedCommerce.value, startDate.utc(), endDate.utc()); // Enviar fechas en UTC
    };

    // Función cuando se selecciona un punto de venta
    const handleSalesPointChange = (selectedSalesPoint) => {
        setSelectedSalesPoint(selectedSalesPoint);
        loadSalesBySalesPoint(selectedCommerce.value, selectedSalesPoint.value, startDate.utc(), endDate.utc()); // Enviar fechas en UTC
    };

    // Función que maneja el cambio de rango de fechas
    const handleDateRangeChange = (event, picker) => {
        const newStartDate = picker.startDate.utc();  // Convertir a UTC
        const newEndDate = picker.endDate.utc();      // Convertir a UTC
        setStartDate(newStartDate);
        setEndDate(newEndDate);

        // Llamar a la función para cargar ventas dependiendo del punto de venta o comercio
        if (selectedSalesPoint) {
            loadSalesBySalesPoint(selectedCommerce.value, selectedSalesPoint.value, newStartDate, newEndDate);
        } else if (selectedCommerce) {
            loadSalesByCommerce(selectedCommerce.value, newStartDate, newEndDate);
        }
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    {userRole === 'administrador' && (
                        <>
                            <div className='row mt-3 mb-4 pe-0'>
                                <div className='col-md-3 align-self-center'>
                                    <label className="form-label my-0">Filtrar por Comercio</label>
                                </div>
                                <div className='col-md-9 px-0'>
                                    <Select
                                        value={selectedCommerce}
                                        onChange={handleCommerceChange}
                                        options={commerces}
                                        placeholder="Seleccionar Comercio"
                                    />
                                </div>
                            </div>
                            {selectedCommerce && salesPoints.length > 0 && (
                                <div className='row mt-0 mb-4 pe-0'>
                                    <div className='col-md-3 align-self-center'>
                                        <label className="form-label my-0">Filtrar por Punto de Venta</label>
                                    </div>
                                    <div className='col-md-9 px-0'>
                                        <Select
                                            value={selectedSalesPoint}
                                            onChange={handleSalesPointChange}
                                            options={salesPoints}
                                            placeholder="Seleccionar Punto de Venta"
                                        />
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                    <div className="row mt-0 mb-4 pe-0">
                        <div className='col-md-3 align-self-center'>
                            <label className="form-label my-0">Filtrar por Fecha</label>
                        </div>
                        <div className='col-md-9 px-0'>
                            <DateRangePicker
                                onApply={handleDateRangeChange} // Usa onApply para cuando el usuario da click en Apply
                                initialSettings={{
                                    startDate: startDate.format('YYYY-MM-DD'),
                                    endDate: endDate.format('YYYY-MM-DD'),
                                    locale: {
                                        format: 'YYYY-MM-DD',
                                    },
                                }}
                            >
                                <input type="text" className="form-control" placeholder="Seleccionar Rango de Fechas" />
                            </DateRangePicker>
                        </div>
                    </div>
                    <div className="col-md-12">
                        {/* Componente de resumen de ventas por tipo de pago */}
                        <VentasResumen sales={sales} />
                    </div>
                    <div className="col-md-12">
                        <VentasListTab sales={sales} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ListaVentasAdmin;
