import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { MenuList } from '../../../layouts/nav/Menu'; // Asegúrate de que este archivo exporte la lista de menús

const InicioGrid = () => {
  const [userRole, setUserRole] = useState(null);
  const [allowedMenuItems, setAllowedMenuItems] = useState([]);

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (userDetails && userDetails?.userData?.role?.role) {
      setUserRole(userDetails.userData.role.role);
    }
  }, []); // El efecto se ejecuta solo una vez, cuando se monta el componente

  useEffect(() => {
    if (userRole) {
      const filteredMenuItems = MenuList.filter(menuItem => 
        menuItem.allowedRoles && menuItem.allowedRoles.includes(userRole) && menuItem.to !== '/login' && menuItem.to !== '/inicio'
      );
      setAllowedMenuItems(filteredMenuItems);
    }
  }, [userRole]); // Este efecto se ejecuta cuando el userRole cambia

  return (
    <div className="row">
      {allowedMenuItems.map((menuItem, index) => (
        <div className="col-xl-3 col-lg-4 col-sm-6 mb-4" key={index}>
          <Card className="h-100 text-center shadow-sm">
            <Card.Body>
              <i className={`${menuItem.iconStyle} fa-2x mb-3 p-3`} style={{ borderRadius: '150px', border: '3px solid rgb(255 204 2)', color: 'rgb(72 91 137)'}} aria-hidden="true"></i>
              <Card.Title style={{color: '#222b40'}}>{menuItem.title}</Card.Title>
              <Link to={menuItem.to} className="btn btn-primary">
                Ir a {menuItem.title}
              </Link>
            </Card.Body>
          </Card>
        </div>
      ))}
    </div>
  );
};

export default InicioGrid;
