import axios from 'axios';

// Crear un nuevo punto de venta
export async function createSalesPoint(formData) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/sales-points`, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json', // Indicamos que estamos enviando archivos
        },
    });

    return response;
}

// Obtener puntos de venta asociados a un comercio
export async function getSalesPoints(commerceId) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/sales-points/by-commerce/${commerceId}`, {
        headers: {
            Authorization: `Bearer ${token}`, // Aquí enviamos el token como Bearer
            'Content-Type': 'application/json',
        },
    });
    return response;
}

// Actualizar un punto de venta
export async function updateSalesPoint(salesPointId, formData) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.put(`${process.env.REACT_APP_API_URL}/sales-points/${salesPointId}`, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json', // Indicamos que estamos enviando archivos
        },
    });

    return response;
}

// Obtener un punto de venta por ID
export async function getSalesPointById(salesPointId) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/sales-points/${salesPointId}`, {
        headers: {
            Authorization: `Bearer ${token}`, // Aquí enviamos el token como Bearer
            'Content-Type': 'application/json',
        },
    });

    return response;
}

// Eliminar un punto de venta
export async function deleteSalesPoint(salesPointId) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/sales-points/${salesPointId}`, {
        headers: {
            Authorization: `Bearer ${token}`, // Aquí enviamos el token como Bearer
            'Content-Type': 'application/json',
        },
    });

    return response;
}
