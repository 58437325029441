import React, { useState, useEffect, useRef } from "react";
import { Nav } from 'react-bootstrap'; 
import { findProductByNameOrBarcode, findServiceByName } from "../../../../services/VentaService";
import { getProductosBySalesPoint } from "../../../../services/ProductosService";
import { getServicesBySalesPoint } from "../../../../services/ServiciosService";

const BuscarProductosServicios = ({ agregarAlCarrito }) => {
  const [busqueda, setBusqueda] = useState("");
  const [resultados, setResultados] = useState([]);
  const [tipoBusqueda, setTipoBusqueda] = useState("producto");  
  const inputRef = useRef(null); 
  const resultadosRef = useRef(null); // Referencia para la lista de resultados

  // Función para obtener commerceId y salePointId del local storage
  const getCommerceAndSalePointIds = () => {
    const commerceData = localStorage.getItem("selectedCommerce");
    const salePointData = localStorage.getItem("selectedSalesPoint");

    const commerceId = commerceData ? JSON.parse(commerceData).value : null;
    const salePointId = salePointData ? JSON.parse(salePointData).value : null;

    return { commerceId, salePointId };
  };

  // Hook para cargar productos o servicios al inicializar el componente o cuando el tipo de búsqueda cambia
  useEffect(() => {
    const { commerceId, salePointId } = getCommerceAndSalePointIds();

    if (commerceId && salePointId) {
      cargarResultadosIniciales(commerceId, salePointId);
    }
  }, [tipoBusqueda]);

  const cargarResultadosIniciales = async (commerceId, salePointId) => {
    try {
      let resultados = [];
      if (tipoBusqueda === "producto") {
        resultados = await getProductosBySalesPoint(salePointId);
        console.log("resultados productos", resultados.data);
      } else if (tipoBusqueda === "servicio") {
        resultados = await getServicesBySalesPoint(salePointId);
        console.log("resultados servicios", resultados.data);
      }
      setResultados(resultados.data);
    } catch (error) {
      console.error("Error al cargar resultados iniciales:", error);
    }
  };

  const buscarProducto = async () => {
    console.log(`Buscando ${tipoBusqueda === "producto" ? "Producto" : "Servicio"} con el término: ${busqueda}`);

    const { commerceId, salePointId } = getCommerceAndSalePointIds();

    if (!commerceId || !salePointId) {
      console.error("commerceId o salePointId no están definidos en el local storage.");
      return;
    }

    try {
      let resultados = [];
      if (tipoBusqueda === "producto") {
        resultados = await findProductByNameOrBarcode(busqueda.toLowerCase(), commerceId, salePointId);
      } else if (tipoBusqueda === "servicio") {
        resultados = await findServiceByName(busqueda.toLowerCase(), commerceId, salePointId);
      }

      setResultados(Array.isArray(resultados) ? resultados : []);
      console.log(`Resultados devueltos:`, resultados);
    } catch (error) {
      console.error("Error en la búsqueda:", error);
    }
  };

  const agregarItem = (item) => {
    // Solo verificar el stock si el tipo es producto
    if (tipoBusqueda === "producto" && item.quantity <= 0) {
      return; // Si es producto y no hay stock, no permitir agregar
    }

    // Agregar el ítem al carrito sin restricciones para los servicios
    const itemConTipo = { ...item, type: tipoBusqueda === "producto" ? "product" : "service" };
    agregarAlCarrito(itemConTipo);
    setBusqueda(""); // Limpia solo el campo de búsqueda
    inputRef.current.focus(); // Mantiene el enfoque en el campo de búsqueda

    // Recargar la lista completa de productos o servicios después de agregar un ítem al carrito
    const { commerceId, salePointId } = getCommerceAndSalePointIds();
    if (commerceId && salePointId) {
      cargarResultadosIniciales(commerceId, salePointId); // Cargar todos los productos o servicios nuevamente
    }
  };

  // Función para manejar el scroll y cargar más resultados si es necesario
  const handleScroll = () => {
    if (resultadosRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = resultadosRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 50) {
        console.log("Cargar más resultados...");
      }
    }
  };

  return (
    <div className="buscar-productos-servicios">
      {/* Agregamos las tabs para Producto/Servicio con alineación a la derecha */}
      <div className="row justify-content-end">
        <div className="col-md-6">
          <Nav as="ul" className="nav nav-tabs dzm-tabs" id="myTab" role="tablist">
            <Nav.Item as="li" className="nav-item w-100" role="presentation">
              <Nav.Link
                as="button"
                type="button"
                className="w-100"
                style={{ borderRadius: '10px' }}
                active={tipoBusqueda === "producto"}
                onClick={() => setTipoBusqueda("producto")}
              >
                Producto
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" className="nav-item w-100" role="presentation">
              <Nav.Link
                as="button"
                type="button"
                className="w-100"
                style={{ borderRadius: '10px' }}
                active={tipoBusqueda === "servicio"}
                onClick={() => setTipoBusqueda("servicio")}
              >
                Servicio
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
      </div>

      {/* Campo de búsqueda */}
      <input
        type="text"
        className="form-control mt-3"
        placeholder={`Buscar ${tipoBusqueda === "producto" ? "Producto" : "Servicio"}`}
        value={busqueda}
        onChange={(e) => setBusqueda(e.target.value)}
        onKeyPress={(e) => { if (e.key === 'Enter') buscarProducto(); }}
        ref={inputRef}
      />

      {/* Resultados de la búsqueda con scroll */}
      <div 
        className="resultados-scroll mt-2"
        ref={resultadosRef}
        onScroll={handleScroll}
        style={{
          maxHeight: '70vh',  // Limitar altura para permitir espacio para el footer
          overflowY: 'auto',
          backgroundColor: '#ffffff',
          border: '1px solid #ddd',
          borderRadius: '8px',
          padding: '10px'
        }}
      >
        <ul className="list-group">
          {Array.isArray(resultados) && resultados.length > 0 ? resultados.map((item) => (
            <li 
              key={item.barcode || item.id} 
              className={`list-group-item d-flex justify-content-between align-items-center selector-products ${tipoBusqueda === "producto" && item.quantity === 0 ? 'disabled' : ''}`} 
              onClick={() => agregarItem(item)}
              style={{
                borderBottom: '1px solid #ddd',
                padding: '10px 15px',
                cursor: tipoBusqueda === "producto" && item.quantity <= 0 ? 'not-allowed' : 'pointer',
                opacity: tipoBusqueda === "producto" && item.quantity <= 0 ? 0.5 : 1, // Aplicar restricciones solo a productos
              }}
            >
              <div className="d-flex align-items-center">
                {tipoBusqueda === "producto" ? (
                  <>
                    <img 
                      src={item.images && item.images.length ? item.images[0] : 'https://via.placeholder.com/50'} 
                      alt={item.name} 
                      style={{
                        width: '50px',
                        height: '50px',
                        borderRadius: '50%',
                        border: '2px solid #ddd',
                        marginRight: '10px',
                        backgroundColor: '#fff',
                      }} 
                    />
                    <div style={{ borderLeft: '2px solid #ddd' }} className="ps-3">
                      <strong className="text-capitalize">{item.name}</strong>
                      <p className="mb-0">Cantidad: {item.quantity}</p>
                      <p className="mb-0">Código de Barras: {item.barcode || '-'}</p>
                      <p className="mb-0">Precio de Venta: {formatearMoneda(item.salePrice)}</p>
                    </div>
                  </>
                ) : (
                  <div>
                    <strong className="text-capitalize">{item.name}</strong>
                    <p className="mb-0">Descripción: {item.description || '-'}</p>
                    <p className="mb-0">Comisión: {item.commissionPercentage || '0'}%</p>
                    <p className="mb-0">Precio de Venta: {formatearMoneda(item.salePrice)}</p>
                  </div>
                )}
              </div>
              <span>{formatearMoneda(item.salePrice)}</span>
            </li>
          )) : (
            <li className="list-group-item text-center">No hay resultados para mostrar</li>
          )}
        </ul>
      </div>
    </div>
  );
};

const formatearMoneda = (valor) => {
  if (valor === null || valor === undefined) return '-';
  return Number(valor).toLocaleString('es-CO', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
  });
};

export default BuscarProductosServicios;
