import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Offcanvas, Alert } from 'react-bootstrap';
import { createCommerce } from '../../services/ComerciosService';
import SelectCategory from './SelectCategory'; // Importa el componente de categorías

const ComercioCanvas = forwardRef((props, ref) => {
    const [name, setName] = useState('');
    const [nit, setNit] = useState('');
    const [description, setDescription] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [logo, setLogo] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null); // Nuevo estado para la categoría
    const [addCommerce, setAddCommerce] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    // Obtener el userDetails desde el localStorage
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const adminId = userDetails?.userData?.id || null;

    useImperativeHandle(ref, () => ({
        showEmployeModal() {
            setAddCommerce(true);
        }    
    }));

    const handleFileChange = (e) => {
        setLogo(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append('name', name);
        formData.append('nit', nit);
        formData.append('description', description);
        formData.append('address', address);
        formData.append('phone', phone);
        if (logo) {
            formData.append('logo', logo);
        }

        if (selectedCategory) {
            formData.append('category', selectedCategory.value); // Añadir la categoría al FormData
        }

        if (adminId) {
            formData.append('adminId', adminId);
        } else {
            setAlertMessage('Error: No se ha encontrado el adminId.');
            setAlertVariant('danger');
            setLoading(false);
            return;
        }

        try {
            const response = await createCommerce(formData);
            setAlertMessage('Comercio creado exitosamente');
            setAlertVariant('success');
            props.onCommerceCreated(response.data);

            setTimeout(() => {
                setAddCommerce(false);
            }, 1000);
        } catch (error) {
            console.error('Error creando el comercio:', error);
            setAlertMessage('Hubo un error creando el comercio. Intente nuevamente.');
            setAlertVariant('danger');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (alertMessage) {
            setShowAlert(true);
            const timeout = setTimeout(() => {
                setShowAlert(false);
                setTimeout(() => setAlertMessage(''), 300);
            }, 3000);
            return () => clearTimeout(timeout);
        }
    }, [alertMessage]);

    return (
        <>
            <Offcanvas show={addCommerce} onHide={() => setAddCommerce(false)} className="offcanvas-end customeoff" placement='end'>
                <div className="offcanvas-header">
                    <h5 className="modal-title">{props.Title}</h5>
                    <button type="button" className="btn-close" onClick={() => setAddCommerce(false)}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div className="offcanvas-body">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className="form-label">Nombre del Comercio <span className="text-danger">*</span></label>
                            <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} required />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Nit <span className="text-danger">*</span></label>
                            <input type="text" className="form-control" value={nit} onChange={(e) => setNit(e.target.value)} required />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Descripción</label>
                            <textarea className="form-control" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Dirección</label>
                            <input type="text" className="form-control" value={address} onChange={(e) => setAddress(e.target.value)} required />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Teléfono</label>
                            <input type="text" className="form-control" value={phone} onChange={(e) => setPhone(e.target.value)} required />
                        </div>
                        <SelectCategory selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
                        <div className="mb-3">
                            <label>Logo del Comercio (opcional)</label>
                            <input type="file" className="form-control" onChange={handleFileChange} />
                        </div>

                        {alertMessage && (
                            <Alert variant={alertVariant} className={`fade ${showAlert ? 'show' : ''}`}>
                                {alertMessage}
                            </Alert>
                        )}

                        <div className="mb-5 mt-3">
                            <button type="submit" className="btn btn-primary" disabled={loading}>
                                {loading ? 'Creando...' : 'Crear Comercio'}
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary ms-2"
                                onClick={() => setAddCommerce(false)}
                            >
                                Cancelar
                            </button>
                        </div>
                    </form>
                </div>
            </Offcanvas>
        </>
    );
});

export default ComercioCanvas;
