import React, { useEffect, useState } from "react";
import Select from 'react-select';
import ModalSeleccionEmpleado from "./ModalSeleccionEmpleado";

const CarritoVentas = ({ carrito, setCarrito, subtotal, setSubtotal, impuestos, setImpuestos, total, setTotal, taxes, salesPointId, assignedUser = {}, setAssignedUser }) => {
  const [selectedTaxes, setSelectedTaxes] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [currentService, setCurrentService] = useState(null);

  // Abrir el modal para asignar usuario al servicio
  const handleAgregarServicio = (item) => {
    setCurrentService(item); 
    setShowModal(true);
  };

  // Guardar usuario asignado o eliminar asignación si se deselecciona el empleado
  const handleGuardarUsuario = (empleado) => {
    setAssignedUser(prev => {
      const updatedAssignedUser = { ...prev };
      if (empleado) {
        updatedAssignedUser[currentService.id] = empleado; // Asignamos el empleado por ID del servicio
      } else {
        delete updatedAssignedUser[currentService.id]; // Eliminamos la asignación si no hay empleado seleccionado
      }
      return updatedAssignedUser;
    });
    setShowModal(false);
  };

  // Eliminar un ítem del carrito
  const eliminarItem = (id) => {
    const nuevoCarrito = carrito.filter(item => item.id !== id);

    // Eliminar el impuesto seleccionado
    const newSelectedTaxes = { ...selectedTaxes };
    delete newSelectedTaxes[id];

    // Eliminar el empleado asignado si el item eliminado es un servicio con empleado asignado
    const newAssignedUser = { ...assignedUser };
    delete newAssignedUser[id];

    // Actualizar los estados
    setCarrito(nuevoCarrito);
    setSelectedTaxes(newSelectedTaxes);
    setAssignedUser(newAssignedUser);
  };

  // Cambiar la cantidad, asegurando que no supere la cantidad disponible en stock
  const cambiarCantidad = (id, nuevaCantidad) => {
    const nuevoCarrito = carrito.map(item => {
      if (item.id === id) {
        const cantidadValida = Math.min(nuevaCantidad, item.quantity);
        return { ...item, cantidad: cantidadValida > 0 ? cantidadValida : 1 };
      }
      return item;
    });
    setCarrito(nuevoCarrito);
  };

  // Cambiar el descuento
  const cambiarDescuento = (id, nuevoDescuento) => {
    const nuevoCarrito = carrito.map(item => {
      if (item.id === id) {
        const descuentoValido = Math.max(0, parseFloat(nuevoDescuento) || 0); // Evita valores negativos
        return { ...item, descuento: descuentoValido };
      }
      return item;
    });
    setCarrito(nuevoCarrito);
  };

  const cambiarImpuesto = (id, selectedTax) => {
    const nuevoCarrito = carrito.map(item =>
      item.id === id ? { ...item, selectedTax } : item
    );
    setCarrito(nuevoCarrito);
    const updatedTaxes = {
      ...selectedTaxes,
      [id]: {
        ...selectedTax, 
        idTax: selectedTax.value,
      },
    };
    setSelectedTaxes(updatedTaxes);
  };

  const formatearMoneda = (valor) => {
    if (valor === null || valor === undefined) return '-';
    return Number(valor).toLocaleString('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
  };

  useEffect(() => {
    carrito.forEach(item => {
      if (!selectedTaxes[item.id]) {
        setSelectedTaxes(prev => ({
          ...prev,
          [item.id]: { label: "0% (Exento)", value: "default", percentage: "0" }
        }));
      }
    });
  }, [carrito]);

  useEffect(() => {
    const recalcularTotales = () => {
      let newSubtotal = 0;
      let newImpuestos = 0;
  
      carrito.forEach(item => {
        const itemCantidad = item.cantidad > 0 ? item.cantidad : 1;
        const itemPrecio = parseFloat(item.salePrice) || 0;
        const descuento = item.descuento || 0; // Descuento aplicado

        // Precio con descuento aplicado
        const precioFinal = Math.max(0, itemPrecio - descuento);
        const itemTotal = itemCantidad * precioFinal;
        newSubtotal += itemTotal;

        const selectedTax = selectedTaxes[item.id];
        const taxRate = selectedTax && selectedTax.percentage ? parseFloat(selectedTax.percentage) / 100 : 0;
        newImpuestos += itemTotal * taxRate;
      });
  
      setSubtotal(parseFloat(newSubtotal.toFixed(2)));
      setImpuestos(parseFloat(newImpuestos.toFixed(2)));
      setTotal(parseFloat((newSubtotal + newImpuestos).toFixed(2)));
    };
  
    recalcularTotales();
  }, [carrito, selectedTaxes, setSubtotal, setImpuestos, setTotal]);

  return (
    <div className="carrito-ventas p-3" style={{ overflowX: 'auto', backgroundColor: '#fff', borderRadius: '5px', border: '1px solid #ccc' }}>
      <h4>Resumen de Venta</h4>
      <table className="table table-striped" style={{ tableLayout: 'fixed', width: '100%' }}>
        <thead>
          <tr>
            <th className="text-left">Item</th>
            <th className="text-left">Nombre</th>
            <th className="text-center"><i className="fa-sharp fa-light fa-user-plus"></i></th>
            <th className="text-center" style={{ width: '80px' }}>Cant</th>
            <th className="text-center" style={{ width: '100px' }}>Descuento</th>
            <th className="text-center">V. Unit.</th>
            <th className="text-center">Impuesto</th>
            <th className="text-end">Total</th>
            <th className="text-end"><i className="fa-sharp fa-light fa-trash-xmark"></i></th>
          </tr>
        </thead>
        <tbody>
          {carrito.map(item => (
            <tr key={item.id}>
              <td>{item.barcode || <span>-</span>}</td>
              <td className="text-capitalize">
                {item.name}
                {item.type === "service" && assignedUser && assignedUser[item.id] && (
                  <span style={{ fontSize: 'smaller' }}><br />({assignedUser[item.id].label})</span>
                )}
              </td>
              <td className="text-center">
                {item.type === "service" && (
                  <button className="btn btn-sm btn-primary" onClick={() => handleAgregarServicio(item)}>
                    <i className="fa-sharp fa-light fa-user-plus"></i>
                  </button>
                )}
              </td>
              <td>
                <input
                  type="number"
                  className="form-control"
                  value={item.cantidad ?? 1}
                  onChange={(e) => cambiarCantidad(item.id, parseInt(e.target.value))}
                  min="1"
                  max={item.quantity} // Agregar el máximo según el stock disponible
                />
              </td>
              <td>
                <input
                  type="number"
                  className="form-control"
                  value={item.descuento || 0}
                  onChange={(e) => cambiarDescuento(item.id, e.target.value)}
                  min="0"
                />
              </td>
              <td className="text-end">{formatearMoneda(item.salePrice)}</td>
              <td>
                <Select
                  value={selectedTaxes[item.id] || null}
                  onChange={(selectedOption) => cambiarImpuesto(item.id, selectedOption)}
                  options={taxes.map(tax => ({
                    label: `(${tax.percentage}%) ${tax.name}`,
                    value: tax.id,
                    percentage: tax.percentage
                  }))}
                  placeholder="Seleccione Impuesto"
                />
              </td>
              <td className="text-end">
                {formatearMoneda((item.cantidad * (parseFloat(item.salePrice) || 0)) - (item.descuento || 0))}
              </td>
              <td className="text-end">
                <button className="btn btn-sm btn-danger" onClick={() => eliminarItem(item.id)}>
                  <i className="fa-sharp fa-light fa-trash-xmark"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="totales mt-3">
        <p className="totales-item"><span>Subtotal</span> <span className="totales-dotted-line"></span> <strong>{formatearMoneda(subtotal)}</strong></p>
        <p className="totales-item"><span>Impuestos</span> <span className="totales-dotted-line"></span> <strong>{formatearMoneda(impuestos)}</strong></p>
        <p className="totales-item"><span>Total</span> <span className="totales-dotted-line"></span> <strong>{formatearMoneda(total)}</strong></p>
      </div>

      <ModalSeleccionEmpleado
        show={showModal}
        onClose={() => setShowModal(false)}
        onSave={handleGuardarUsuario}
        salesPointId={salesPointId}
        empleadoPreseleccionado={assignedUser ? assignedUser[currentService?.id] : null}
      />
    </div>
  );
};

export default CarritoVentas;
