import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";

import "./index.css";
import "./chart.css";
import "./step.css";

import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";

// Mis Rutas
import CrearVentaAdmin from "./components/Dashboard/crearVenta/CrearVentaAdmin";
import ListaVentasAdmin from "./components/Dashboard/ListaVentasAdmin";
import ComerciosAdmin from "./components/Dashboard/ComerciosAdmin";
import PuntosVentaAdmin from "./components/Dashboard/PuntosVentaAdmin";
import UsuariosComercioAdmin from "./components/Dashboard/UsuariosComercioAdmin";
import ClientesAdmin from "./components/Dashboard/ClientesAdmin";
import ProductosAdmin from "./components/Dashboard/ProductosAdmin";
import ServiciosAdmin from "./components/Dashboard/ServiciosAdmin";
import InicioAdmin from "./components/Dashboard/inicio/InicioDashboardAdmin";

// Dashboard
import Home from "./components/Dashboard/Home";

// Pages de error
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";

import { ThemeContext } from "../context/ThemeContext";
import CommerceSelectionModal from './components/Dashboard/CommerceSelectionModal'; // Modal para seleccionar comercio y punto de venta

// Función para proteger rutas según roles
const ProtectedRoute = ({ component, allowedRoles }) => {
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));

  // Verifica si los datos están disponibles
  if (!userDetails || !userDetails.userData) {
    console.log('No userDetails found, redirecting to login');
    return <Navigate to="/login" />;
  }

  const userRole = userDetails.userData.role.role;
  console.log('User role:', userRole);

  // Si el rol no está permitido, redirige a la página de dashboard
  if (!allowedRoles.includes(userRole)) {
    console.log('User does not have permission, redirecting to dashboard');
    return <Navigate to="/dashboard" />;
  }

  // Si todo está bien, renderiza el componente
  return component;
};


// Rutas disponibles en la aplicación
const allroutes = [
  { url: "", component: <Home /> },
  { url: "crear-ventas", component: <CrearVentaAdmin />, roles: ['usuario-comercio'] },
  { url: "listar-ventas", component: <ListaVentasAdmin />, roles: ['administrador', 'usuario-comercio'] },
  { url: "admin-comercios", component: <ComerciosAdmin />, roles: ['administrador'] },
  { url: "admin-puntos-venta", component: <PuntosVentaAdmin />, roles: ['administrador'] },
  { url: "admin-usuarios-comercio", component: <UsuariosComercioAdmin />, roles: ['administrador'] },
  { url: "admin-clientes-comercio", component: <ClientesAdmin />, roles: ['administrador'] },
  { url: "productos-comercio", component: <ProductosAdmin />, roles: ['administrador', 'usuario-comercio'] },
  { url: "servicios-comercio", component: <ServiciosAdmin />, roles: ['administrador', 'usuario-comercio'] },
  { url: "dashboard", component: <Home />, roles: ['administrador', 'usuario-comercio'] },
  { url: "inicio", component: <InicioAdmin />, roles: ['administrador', 'usuario-comercio'] }
];

// Función para manejar errores 404
function NotFound() {
  const url = allroutes.map((route) => route.url);
  let path = window.location.pathname;
  path = path.split('/');
  path = path[path.length - 1];
  
  if (url.indexOf(path) <= 0) {
    return <Error404 />;
  }
}

// Componente principal
const Markup = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    console.log('User details after reload:', userDetails); // Verifica si `localStorage` se lee correctamente al recargar

    if (userDetails) {
      const role = userDetails?.userData?.role?.role;
      console.log('User role after reload:', role);

      if (role === 'usuario-comercio') {
        const selectedCommerce = localStorage.getItem('selectedCommerce');
        const selectedSalesPoint = localStorage.getItem('selectedSalesPoint');
        if (!selectedCommerce || !selectedSalesPoint) {
          setShowModal(true); // Mostrar el modal si no hay selección
        }
      }
    } else {
      console.log("No userDetails found, redirecting to login");
      window.location.href = '/login'; // Redirige si no se encuentran detalles de usuario
    }
  }, []);

  // Función para cerrar el modal después de guardar la selección
  const handleSave = () => {
    setShowModal(false);
  };

  return (
    <>
      <CommerceSelectionModal show={showModal} onSave={handleSave} />
      <Routes>
        <Route path='/page-lock-screen' element={<LockScreen />} />
        <Route path='/page-error-400' element={<Error400/>} />
        <Route path='/page-error-403' element={<Error403/>} />
        <Route path='/page-error-404' element={<Error404/>} />
        <Route path='/page-error-500' element={<Error500/>} />
        <Route path='/page-error-503' element={<Error503/>} />

        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route
              key={i}
              path={`${data.url}`}
              element={<ProtectedRoute component={data.component} allowedRoles={data.roles} />}
            />
          ))}
        </Route>
        <Route path='*' element={<NotFound/>} />
      </Routes>
      <ScrollToTop />
    </>
  );
};

// Layout principal
function MainLayout() {
  const { menuToggle, sidebariconHover } = useContext(ThemeContext);
  const sideMenu = useSelector(state => state.sideMenu);

  return (
    <div id="main-wrapper" className={`show ${sidebariconHover ? "iconhover-toggle" : ""} ${sideMenu ? "menu-toggle" : ""}`}>
      <Nav />
      <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default Markup;
