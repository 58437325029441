import axios from 'axios';

// Obtener token desde el localStorage
const getToken = () => {
  const tokenDetailsString = localStorage.getItem('userDetails');
  const tokenDetails = JSON.parse(tokenDetailsString);
  return tokenDetails.token;
};

// Crear una venta
export async function createSale(saleData) {
  const token = getToken();
  console.log("Data completa a enviar para creación de la venta", saleData)
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/sales`, saleData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response;
  } catch (error) {
    console.error('Error al crear la venta:', error);
    throw error;
  }
}

// Buscar productos por código de barras o nombre
export async function findProductByNameOrBarcode(searchTerm, commerceId, salePointId) {
  const token = getToken();
  
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/products/search/${searchTerm}/${commerceId}/${salePointId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error al buscar productos:', error);
    throw error;
  }
}

// Buscar servicios por nombre
export async function findServiceByName(searchTerm, commerceId, salePointId) {
  const token = getToken();
  
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/services/searchByName/${searchTerm}/${commerceId}/${salePointId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error al buscar servicios:', error);
    throw error;
  }
}

// Listar impuestos
export async function listTaxes() {
  const token = getToken();

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/taxes`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    console.log('Impuestos cargados Service:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error al listar impuestos:', error);
    throw error;
  }
}

// Obtener stock disponible de un producto
export async function getProductStock(productId) {
  const token = getToken();
  
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/products/${productId}/stock`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error al obtener stock de producto:', error);
    throw error;
  }
}

// Buscar clientes por dni
export async function findCustomerByDni(DocumentNumber, commerceId) {
  const token = getToken();
  
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/customers/dni/${DocumentNumber}/${commerceId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error al buscar clientes:', error);
    throw error;
  }
}

// Filtrar ventas por comercio con rango de fechas
export async function getSalesByCommerce(commerceId, startDate = null, endDate = null) {
  const token = getToken();
  let url = `${process.env.REACT_APP_API_URL}/sales/commerce/${commerceId}`;
  
  // Agregar parámetros de fecha si existen
  if (startDate && endDate) {
    url += `?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response;
  } catch (error) {
    console.error('Error al obtener ventas por comercio:', error);
    throw error;
  }
}

// Filtrar ventas por punto de venta con rango de fechas
export async function getSalesBySalesPoint(commerceId, salesPointId, startDate = null, endDate = null) {
  const token = getToken();
  let url = `${process.env.REACT_APP_API_URL}/sales/sales-point/${salesPointId}`;
  
  // Agregar parámetros de fecha si existen
  if (startDate && endDate) {
    url += `?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response;
  } catch (error) {
    console.error('Error al obtener ventas por punto de venta:', error);
    throw error;
  }
}