import axios from 'axios';
import swal from "sweetalert";
import {
    loginConfirmedAction,
    Logout,
} from '../store/actions/AuthActions';

export function signUp(email, password) {
    //axios call
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        `${process.env.REACT_APP_API_URL}/auth/signup`,
        postData,
    );
}

export function login(email, password) {
    const postData = {
        email,
        password,
    };

    // Cambiar la URL a tu backend
    return axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        postData
    );
}

export function formatError(errorResponse) {
    // Reemplazar el manejo de errores según el formato de tu backend
    console.log("Error Message", errorResponse)
    switch (errorResponse.message) {
        case 'EMAIL_EXISTS':
            swal("Oops", "Email already exists", "error");
            break;
        case 'EMAIL_NOT_FOUND':
            swal("Oops", "Email not found", "error", { button: "Try Again!" });
            break;
        case 'INVALID_PASSWORD':
            swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
            break;
        default:
            swal("Oops", errorResponse.error === "Bad Request" ? errorResponse.message[0] : errorResponse.message, "error", { button: "Try Again!" });
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    const { idToken, expiresIn, userData } = tokenDetails;
    const expireDate = new Date(new Date().getTime() + expiresIn * 1000);
    console.log("TOKEN: ", idToken);
    localStorage.setItem('userDetails', JSON.stringify({
        token: idToken,
        expireDate,
        userData,
    }));
}

export function runLogoutTimer(dispatch, timer, navigate) {
    setTimeout(() => {
        //dispatch(Logout(history));
        dispatch(Logout(navigate));
    }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    if (!tokenDetailsString) {
        dispatch(Logout(navigate));
        return;
    }

    const tokenDetails = JSON.parse(tokenDetailsString);
    const expireDate = new Date(tokenDetails.expireDate);
    const todaysDate = new Date();

    if (todaysDate > expireDate) {
        dispatch(Logout(navigate));
        return;
    }

    dispatch(loginConfirmedAction(tokenDetails));
    const timer = expireDate.getTime() - todaysDate.getTime();
    runLogoutTimer(dispatch, timer, navigate);
}

export function isLogin() {
    const tokenDetailsString = localStorage.getItem('userDetails');

    if (tokenDetailsString) {
        return true;
    }else{
        return false;
    }
}