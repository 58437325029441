import axios from 'axios';

// Crear un nuevo punto de venta
export async function getAllCustomersByCommerce(commerceId) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/customers/commerce/${commerceId}`, {
        headers: {
            Authorization: `Bearer ${token}` // Aquí enviamos el token como Bearer
        }
    });
    console.log("response clientes", response);
    return response;
}
