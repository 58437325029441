import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { deleteService } from '../../../../services/ServiciosService';

const GridTab = ({ servicios, openEditModal, onServicioDeleted }) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [servicioToDelete, setServicioToDelete] = useState(null);
    const [showResultModal, setShowResultModal] = useState(false);
    const [resultMessage, setResultMessage] = useState('');
    const [resultVariant, setResultVariant] = useState('success');

    const handleDeleteClick = (servicioId) => {
        setServicioToDelete(servicioId);
        setShowDeleteModal(true);
    };

    const confirmDeleteServicio = async () => {
        try {
            await deleteService(servicioToDelete);
            setResultMessage('Servicio eliminado exitosamente.');
            setResultVariant('success');
            onServicioDeleted();
        } catch (error) {
            setResultMessage('Error al eliminar el servicio. Intente nuevamente.');
            setResultVariant('danger');
            console.error('Error al eliminar el servicio:', error);
        } finally {
            setShowDeleteModal(false);
            setShowResultModal(true);
        }
    };

    return (
        <>
            <div className="row">
                {servicios.map((servicio, index) => (
                    <div className="col-xl-3 col-lg-4 col-sm-6" key={index}>
                        <div className="card">
                            <div className="card-body">
                                <div className="card-use-box">
                                    <div className="card__text">
                                        <h4 className="mb-3 text-capitalize">{servicio.name}</h4>
                                        <p className="mb-0">Precio Base: {formatearMoneda(servicio.basePrice) || "-"}</p>
                                        <p>Precio Venta: {formatearMoneda(servicio.salePrice) || "-"}</p>
                                        <p>Comisión: {servicio.commissionPercentage || "-"}%</p>
                                    </div>
                                    <div className="d-flex justify-content-center mt-3">
                                        <button className="btn btn-info btn-sm me-3" onClick={() => openEditModal(servicio.id)}>Editar</button>
                                        <button className="btn btn-danger btn-sm" onClick={() => handleDeleteClick(servicio.id)}>Eliminar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/* Modal de confirmación de eliminación */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar eliminación</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Estás seguro de que deseas eliminar este servicio? Esta acción no se puede deshacer.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancelar</Button>
                    <Button variant="danger" onClick={confirmDeleteServicio}>Eliminar</Button>
                </Modal.Footer>
            </Modal>

            {/* Modal de resultado de la eliminación */}
            <Modal show={showResultModal} onHide={() => setShowResultModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{resultVariant === 'success' ? 'Éxito' : 'Error'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{resultMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowResultModal(false)}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

const formatearMoneda = (valor) => {
    if (valor === null || valor === undefined) return '-';
    return Number(valor).toLocaleString('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
  };

export default GridTab;
