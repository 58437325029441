import React from 'react';
import { Table } from 'react-bootstrap';

const FacturaImprimible = ({ venta }) => {
  // Función para formatear los valores como moneda
  const formatearMoneda = (valor) => {
    if (valor === null || valor === undefined) return '-';
    return Number(valor).toLocaleString('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
  };

  return (
    <div id="factura-para-imprimir" className="w-100">
      <h3 className="text-center">Factura de Venta</h3>
      <p className='mb-3'><strong>Fecha:</strong> {new Date(venta.createAt).toLocaleString()}</p>
      <p className='mb-0'><strong>Comercio:</strong> {venta.commerce.name}</p>
      <p className='mb-0'><strong>Punto de Venta:</strong> {venta.salesPoint.name}</p>
      <p className='mb-0'><strong>Cliente:</strong> {venta.customer.nombre}</p>
      <p><strong>Documento:</strong> {venta.customer.tipoDocumento.value} {venta.customer.documento}</p>

      <Table responsive bordered hover className="mb-4">
        <thead className="thead-dark">
          <tr>
            <th>#</th>
            <th>Producto/Servicio</th>
            <th>Cant</th>
            <th>V. Unitario</th>
            <th>Descuento</th> {/* Nueva columna de descuento */}
            <th>Impuesto</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {venta.dataItems.map((item, index) => (
            <tr key={index}>
              <td>{item.ItemNumber}</td>
              <td>{item.name}</td>
              <td className='text-center'>{item.quantity}</td>
              <td className='text-end'>{formatearMoneda(item.unitValue)}</td>
              <td className='text-end'>{formatearMoneda(item.disccount || 0)}</td> {/* Muestra el descuento */}
              <td className='text-end'>{item.tax.percentage}%</td>
              <td className='text-end'>{formatearMoneda((item.quantity * (item.unitValue - (item.disccount || 0))))}</td> {/* Total con descuento */}
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="d-flex justify-content-between">
        <div>
          <p><strong>Subtotal:</strong></p>
          <p><strong>Impuestos:</strong></p>
          <p><strong>Total:</strong></p>
        </div>
        <div>
          <p className='text-end'>{formatearMoneda(venta.Subtotal)}</p>
          <p className='text-end'>{formatearMoneda(venta.Taxes)}</p>
          <p className='text-end'><strong>{formatearMoneda(venta.Total)}</strong></p>
        </div>
      </div>
    </div>
  );
};

export default FacturaImprimible;
