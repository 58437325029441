import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Offcanvas, Alert, Form } from 'react-bootstrap';
import Select from 'react-select';
import { updateUserAssignment } from '../../services/UsuariosComercioService';
import { getComercios } from '../../services/ComerciosService';
import { getSalesPoints } from '../../services/PuntosVentaService';

const AssignToSalesPointCanvas = forwardRef((props, ref) => {
    const [userId, setUserId] = useState(null);
    const [roleId, setRoleId] = useState(null); // Nuevo estado para almacenar el roleId
    const [commerceId, setCommerceId] = useState('');
    const [salesPointId, setSalesPointId] = useState('');
    const [commerces, setCommerces] = useState([]);
    const [salesPoints, setSalesPoints] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [showCanvas, setShowCanvas] = useState(false);
    const currentUserId = JSON.parse(localStorage.getItem('userDetails')).userData.id;

    useImperativeHandle(ref, () => ({
        // Ahora acepta tanto userId como roleId
        showAssignToSalesPointModal(userId, roleId) { 
            resetForm(); // Reiniciar el formulario cada vez que se abre el modal
            setUserId(userId);
            setRoleId(roleId); // Almacenar el roleId
            loadCommerces();
            setShowCanvas(true);
        }
    }));

    const resetForm = () => {
        setCommerceId('');
        setSalesPointId('');
        setCommerces([]);
        setSalesPoints([]);
        setAlertMessage('');
        setAlertVariant('');
        setShowAlert(false);
    };

    const loadCommerces = async () => {
        try {
            const response = await getComercios(currentUserId);
            const activeCommerces = response.data
                .filter(commerce => commerce.licenses.some(license => license.isActive && new Date(license.expirationDate) > new Date()))
                .map(commerce => ({ value: commerce.id, label: commerce.name }));
            setCommerces(activeCommerces);
        } catch (error) {
            console.error('Error al cargar los comercios:', error);
        }
    };

    const loadSalesPoints = async (commerceId) => {
        try {
            const response = await getSalesPoints(commerceId);
            setSalesPoints(response.data.map(salesPoint => ({
                value: salesPoint.id,
                label: salesPoint.name,
            })));
        } catch (error) {
            console.error('Error al cargar los puntos de venta:', error);
        }
    };

    useEffect(() => {
        if (commerceId) {
            loadSalesPoints(commerceId);
        }
    }, [commerceId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
    
        // Asegúrate de que todos los valores sean cadenas de texto
        const formData = {
            userId: String(userId),
            roleId: String(roleId),
            commerceId: String(commerceId),
            salesPointId: String(salesPointId),
        };
    
        try {
            await updateUserAssignment(formData);
            setAlertMessage('Usuario asignado al punto de venta exitosamente');
            setAlertVariant('success');
            props.onUserAssignmentUpdated(); // Recargar la lista de asignaciones en GridTab y ListTab
            setTimeout(() => setShowCanvas(false), 1000);
        } catch (error) {
            setAlertMessage(error.message === 'Request failed with status code 409' ? 'El usuario ya tiene asignado este punto de venta en el comercio.' : 'Error al asignar usuario. Intente nuevamente.');
            setAlertVariant('danger');
        } finally {
            setLoading(false);
        }
    };    

    return (
        <Offcanvas show={showCanvas} onHide={() => setShowCanvas(false)} className="offcanvas-end customeoff" placement="end">
            <div className="offcanvas-header">
                <h5>Asignar Usuario a Punto de Venta</h5>
                <button type="button" className="btn-close" onClick={() => setShowCanvas(false)}>
                    <i className="fa-solid fa-xmark"></i>
                </button>
            </div>
            <div className="offcanvas-body">
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label className="form-label">Comercio</label>
                        <Select 
                            value={commerces.find(c => c.value === commerceId)}
                            onChange={(selected) => setCommerceId(selected?.value || '')}
                            options={commerces}
                            placeholder="Seleccionar Comercio"
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable
                            required 
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Punto de Venta</label>
                        <Select 
                            value={salesPoints.find(sp => sp.value === salesPointId)}
                            onChange={(selected) => setSalesPointId(selected?.value || '')}
                            options={salesPoints}
                            placeholder="Seleccionar Punto de Venta"
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable
                            required 
                        />
                    </div>
                    {alertMessage && (
                        <Alert variant={alertVariant} className={`fade ${showAlert ? 'show' : ''}`}>
                            {alertMessage}
                        </Alert>
                    )}
                    <div className="mb-3 mt-5">
                        <button type="submit" className="btn btn-primary" disabled={loading}>
                            {loading ? 'Asignando...' : 'Asignar Usuario'}
                        </button>
                        <button type="button" className="btn btn-secondary ms-2" onClick={() => setShowCanvas(false)}>
                            Cancelar
                        </button>
                    </div>
                </form>
            </div>
        </Offcanvas>
    );
});

export default AssignToSalesPointCanvas;
