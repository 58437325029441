import axios from 'axios';

// Crear un nuevo usuario y asignarlo a un comercio y un punto de venta
export async function createUserAndAssignToCommerce(formData) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/user-assignments/create-user`, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    });

    return response;
}

// Actualizar un usuario y sus asignaciones
export async function updateUserAndAssignments(userId, formData) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.patch(`${process.env.REACT_APP_API_URL}/user-assignments/update-user/${userId}`, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    });

    return response;
}

// Eliminar un usuario y sus asignaciones
export async function deleteUserAndAssignments(userId) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;
    console.log("USuario a habilitar o inhabilitar con ID:", userId)
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/user-assignments/delete-user/${userId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    });

    return response;
}


export async function getAllUserAssignments() {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/user-assignments/all-users-assignments`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });

    return response;
}

// Obtener todas las asignaciones de usuarios por ID de comercio
export async function getAllUserAssignmentsByCommerce(commerceId) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/user-assignments/all-users-assignments-by-commerce/${commerceId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    });

    return response;
}

// Obtener todas las asignaciones de usuarios para un punto de venta específico
export async function getAllUserAssignmentsBySalesPoint(salesPointId) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/user-assignments/all-users-assignments-by-sales-point/${salesPointId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    });

    return response;
}

// Obtener una asignación de usuario por ID
export async function getUserAssignmentById(assignmentId) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/user-assignments/${assignmentId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    });

    return response;
}

// Actualizar una asignación de usuario
export async function updateUserAssignment(formData) {
    console.log('Update user assignment', formData);  // Asegúrate de que los valores sean correctos

    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/user-assignments/sales-point`, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    });

    return response;
}

// Eliminar una asignación de usuario
export async function deleteUserAssignment(assignmentId) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/user-assignments/${assignmentId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    });

    return response;
}

// Obtener todas las asignaciones de Comercios por ID de usuario
export async function getAllCommerceAssignmentsByUser(userId) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/user-assignments/commerce/${userId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    });

    return response;
}

// Obtener todas las asignaciones de puntos venta para un usuario específico, filtrado por comercio
export async function getAllSalesPointAssignmentsByUserAndCommerce(userId, commerceId) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/user-assignments/sales-point/${userId}/commerce/${commerceId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    });

    return response;
}