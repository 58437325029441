import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import { getAllCustomersByCommerce } from '../../../services/ClientesService'; // Importamos el servicio que obtiene los clientes
import { getComercios } from '../../../services/ComerciosService'; // Importamos el servicio que obtiene los comercios
import ListTab from '../AppsMenu/Clientes/ListTab';

const ClientesAdmin = () => {
    const [clientes, setClientes] = useState([]);
    const [commerces, setCommerces] = useState([]);
    const [selectedCommerce, setSelectedCommerce] = useState(null);
    
    // Obtener detalles del usuario desde el localStorage
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const userId = userDetails.userData.id;
    const userRole = userDetails.userData.role?.role;

    useEffect(() => {
        // Cargar comercios para administradores o roles que pueden gestionarlos
        loadCommerces();
    }, [userId]);

    useEffect(() => {
        if (selectedCommerce) {
            loadCustomersByCommerce(selectedCommerce.value);
        } else {
            setClientes([]); // Limpiar clientes si no hay comercio seleccionado
        }
    }, [selectedCommerce]);

    const loadCommerces = async () => {
        try {
            const response = await getComercios(userId);
            const activeCommerces = response.data.map(commerce => ({
                value: commerce.id,
                label: commerce.name,
            }));
            setCommerces(activeCommerces);

            // Si solo hay un comercio, seleccionarlo automáticamente
            if (activeCommerces.length === 1) {
                setSelectedCommerce(activeCommerces[0]);
            }
        } catch (error) {
            console.log('Error al obtener comercios:', error);
            setCommerces([]);
        }
    };

    const loadCustomersByCommerce = async (commerceId) => {
        try {
            const response = await getAllCustomersByCommerce(commerceId);
            setClientes(response.data);
        } catch (error) {
            console.log('Error al obtener clientes:', error);
        }
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row mb-4">
                    <h4 className="heading mb-0">Clientes</h4>
                </div>

                <div className='row mt-3 mb-4 pe-0'>
                    <div className='col-md-3 align-self-center'>
                        <label className="form-label my-0">Filtrar por Comercio</label>
                    </div>
                    <div className='col-md-9 px-0'>
                        <Select
                            value={selectedCommerce}
                            onChange={selectedOption => setSelectedCommerce(selectedOption)}
                            options={commerces}
                            placeholder="Seleccionar Comercio"
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable
                        />
                    </div>
                </div>

                {/* Lista de clientes */}
                <div className="col-xl-12">
                    <ListTab clientes={clientes} tableName={"Lista de Clientes"} />
                </div>
            </div>
        </>
    );
};

export default ClientesAdmin;
