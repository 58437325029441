import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Offcanvas, Alert, Form } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
import { updateProducto, getProductoById } from '../../services/ProductosService';
import Select from 'react-select';
import { getComercios } from '../../services/ComerciosService';
import { getSalesPoints } from '../../services/PuntosVentaService';

const ProductoEditCanvas = forwardRef((props, ref) => {
    const [productoId, setProductoId] = useState(null);
    const [name, setName] = useState('');
    const [barcode, setBarcode] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [purchasePrice, setPurchasePrice] = useState('');
    const [salePrice, setSalePrice] = useState('');
    const [colors, setColors] = useState(['#000000']);
    const [currentColor, setCurrentColor] = useState('#000000');
    const [sizes, setSizes] = useState([]);
    const [unitOfMeasurement, setUnitOfMeasurement] = useState(null); 
    const [logo, setLogo] = useState(null);
    const [logoUrl, setLogoUrl] = useState('');
    const [commerceId, setCommerceId] = useState(null);
    const [salesPoints, setSalesPoints] = useState([]);
    const [commerces, setCommerces] = useState([]);
    const [salesPointsOptions, setSalesPointsOptions] = useState([]);
    const [editProducto, setEditProducto] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    const unitOptions = [
        { value: 'gramos', label: 'Gramos' },
        { value: 'kilogramos', label: 'Kilogramos' },
        { value: 'libras', label: 'Libras' },
        { value: 'litros', label: 'Litros' },
        { value: 'mililitros', label: 'Mililitros' },
        { value: 'unidades', label: 'Unidades' }
    ];

    const sizeOptions = [
        { value: 'XS', label: 'XS' },
        { value: 'S', label: 'S' },
        { value: 'M', label: 'M' },
        { value: 'L', label: 'L' },
        { value: 'XL', label: 'XL' },
        { value: 'XXL', label: 'XXL' },
        { value: 'XXXL', label: 'XXXL' },
        { value: 'Grande', label: 'Grande' },
        { value: 'Mediano', label: 'Mediano' },
        { value: 'Pequeño', label: 'Pequeño' }
    ];

    useImperativeHandle(ref, () => ({
        showEditProductoModal(id) {
            resetFormFields();
            setProductoId(id);
            fetchProductoDetails(id);
            loadCommerces();
            setEditProducto(true);
            // Restablecer los valores de alerta al abrir el modal
            setAlertMessage('');
            setShowAlert(false);
        }
    }));

    const resetFormFields = () => {
        setName('');
        setBarcode('');
        setQuantity(0);
        setPurchasePrice('');
        setSalePrice('');
        setColors(['#000000']);
        setCurrentColor('#000000');
        setSizes([]);
        setUnitOfMeasurement(null);
        setLogo(null);
        setLogoUrl('');
        setCommerceId(null);
        setSalesPoints([]);
    };

    const fetchProductoDetails = async (id) => {
        try {
            const response = await getProductoById(id);
            const producto = response.data;
            setName(producto.name || '');
            setBarcode(producto.barcode || '');
            setQuantity(producto.quantity || 0);
            setPurchasePrice(producto.purchasePrice?.toString() || '');
            setSalePrice(producto.salePrice?.toString() || '');
            setColors(producto.colors || ['#000000']);
            setSizes(sizeOptions.filter(size => producto.sizes?.includes(size.value)) || []);
            setUnitOfMeasurement(unitOptions.find(option => option.value === producto.unitOfMeasurement) || null);
            setLogoUrl(producto.images[0] || '');
            setCommerceId(producto.commerce?.id || null);
            setSalesPoints(producto.salesPoints.map(sp => sp.id) || []); // Ajustado para manejar múltiples puntos de venta
        } catch (error) {
            console.error('Error al obtener los detalles del producto:', error);
            setAlertMessage('Hubo un error al cargar los detalles del producto.');
            setAlertVariant('danger');
            setShowAlert(true);
        }
    };

    const loadCommerces = async () => {
        try {
            const userDetails = JSON.parse(localStorage.getItem('userDetails'));
            const adminId = userDetails?.userData?.id || null;
            const response = await getComercios(adminId);
            const activeCommerces = response.data.map(commerce => ({
                value: commerce.id,
                label: commerce.name,
            }));
            setCommerces(activeCommerces);
        } catch (error) {
            console.error('Error al cargar los comercios:', error);
        }
    };

    const loadSalesPoints = async (commerceId) => {
        try {
            const response = await getSalesPoints(commerceId);
            setSalesPointsOptions(response.data.map(salesPoint => ({
                value: salesPoint.id,
                label: salesPoint.name,
            })));
        } catch (error) {
            console.error('Error al cargar los puntos de venta:', error);
        }
    };

    useEffect(() => {
        if (commerceId) {
            loadSalesPoints(commerceId);
        }
    }, [commerceId]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setLogo(file); // Guardar el archivo del logo
        setLogoUrl(URL.createObjectURL(file)); // Vista previa del logo cargado
    };

    const handleSubmit = async (e) => {
        console.log('Submitting form...'); 
        e.preventDefault();
        setLoading(true);
    
        try {
            const formData = new FormData();
            formData.append('name', name.toLowerCase().trim());
            formData.append('barcode', barcode);
            formData.append('quantity', Number(quantity));
            formData.append('purchasePrice', parseFloat(purchasePrice.replace(/[^0-9.-]+/g, "")));
            formData.append('salePrice', parseFloat(salePrice.replace(/[^0-9.-]+/g, "")));
            colors.forEach(color => formData.append('colors', color));
            sizes.forEach(size => formData.append('sizes', size.value));
            if (unitOfMeasurement) formData.append('unitOfMeasurement', unitOfMeasurement.value);
            formData.append('commerceId', commerceId);

            // Asegurarte de que salesPointIds sea siempre un array
            if (salesPoints.length > 0) {
                salesPoints.forEach(salesPoint => formData.append('salesPointIds[]', salesPoint)); // Agrega los salesPointIds como array
            } else {
                formData.append('salesPointIds[]', []); // Envía un array vacío si no hay puntos de venta seleccionados
            }

            if (logo) {
                formData.append('images', logo);
            }
    
            const response = await updateProducto(productoId, formData);
    
            setAlertMessage('Producto actualizado exitosamente');
            setAlertVariant('success');
            setShowAlert(true);
            props.onProductoUpdated(response.data);
    
            setTimeout(() => {
                setEditProducto(false);
                resetFormFields();
            }, 1000);
        } catch (error) {
            console.error('Error actualizando el producto:', error);
            setAlertMessage('Hubo un error actualizando el producto. Intente nuevamente.');
            setAlertVariant('danger');
            setShowAlert(true);
        } finally {
            setLoading(false);
        }
    };
    
    const handleAddColor = () => {
        if (!colors.includes(currentColor)) {
            setColors([...colors, currentColor]);
        }
    };

    const handleRemoveColor = (color) => {
        setColors(colors.filter(c => c !== color));
    };

    return (
        <>
            <Offcanvas show={editProducto} onHide={() => setEditProducto(false)} className="offcanvas-end customeoff" placement='end'>
                <div className="offcanvas-header">
                    <h5>Editar Producto</h5>
                    <button type="button" className="btn-close" onClick={() => setEditProducto(false)}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div className="offcanvas-body">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className="form-label">Nombre del Producto <span className="text-danger">*</span></label>
                            <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} required />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Código de Barras <span className="text-danger">*</span></label>
                            <input type="text" className="form-control" value={barcode} onChange={(e) => setBarcode(e.target.value)} required />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Cantidad <span className="text-danger">*</span></label>
                            <input type="number" className="form-control" value={quantity} onChange={(e) => setQuantity(e.target.value)} required />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Precio de compra <span className="text-danger">*</span></label>
                            <NumericFormat
                                className="form-control"
                                value={purchasePrice}
                                thousandSeparator="."
                                decimalSeparator=","
                                prefix="$"
                                onValueChange={(values) => setPurchasePrice(values.value)}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Precio de venta <span className="text-danger">*</span></label>
                            <NumericFormat
                                className="form-control"
                                value={salePrice}
                                thousandSeparator="."
                                decimalSeparator=","
                                prefix="$"
                                onValueChange={(values) => setSalePrice(values.value)}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label>Colores (opcional)</label>
                            <div className="d-flex align-items-center mb-2">
                                <input
                                    type="color"
                                    value={currentColor}
                                    onChange={(e) => setCurrentColor(e.target.value)}
                                    className="form-control form-control-color"
                                />
                                <button type="button" className="btn btn-sm btn-primary ms-2" onClick={handleAddColor}>
                                    +
                                </button>
                            </div>
                            <div className="d-flex">
                                {colors.map((color, index) => (
                                    <div key={index} className="d-flex align-items-center me-3">
                                        <div
                                            style={{
                                                width: '20px',
                                                height: '20px',
                                                backgroundColor: color,
                                                border: '1px solid #000',
                                                marginRight: '10px'
                                            }}
                                        ></div>
                                        <span>{color}</span>
                                        <button
                                            type="button"
                                            className="btn btn-danger btn-sm ms-2"
                                            onClick={() => handleRemoveColor(color)}
                                        >
                                            x
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Tamaños (opcional)</label>
                            <Select
                                value={sizes}
                                onChange={(selectedSizes) => setSizes(selectedSizes)}
                                options={sizeOptions}
                                isMulti
                                placeholder="Seleccionar Tamaños"
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Unidad de Medida (opcional)</label>
                            <Select
                                value={unitOfMeasurement}
                                onChange={(selectedOption) => setUnitOfMeasurement(selectedOption)}
                                options={unitOptions}
                                placeholder="Seleccionar Unidad de Medida"
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Comercio</label>
                            <Select
                                value={commerces.find(c => c.value === commerceId)}
                                onChange={(selected) => setCommerceId(selected.value)}
                                options={commerces}
                                placeholder="Seleccionar Comercio"
                                className="basic-single"
                                classNamePrefix="select"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Puntos de Venta</label>
                            <Select
                                value={salesPointsOptions.filter(sp => salesPoints.includes(sp.value))}
                                onChange={(selectedOptions) => {
                                    // Si no hay opciones seleccionadas, asegúrate de enviar un array vacío
                                    const updatedSalesPoints = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                    setSalesPoints(updatedSalesPoints);
                                }}
                                options={salesPointsOptions}
                                placeholder="Seleccionar Puntos de Venta"
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable
                                isMulti
                            />
                        </div>
                        <div className="mb-3">
                            <label>Imagen del Producto (opcional)</label>
                            <div className="author-profile">
                                <div className="author-media">
                                    <img src={logoUrl || 'default-image-url.jpg'} alt="Imagen producto" />
                                    <div className="upload-link" title="Imagen producto" data-toggle="tooltip" data-placement="right" data-original-title="update">
                                        <input type="file" className="update-flie cursor-pointer" onChange={handleFileChange} />
                                        <i className="fa fa-camera"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {alertMessage && (
                            <Alert variant={alertVariant} className={`fade ${showAlert ? 'show' : ''}`}>
                                {alertMessage}
                            </Alert>
                        )}

                        <div className="mb-5 mt-3">
                            <button type="submit" className="btn btn-primary" disabled={loading}>
                                {loading ? 'Actualizando...' : 'Actualizar Producto'}
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary ms-2"
                                onClick={() => setEditProducto(false)}
                            >
                                Cancelar
                            </button>
                        </div>
                    </form>
                </div>
            </Offcanvas>
        </>
    );
});

export default ProductoEditCanvas;
