import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Offcanvas, Alert, Form, Button, Accordion } from 'react-bootstrap';
import { connectToDian, updateDianConfig, getDianStatus } from '../../services/ComerciosService'; // Importa el servicio para conectar y actualizar

const DianConnectCanvas = forwardRef((props, ref) => {
    const [showCanvas, setShowCanvas] = useState(false);
    const [commerceId, setCommerceId] = useState(null);
    const [dianSoftwareId, setDianSoftwareId] = useState('');
    const [dianSoftwarePin, setDianSoftwarePin] = useState('');
    const [testSetId, setTestSetId] = useState('');
    const [dianSenderId, setDianSenderId] = useState(''); 
    const [certificateFile, setCertificateFile] = useState(null);
    const [certificatePassword, setCertificatePassword] = useState('');
    const [isProduction, setIsProduction] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState('');
    const [dianStatus, setDianStatus] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false); // Nuevo estado para controlar el modo de edición

    useImperativeHandle(ref, () => ({
        showDianModal(id, type) {
            setCommerceId(id);
            resetForm(); // Resetear el formulario al cambiar de comercio
            fetchDianStatus(id);
            setShowCanvas(true);
        }
    }));

    const resetForm = () => {
        setDianSoftwareId('');
        setDianSoftwarePin('');
        setTestSetId('');
        setDianSenderId('');
        setCertificateFile(null);
        setCertificatePassword('');
        setIsProduction(false);
        setDianStatus(null);
        setIsEditMode(false); // Restablecer el modo de edición
        setAlertMessage('');
        setAlertVariant('');
    };

    const handleFileChange = (e) => {
        setCertificateFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!dianSoftwareId || !dianSoftwarePin || !certificateFile || !certificatePassword || !dianSenderId || !testSetId) {
            setAlertMessage('Todos los campos son obligatorios.');
            setAlertVariant('danger');
            return;
        }

        try {
            if (isEditMode) {
                // Actualizar la conexión con la DIAN
                const response = await updateDianConfig(commerceId, {
                    dianSoftwareId,
                    dianSoftwarePin,
                    dianSenderId,
                    certificateFile,
                    certificatePassword,
                    isProduction,
                    testSetId
                });
                setAlertMessage('Conexión con la DIAN actualizada correctamente.');
                setAlertVariant('success');
            } else {
                // Crear una nueva conexión con la DIAN
                const response = await connectToDian(commerceId, {
                    dianSoftwareId,
                    dianSoftwarePin,
                    dianSenderId,
                    certificateFile,
                    certificatePassword,
                    isProduction,
                    testSetId
                });
                setAlertMessage('Conexión con la DIAN configurada correctamente.');
                setAlertVariant('success');
            }
            fetchDianStatus(commerceId); // Refrescar el estado de DIAN después de la operación
        } catch (error) {
            setAlertMessage('Error en la conexión con la DIAN.');
            setAlertVariant('danger');
        }
    };

    const fetchDianStatus = async (id) => {
        try {
            const response = await getDianStatus(id);
            setDianStatus(response.data);
            console.log("response", response);    
            if (response.data) {
                // Si hay datos previos, rellenar los campos del formulario y activar modo edición
                setDianSoftwareId(response.data.dianSoftwareId || '');
                setDianSoftwarePin(response.data.dianSoftwarePin || '');
                setTestSetId(response.data.testSetId || '');
                setDianSenderId(response.data.dianSenderId || '');
                setIsProduction(response.data.isProduction || false);
                setIsEditMode(true); // Activar el modo de edición si ya hay configuración
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                // Si no se encuentra la configuración, asegurarse de que el formulario esté limpio
                resetForm();
            } else {
                setAlertMessage('Error al obtener la configuración de la DIAN.');
                setAlertVariant('danger');
            }
        }
    };

    return (
        <Offcanvas show={showCanvas} onHide={() => setShowCanvas(false)} className="offcanvas-end customeoff" placement='end'>
            <div className="offcanvas-header">
                <h5 className="modal-title">Configurar Conexión DIAN</h5>
                <button type="button" className="btn-close" onClick={() => setShowCanvas(false)}>
                    <i className="fa-solid fa-xmark"></i>
                </button>
            </div>
            <Offcanvas.Body>
                {dianStatus ? (
                    <div>
                        <hr className="mb-4 mt-0"></hr>
                        <h5 className="mb-4">Estado de Conexión DIAN</h5>
                        <p><strong>Software ID:</strong> {dianStatus.dianSoftwareId}</p>
                        <p><strong>Software PIN:</strong> {dianStatus.dianSoftwarePin}</p>
                        <p><strong>Test Set ID:</strong> {dianStatus.testSetId}</p>
                        <p><strong>Sender ID:</strong> {dianStatus.dianSenderId}</p>
                        <p><strong>Entorno de Producción:</strong> {dianStatus.isProduction ? 'Sí' : 'No'}</p>
                        <hr className="my-4"></hr>

                        <Accordion defaultActiveKey={isEditMode ? null : "1"}>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Editar Configuración DIAN</Accordion.Header>
                                <Accordion.Body>
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Software ID</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Ingresa el Software ID"
                                                value={dianSoftwareId}
                                                onChange={(e) => setDianSoftwareId(e.target.value)}
                                                required
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Software PIN</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Ingresa el Software PIN"
                                                value={dianSoftwarePin}
                                                onChange={(e) => setDianSoftwarePin(e.target.value)}
                                                required
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Test Set ID</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Ingresa el Test Set ID"
                                                value={testSetId}
                                                onChange={(e) => setTestSetId(e.target.value)}
                                                required
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Sender ID (NIT de la empresa)</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Ingresa el Sender ID"
                                                value={dianSenderId}
                                                onChange={(e) => setDianSenderId(e.target.value)}
                                                required
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Certificado DIAN (.p12)</Form.Label>
                                            <Form.Control type="file" onChange={handleFileChange} required />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Contraseña del Certificado</Form.Label>
                                            <Form.Control
                                                type="password"
                                                placeholder="Ingresa la contraseña"
                                                value={certificatePassword}
                                                onChange={(e) => setCertificatePassword(e.target.value)}
                                                required
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Check
                                                type="checkbox"
                                                label="Entorno de Producción"
                                                checked={isProduction}
                                                onChange={() => setIsProduction(!isProduction)}
                                            />
                                        </Form.Group>

                                        {alertMessage && (
                                            <Alert variant={alertVariant}>
                                                {alertMessage}
                                            </Alert>
                                        )}

                                        <Button variant="primary" type="submit">
                                            {isEditMode ? 'Editar Conexión' : 'Conectar a la DIAN'}
                                        </Button>
                                    </Form>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                ) : (
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Software ID</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Ingresa el Software ID"
                                value={dianSoftwareId}
                                onChange={(e) => setDianSoftwareId(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Software PIN</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Ingresa el Software PIN"
                                value={dianSoftwarePin}
                                onChange={(e) => setDianSoftwarePin(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Test Set ID</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Ingresa el Test Set ID"
                                value={testSetId}
                                onChange={(e) => setTestSetId(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Sender ID (NIT de la empresa)</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Ingresa el Sender ID"
                                value={dianSenderId}
                                onChange={(e) => setDianSenderId(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Certificado DIAN (.p12)</Form.Label>
                            <Form.Control type="file" onChange={handleFileChange} required />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Contraseña del Certificado</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Ingresa la contraseña"
                                value={certificatePassword}
                                onChange={(e) => setCertificatePassword(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Check
                                type="checkbox"
                                label="Entorno de Producción"
                                checked={isProduction}
                                onChange={() => setIsProduction(!isProduction)}
                            />
                        </Form.Group>

                        {alertMessage && (
                            <Alert variant={alertVariant}>
                                {alertMessage}
                            </Alert>
                        )}

                        <Button variant="primary" type="submit">
                            Conectar a la DIAN
                        </Button>
                    </Form>
                )}
            </Offcanvas.Body>
        </Offcanvas>
    );
});

export default DianConnectCanvas;
