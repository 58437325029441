import React from 'react';

export const GlobalFilter = ({ filter, setFilter }) => {
    return (
        <span>
            <input
                value={filter || ''}
                onChange={(e) => setFilter(e.target.value)}
                placeholder="Escribe para buscar..."
                className="form-control w-50"  // Clases de Bootstrap para estilo
                style={{
                    width: '300px',  // Para mantener el ancho específico
                }}
            />
        </span>
    );
};

export default GlobalFilter;
