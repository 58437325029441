import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Offcanvas, Alert } from 'react-bootstrap';
import { updateCommerce, getCommerceById } from '../../services/ComerciosService'; // Servicios de edición y obtención de comercio
import SelectCategory from './SelectCategory'; // Importar el componente de selección de categoría

const categoryOptions = [
    { value: 'papeleria', label: 'Papelería' },
    { value: 'restaurante', label: 'Restaurante' },
    { value: 'panaderia', label: 'Panadería' },
    { value: 'supermercado', label: 'Supermercado' },
    { value: 'ferreteria', label: 'Ferretería' },
    { value: 'farmacia', label: 'Farmacia' },
    { value: 'boutique', label: 'Boutique' },
    { value: 'tienda-de-ropa', label: 'Tienda de Ropa' },
    { value: 'tienda-de-comestibles', label: 'Tienda de Comestibles' },
    { value: 'cafe', label: 'Café' },
    { value: 'bar', label: 'Bar' },
    { value: 'salon-de-belleza', label: 'Salón de Belleza' },
    { value: 'gimnasio', label: 'Gimnasio' },
    { value: 'lavanderia', label: 'Lavandería' },
    { value: 'negocio', label: 'Negocio de barrio' },
];

const ComerciosEditCanvas = forwardRef((props, ref) => {
    const [commerceId, setCommerceId] = useState(null);
    const [name, setName] = useState('');
    const [nit, setNit] = useState('');
    const [description, setDescription] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [logo, setLogo] = useState(null);
    const [logoUrl, setLogoUrl] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(null); // Estado para la categoría
    const [editCommerce, setEditCommerce] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    useImperativeHandle(ref, () => ({
        showEditCommerceModal(id) {
            resetFormFields();
            setCommerceId(id);
            fetchCommerceDetails(id);
            setEditCommerce(true);
        }    
    }));

    const resetFormFields = () => {
        setName('');
        setNit('');
        setDescription('');
        setAddress('');
        setPhone('');
        setLogo(null);
        setLogoUrl('');
        setSelectedCategory(null); // Resetear la categoría seleccionada
    };

    const fetchCommerceDetails = async (id) => {
        try {
            const response = await getCommerceById(id);
            const commerce = response.data;
            setName(commerce.name || '');
            setNit(commerce.nit || '');
            setDescription(commerce.description || '');
            setAddress(commerce.address || '');
            setPhone(commerce.phone || '');
            setLogoUrl(commerce.logoUrl || '');

            // Encontrar la opción de categoría correspondiente en categoryOptions
            const categoryOption = categoryOptions.find(option => option.value === commerce.category);
            setSelectedCategory(categoryOption || null); // Si no hay coincidencia, selecciona null

        } catch (error) {
            console.error('Error al obtener los detalles del comercio:', error);
            setAlertMessage('Hubo un error al cargar los detalles del comercio.');
            setAlertVariant('danger');
            setShowAlert(true);
        }
    };

    const handleFileChange = (e) => {
        setLogo(e.target.files[0]);
        setLogoUrl(URL.createObjectURL(e.target.files[0]));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append('name', name);
        formData.append('nit', nit);
        formData.append('description', description);
        formData.append('address', address);
        formData.append('phone', phone);
        if (logo) {
            formData.append('logo', logo);
        }

        if (selectedCategory) {
            formData.append('category', selectedCategory.value); // Agregar la categoría al FormData
        }

        try {
            const response = await updateCommerce(commerceId, formData);
            console.log('Comercio actualizado:', response.data);
            setAlertMessage('Comercio actualizado exitosamente');
            setAlertVariant('success');
            props.onCommerceUpdated(response.data);

            setTimeout(() => {
                setEditCommerce(false);
                resetFormFields();
            }, 1000);
        } catch (error) {
            console.error('Error actualizando el comercio:', error);
            setAlertMessage('Hubo un error actualizando el comercio. Intente nuevamente.');
            setAlertVariant('danger');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (alertMessage) {
            setShowAlert(true);
            const timeout = setTimeout(() => {
                setShowAlert(false);
                setTimeout(() => setAlertMessage(''), 300);
            }, 3000);
            return () => clearTimeout(timeout);
        }
    }, [alertMessage]);

    return (
        <>
            <Offcanvas show={editCommerce} onHide={() => setEditCommerce(false)} className="offcanvas-end customeoff" placement='end'>
                <div className="offcanvas-header">
                    <h5>Editar Comercio</h5>
                    <button type="button" className="btn-close" onClick={() => setEditCommerce(false)}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div className="offcanvas-body">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className="form-label">Nombre del Comercio <span className="text-danger">*</span></label>
                            <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} required />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Nit <span className="text-danger">*</span></label>
                            <input type="text" className="form-control" value={nit} onChange={(e) => setNit(e.target.value)} required />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Descripción</label>
                            <textarea className="form-control" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Dirección</label>
                            <input type="text" className="form-control" value={address} onChange={(e) => setAddress(e.target.value)} required />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Teléfono</label>
                            <input type="text" className="form-control" value={phone} onChange={(e) => setPhone(e.target.value)} required />
                        </div>

                        {/* Agregar el componente de selección de categoría */}
                        <SelectCategory selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />

                        <div className="mb-3">
                            <label>Logo del Comercio (opcional)</label>
                            <div className="author-profile">
                                <div className="author-media">
                                    <img src={logoUrl || 'default-image-url.jpg'} alt="Logo Comercio" />
                                    <div className="upload-link" title="Actualizar logo" data-toggle="tooltip" data-placement="right" data-original-title="update">
                                        <input type="file" className="update-flie cursor-pointer" onChange={handleFileChange} />
                                        <i className="fa fa-camera"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {alertMessage && (
                            <Alert variant={alertVariant} className={`fade ${showAlert ? 'show' : ''}`}>
                                {alertMessage}
                            </Alert>
                        )}

                        <div className="mb-5 mt-3">
                            <button type="submit" className="btn btn-primary" disabled={loading}>
                                {loading ? 'Actualizando...' : 'Actualizar Comercio'}
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary ms-2"
                                onClick={() => setEditCommerce(false)}
                            >
                                Cancelar
                            </button>
                        </div>
                    </form>
                </div>
            </Offcanvas>
        </>
    );
});

export default ComerciosEditCanvas;
