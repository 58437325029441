import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Offcanvas, Alert, Form } from 'react-bootstrap';
import { updateUserAndAssignments, getUserAssignmentById } from '../../services/UsuariosComercioService';
import { getComercios } from '../../services/ComerciosService';
import { getSalesPoints } from '../../services/PuntosVentaService';

const UsuariosComercioEditCanvas = forwardRef((props, ref) => {
    const [userId, setUserId] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [role, setRole] = useState('');
    const [commerceId, setCommerceId] = useState('');
    const [salesPointId, setSalesPointId] = useState('');
    const [commerces, setCommerces] = useState([]);
    const [salesPoints, setSalesPoints] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [showCanvas, setShowCanvas] = useState(false);
    const currentUserId = JSON.parse(localStorage.getItem('userDetails')).userData.id;

    useImperativeHandle(ref, () => ({
        showEditAssignmentModal(existingUserId) { 
            if (existingUserId) {
                fetchUserDetails(existingUserId);
                setShowCanvas(true);
            }
        }
    }));

    const fetchUserDetails = async (userId) => {
        try {
            const response = await getUserAssignmentById(userId);
            const { user, commerce, salesPoints } = response.data[0];
            console.log("response", user);
            setUserId(user.id);
            setName(user.name);
            setEmail(user.email);
            setPhone(user.phone || '');
            setRole(user.role.role);
            setCommerceId(commerce.id);
            setSalesPointId(salesPoints[0]?.id || '');
            loadCommerces();
            loadSalesPoints(commerce.id);
        } catch (error) {
            console.error('Error al cargar los detalles del usuario:', error);
        }
    };

    const resetFormFields = () => {
        setUserId(null);
        setName('');
        setEmail('');
        setPhone('');
        setRole('');
        setCommerceId('');
        setSalesPointId('');
    };

    const loadCommerces = async () => {
        try {
            const response = await getComercios(currentUserId);
            const activeCommerces = response.data
                .filter(commerce => commerce.licenses.some(license => license.isActive && new Date(license.expirationDate) > new Date()))
                .map(commerce => ({ value: commerce.id, label: commerce.name }));
            setCommerces(activeCommerces);
        } catch (error) {
            console.error('Error al cargar los comercios:', error);
        }
    };

    const loadSalesPoints = async (commerceId) => {
        try {
            const response = await getSalesPoints(commerceId);
            setSalesPoints(response.data.map(salesPoint => ({
                value: salesPoint.id,
                label: salesPoint.name,
            })));
        } catch (error) {
            console.error('Error al cargar los puntos de venta:', error);
        }
    };

    useEffect(() => {
        if (commerceId) {
            loadSalesPoints(commerceId);
        }
    }, [commerceId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = {
            name,
            email,
            phone,
            role,
            commerceId,
            salesPointId,
            userId,  // Incluir userId en los datos enviados
        };

        try {
            console.log("Data a enviar", userId);
            const response = await updateUserAndAssignments(userId, formData);
            setAlertMessage('Usuario y asignación actualizados exitosamente');
            setAlertVariant('success');
            props.onUserAssignmentUpdated(response.data);
            setTimeout(() => setShowCanvas(false), 1000);
        } catch (error) {
            setAlertMessage('Error al procesar la asignación. Intente nuevamente.');
            setAlertVariant('danger');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Offcanvas show={showCanvas} onHide={() => setShowCanvas(false)} className="offcanvas-end customeoff" placement="end">
            <div className="offcanvas-header">
                <h5>Editar Usuario y Asignación</h5>
                <button type="button" className="btn-close" onClick={() => setShowCanvas(false)}>
                    <i className="fa-solid fa-xmark"></i>
                </button>
            </div>
            <div className="offcanvas-body">
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label className="form-label">Nombre</label>
                        <input
                            type="text"
                            className="form-control"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Correo Electrónico</label>
                        <input
                            type="email"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Teléfono</label>
                        <input
                            type="text"
                            className="form-control"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Rol</label>
                        <Form.Select 
                            value={role} 
                            onChange={(e) => setRole(e.target.value)} 
                            required 
                            className="basic-single select2-selection__rendered"
                        >
                            <option value="">Seleccionar Rol</option>
                            <option value="usuario-comercio">usuario-comercio</option>
                        </Form.Select>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Comercio</label>
                        <Form.Select 
                            value={commerceId} 
                            onChange={(e) => setCommerceId(e.target.value)} 
                            required 
                            className="basic-single select2-selection__rendered"
                        >
                            <option value="">Seleccionar Comercio</option>
                            {commerces.map(commerce => (
                                <option key={commerce.value} value={commerce.value}>{commerce.label}</option>
                            ))}
                        </Form.Select>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Punto de Venta</label>
                        <Form.Select 
                            value={salesPointId} 
                            onChange={(e) => setSalesPointId(e.target.value)} 
                            required 
                            className="basic-single select2-selection__rendered"
                        >
                            <option value="">Seleccionar Punto de Venta</option>
                            {salesPoints.map(point => (
                                <option key={point.value} value={point.value}>{point.label}</option>
                            ))}
                        </Form.Select>
                    </div>
                    {alertMessage && (
                        <Alert variant={alertVariant} className={`fade ${showAlert ? 'show' : ''}`}>
                            {alertMessage}
                        </Alert>
                    )}
                    <div className="mb-3 mt-5">
                        <button type="submit" className="btn btn-primary" disabled={loading}>
                            {loading ? 'Actualizando...' : 'Actualizar Asignación'}
                        </button>
                        <button type="button" className="btn btn-secondary ms-2" onClick={() => setShowCanvas(false)}>
                            Cancelar
                        </button>
                    </div>
                </form>
            </div>
        </Offcanvas>
    );
});

export default UsuariosComercioEditCanvas;
