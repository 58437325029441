import React from 'react';

const VentaDetalleModal = ({ venta }) => {
    if (!venta) return null; // Verificar si la venta existe antes de renderizar

    const calcularComision = (item) => {
        if (item.ItemType === 'Service' && item.commisionPercetage && item.Employee) {
            const comision = (item.unitValue * item.commisionPercetage) / 100;
            return `${item.Employee.label} - ${formatearMoneda(comision)}`; // Mostrar el nombre del empleado y la comisión
        }
        return '-';
    };

    return (
        <div>
            <h5>Información General</h5>
            <div className="totales">
                <p className="totales-item">
                    <strong>Fecha</strong> <span className="totales-dotted-line"></span> {new Date(venta.date).toLocaleString()}
                </p>
                <p className="totales-item">
                    <strong>Comercio</strong> <span className="totales-dotted-line"></span> {venta.commerceDetails.name}
                </p>
                <p className="totales-item">
                    <strong>Punto de Venta</strong> <span className="totales-dotted-line"></span> {venta.salesPointDetails.name}
                </p>
                <p className="totales-item">
                    <strong>Cliente</strong> <span className="totales-dotted-line"></span> {venta.customerDetails.nombre}
                </p>
                <p className="totales-item">
                    <strong>Documento del Cliente</strong> <span className="totales-dotted-line"></span> {venta.customerDetails.documento} ({venta.customerDetails.tipoDocumento.label})
                </p>
                <p className="totales-item text-capitalize">
                    <strong>Tipo de Pago</strong> <span className="totales-dotted-line"></span> {venta.paymentType}
                </p>
                <p className="totales-item">
                    <strong>Total</strong> <span className="totales-dotted-line"></span> <span className='text-success'>{formatearMoneda(venta.total)}</span>
                </p>
            </div>

            <h5 className='mt-5'>Detalles del Vendedor</h5>
            <div className="totales">
                <p className="totales-item">
                    <strong>Vendedor</strong> <span className="totales-dotted-line"></span> {venta.employeeDetails.name}
                </p>
                <p className="totales-item">
                    <strong>Email del Vendedor</strong> <span className="totales-dotted-line"></span> {venta.employeeDetails.email}
                </p>
                <p className="totales-item">
                    <strong>Teléfono del Vendedor</strong> <span className="totales-dotted-line"></span> {venta.employeeDetails.phone}
                </p>
            </div>

            <h5 className='mt-5'>Items Vendidos</h5>
            <div className="table-responsive"> {/* Agregar la clase table-responsive aquí */}
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Item</th>
                            <th>Cantidad</th>
                            <th>Precio Unitario</th>
                            <th>Descuento</th>
                            <th>Impuesto</th>
                            <th>Comisión (si aplica)</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {venta.dataItems.map((item, index) => (
                            <tr key={index}>
                                <td className='text-capitalize'>{item.name}</td>
                                <td>{item.quantity}</td>
                                <td>{formatearMoneda(item.unitValue)}</td>
                                <td>{formatearMoneda(item.disccount || 0)}</td>
                                <td>{item.tax.name}</td>
                                <td className='text-success'>{calcularComision(item)}</td> {/* Mostrar comisión si es servicio */}
                                <td>{formatearMoneda(item.Total)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <h5 className='mt-5'>Totales</h5>
            <div className="totales">
                <p className="totales-item">
                    Subtotal <span className="totales-dotted-line"></span> <strong>{formatearMoneda(venta.subtotal)}</strong>
                </p>
                <p className="totales-item">
                    Impuestos <span className="totales-dotted-line"></span> <strong>{formatearMoneda(venta.taxes)}</strong>
                </p>
                <p className="totales-item">
                    Total <span className="totales-dotted-line"></span> <strong>{formatearMoneda(venta.total)}</strong>
                </p>
            </div>

            <h5 className='mt-5'>Documentos</h5>
            <div className="totales">
                {venta.invoiceUrl && (
                    <p className="totales-item">
                        Factura <span className="totales-dotted-line"></span> <a href={venta.invoiceUrl} target="_blank" rel="noopener noreferrer">Ver Factura</a>
                    </p>
                )}
            </div>
        </div>
    );
};

export default VentaDetalleModal;

// Función para formatear los valores como moneda
const formatearMoneda = (valor) => {
    if (valor === null || valor === undefined) return '-';
    return Number(valor).toLocaleString('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
};
