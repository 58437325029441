import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Offcanvas, Alert } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
import { updateService, getServiceById } from '../../services/ServiciosService';
import Select from 'react-select';
import { getComercios } from '../../services/ComerciosService';
import { getSalesPoints } from '../../services/PuntosVentaService';

const ServicioEditCanvas = forwardRef((props, ref) => {
    const [serviceId, setServiceId] = useState(null);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [basePrice, setBasePrice] = useState('');
    const [salePrice, setSalePrice] = useState('');
    const [commissionPercentage, setCommissionPercentage] = useState('');
    const [commerceId, setCommerceId] = useState(null);
    const [salesPoints, setSalesPoints] = useState([]);
    const [commerces, setCommerces] = useState([]);
    const [salesPointsOptions, setSalesPointsOptions] = useState([]);
    const [editServicio, setEditServicio] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [errors, setErrors] = useState({});

    useImperativeHandle(ref, () => ({
        showEditServicioModal(id) {
            resetFormFields();
            setServiceId(id);
            fetchServiceDetails(id);
            loadCommerces();
            setEditServicio(true);
            setAlertMessage('');
            setShowAlert(false);
        }
    }));

    const resetFormFields = () => {
        setName('');
        setDescription('');
        setBasePrice('');
        setSalePrice('');
        setCommissionPercentage('');
        setCommerceId(null);
        setSalesPoints([]);
        setErrors({});
    };

    const fetchServiceDetails = async (id) => {
        try {
            const response = await getServiceById(id);
            const service = response.data;
            setName(service.name || '');
            setDescription(service.description || '');
            setBasePrice(service.basePrice?.toString() || '');
            setSalePrice(service.salePrice?.toString() || '');
            setCommissionPercentage(service.commissionPercentage?.toString() || '');
            setCommerceId(service.commerce?.id || null);
            setSalesPoints(service.salesPoints.map(sp => sp.id) || []);
        } catch (error) {
            setAlertMessage('Hubo un error al cargar los detalles del servicio.');
            setAlertVariant('danger');
            setShowAlert(true);
        }
    };

    const loadCommerces = async () => {
        try {
            const userDetails = JSON.parse(localStorage.getItem('userDetails'));
            const adminId = userDetails?.userData?.id || null;
            const response = await getComercios(adminId);
            const activeCommerces = response.data.map(commerce => ({
                value: commerce.id,
                label: commerce.name,
            }));
            setCommerces(activeCommerces);
        } catch (error) {
            console.error('Error al cargar los comercios:', error);
        }
    };

    const loadSalesPoints = async (commerceId) => {
        try {
            const response = await getSalesPoints(commerceId);
            setSalesPointsOptions(response.data.map(salesPoint => ({
                value: salesPoint.id,
                label: salesPoint.name,
            })));
        } catch (error) {
            console.error('Error al cargar los puntos de venta:', error);
        }
    };

    useEffect(() => {
        if (commerceId) {
            loadSalesPoints(commerceId);
        }
    }, [commerceId]);

    const validateForm = () => {
        const newErrors = {};
        if (!name.trim()) newErrors.name = 'El nombre es obligatorio.';
        if (!basePrice) newErrors.basePrice = 'El precio base es obligatorio.';
        if (!salePrice) newErrors.salePrice = 'El precio de venta es obligatorio.';
        if (!commissionPercentage) newErrors.commissionPercentage = 'El porcentaje de comisión es obligatorio.';
        if (!commerceId) newErrors.commerceId = 'Debe seleccionar un comercio.';
        if (salesPoints.length === 0) newErrors.salesPoints = 'Debe seleccionar al menos un punto de venta.';
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const formErrors = validateForm();
        setErrors(formErrors);

        if (Object.keys(formErrors).length === 0) {
            try {
                const formData = new FormData();
                formData.append('name', name.toLowerCase().trim());
                formData.append('description', description || '');
                formData.append('basePrice', parseFloat(basePrice.replace(/[^0-9.-]+/g, "")));
                formData.append('salePrice', parseFloat(basePrice.replace(/[^0-9.-]+/g, "")));
                formData.append('commissionPercentage', parseFloat(commissionPercentage.replace(/[^0-9.-]+/g, "")));
                formData.append('commerceId', commerceId);
                // Asegurarte de que salesPointIds sea siempre un array
                if (salesPoints.length > 0) {
                    salesPoints.forEach(salesPoint => formData.append('salesPointIds[]', salesPoint)); // Agrega los salesPointIds como array
                } else {
                    formData.append('salesPointIds[]', []); // Envía un array vacío si no hay puntos de venta seleccionados
                }

                const response = await updateService(serviceId, formData);

                setAlertMessage('Servicio actualizado exitosamente');
                setAlertVariant('success');
                setShowAlert(true);
                props.onServicioUpdated(response.data);

                setTimeout(() => {
                    setEditServicio(false);
                    resetFormFields();
                }, 1000);
            } catch (error) {
                setAlertMessage('Hubo un error actualizando el servicio. Intente nuevamente.');
                setAlertVariant('danger');
                setShowAlert(true);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <>
            <Offcanvas show={editServicio} onHide={() => setEditServicio(false)} className="offcanvas-end customeoff" placement='end'>
                <div className="offcanvas-header">
                    <h5>Editar Servicio</h5>
                    <button type="button" className="btn-close" onClick={() => setEditServicio(false)}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div className="offcanvas-body">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className="form-label">Nombre del Servicio <span className="text-danger">*</span></label>
                            <input
                                type="text"
                                className={`form-control ${errors.name ? 'border-danger' : ''}`}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                            {errors.name && <div className="text-danger">{errors.name}</div>}
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Descripción</label>
                            <textarea
                                className="form-control"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Precio Base <span className="text-danger">*</span></label>
                            <NumericFormat
                                className={`form-control ${errors.basePrice ? 'border-danger' : ''}`}
                                value={basePrice}
                                thousandSeparator="."
                                decimalSeparator=","
                                prefix="$"
                                onValueChange={(values) => setBasePrice(values.value)}
                                required
                            />
                            {errors.basePrice && <div className="text-danger">{errors.basePrice}</div>}
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Precio Venta <span className="text-danger">*</span></label>
                            <NumericFormat
                                className={`form-control ${errors.salePrice ? 'border-danger' : ''}`}
                                value={salePrice}
                                thousandSeparator="."
                                decimalSeparator=","
                                prefix="$"
                                onValueChange={(values) => setSalePrice(values.value)}
                                required
                            />
                            {errors.basePrice && <div className="text-danger">{errors.basePrice}</div>}
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Porcentaje de Comisión <span className="text-danger">*</span></label>
                            <NumericFormat
                                className={`form-control ${errors.commissionPercentage ? 'border-danger' : ''}`}
                                value={commissionPercentage}
                                thousandSeparator="."
                                decimalSeparator=","
                                suffix="%"
                                onValueChange={(values) => setCommissionPercentage(values.value)}
                                required
                            />
                            {errors.commissionPercentage && <div className="text-danger">{errors.commissionPercentage}</div>}
                        </div>
                        <div className="mb-3">
                            <label>Comercio</label>
                            <Select
                                value={commerces.find(c => c.value === commerceId)}
                                onChange={(selected) => setCommerceId(selected?.value || null)}
                                options={commerces}
                                placeholder="Seleccionar Comercio"
                                className={`basic-single ${errors.commerceId ? 'border-danger' : ''}`}
                                classNamePrefix="select"
                                isClearable
                            />
                            {errors.commerceId && <div className="text-danger">{errors.commerceId}</div>}
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Puntos de Venta</label>
                            <Select
                                value={salesPointsOptions.filter(sp => salesPoints.includes(sp.value))}
                                onChange={(selectedOptions) => {
                                    // Si no hay opciones seleccionadas, asegúrate de enviar un array vacío
                                    const updatedSalesPoints = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                    setSalesPoints(updatedSalesPoints);
                                }}
                                options={salesPointsOptions}
                                placeholder="Seleccionar Puntos de Venta"
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable
                                isMulti
                            />
                        </div>
                        {alertMessage && (
                            <Alert variant={alertVariant}>
                                {alertMessage}
                            </Alert>
                        )}

                        <div className="mb-5 mt-3">
                            <button type="submit" className="btn btn-primary" disabled={loading}>
                                {loading ? 'Actualizando...' : 'Actualizar Servicio'}
                            </button>
                            <button type="button" className="btn btn-secondary ms-2" onClick={() => setEditServicio(false)}>
                                Cancelar
                            </button>
                        </div>
                    </form>
                </div>
            </Offcanvas>
        </>
    );
});

export default ServicioEditCanvas;
