import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Offcanvas, Alert, Form } from 'react-bootstrap';
import { createSalesPoint } from '../../services/PuntosVentaService'; // Servicio de creación de puntos de venta
import { getComercios } from '../../services/ComerciosService'; // Servicio para obtener comercios con licencia activa

const PuntosVentaCanvas = forwardRef((props, ref) => {
    const [name, setName] = useState('');
    const [location, setLocation] = useState('');
    const [selectedCommerceId, setSelectedCommerceId] = useState('');
    const [commerces, setCommerces] = useState([]); // Estado para almacenar comercios con licencia activa
    const [addSalesPoint, setAddSalesPoint] = useState(false);
    const [loading, setLoading] = useState(false); // Estado para el botón de carga
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState(''); // Variantes como success, danger
    const [showAlert, setShowAlert] = useState(false); // Estado para controlar el fade-out de la alerta
    const userId = JSON.parse(localStorage.getItem('userDetails')).userData.id;

    // Hacemos disponible la función showSalesPointModal a través de useImperativeHandle
    useImperativeHandle(ref, () => ({
        showSalesPointModal() {
            setAddSalesPoint(true);
        }    
    }));

    // Cargar comercios con licencia activa al montar el componente
    useEffect(() => {
        const loadCommerces = async () => {
            try {
                const response = await getComercios(userId); // Obtener comercios
                // Filtrar comercios que tienen al menos una licencia activa con vigencia
                const activeLicensedCommerces = response.data.filter(commerce =>
                    commerce.licenses.some(license => license.isActive && new Date(license.expirationDate) > new Date())
                );
                setCommerces(activeLicensedCommerces || []);
            } catch (error) {
                console.error('Error al cargar los comercios:', error);
                setCommerces([]);
            }
        };

        loadCommerces();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
    
        const formData = {
            name: String(name),  // Asegúrate de que sea una cadena
            location: String(location),  // Asegúrate de que sea una cadena, si está presente
            commerceId: String(selectedCommerceId),  // Asegúrate de que sea una cadena
        };
        
    
        try {
            const response = await createSalesPoint(formData); // Llamamos al servicio para crear el punto de venta
            console.log('Punto de venta creado:', response.data);
            setAlertMessage('Punto de venta creado exitosamente');
            setAlertVariant('success');
            
            props.onSalesPointCreated(response.data);
    
            setTimeout(() => {
                setAddSalesPoint(false); // Cerrar el modal
            }, 1000); // Dar un pequeño tiempo antes de cerrar
        } catch (error) {
            console.error('Error creando el punto de venta:', error);
            setAlertMessage('Hubo un error creando el punto de venta. Intente nuevamente.');
            setAlertVariant('danger');
        } finally {
            setLoading(false);
        }
    };    

    // Efecto para ocultar la alerta automáticamente después de 3 segundos con transición suave
    useEffect(() => {
        if (alertMessage) {
            setShowAlert(true); // Mostrar alerta con la clase 'show'

            const timeout = setTimeout(() => {
                setShowAlert(false); // Desaparecer alerta suavemente
                setTimeout(() => setAlertMessage(''), 300); // Esperar el fade-out antes de borrar el mensaje
            }, 3000); // 3 segundos

            // Limpiar el timeout si el componente se desmonta antes de los 3 segundos
            return () => clearTimeout(timeout);
        }
    }, [alertMessage]);

    return (
        <>
            <Offcanvas show={addSalesPoint} onHide={() => setAddSalesPoint(false)} className="offcanvas-end customeoff" placement='end'>
                <div className="offcanvas-header">
                    <h5 className="modal-title">{props.Title}</h5>
                    <button type="button" className="btn-close" onClick={() => setAddSalesPoint(false)}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div className="offcanvas-body">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className="form-label">Nombre del Punto de Venta <span className="text-danger">*</span></label>
                            <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} required />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Ubicación</label>
                            <input type="text" className="form-control" value={location} onChange={(e) => setLocation(e.target.value)} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Seleccionar Comercio <span className="text-danger">*</span></label>
                            <Form.Select
                                value={selectedCommerceId}
                                onChange={(e) => setSelectedCommerceId(e.target.value)}
                                required
                            >
                                <option value="">Seleccionar un comercio</option>
                                {commerces.map((commerce) => (
                                    <option key={commerce.id} value={commerce.id}>
                                        {commerce.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>

                        {/* Mostrar alerta dentro del formulario, justo encima del botón */}
                        {alertMessage && (
                            <Alert variant={alertVariant} className={`fade ${showAlert ? 'show' : ''}`}>
                                {alertMessage}
                            </Alert>
                        )}

                        {/* Botón de crear punto de venta */}
                        <div className="mb-5 mt-3">
                            <button type="submit" className="btn btn-primary" disabled={loading}>
                                {loading ? 'Creando...' : 'Crear Punto de Venta'}
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary ms-2"
                                onClick={() => setAddSalesPoint(false)}
                            >
                                Cancelar
                            </button>
                        </div>
                    </form>
                </div>
            </Offcanvas>
        </>
    );
});

export default PuntosVentaCanvas;
