import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { deleteService } from '../../../../services/ServiciosService';

const ListTab = ({ servicios, openEditModal, onServicioDeleted }) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [servicioToDelete, setServicioToDelete] = useState(null);
    const [showResultModal, setShowResultModal] = useState(false);
    const [resultMessage, setResultMessage] = useState('');
    const [resultVariant, setResultVariant] = useState('success');

    const handleDeleteClick = (servicioId) => {
        setServicioToDelete(servicioId);
        setShowDeleteModal(true);
    };

    const confirmDeleteServicio = async () => {
        try {
            await deleteService(servicioToDelete);
            setResultMessage('Servicio eliminado exitosamente.');
            setResultVariant('success');
            onServicioDeleted();
        } catch (error) {
            setResultMessage('Error al eliminar el servicio. Intente nuevamente.');
            setResultVariant('danger');
            console.error('Error al eliminar el servicio:', error);
        } finally {
            setShowDeleteModal(false);
            setShowResultModal(true);
        }
    };

    return (
        <>
            <div className="card">
                <div className="card-body p-0">
                    <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                        <div className="tbl-caption d-flex justify-content-between align-items-center">
                            <h4 className="heading mb-0">Mis Servicios</h4>
                        </div>
                        <table className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0">
                            <thead>
                                <tr>
                                    <th>Servicio</th>
                                    <th>Precio Base</th>
                                    <th>Precio Venta</th>
                                    <th>Comisión</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {servicios.map((servicio) => (
                                    <tr key={servicio.id}>
                                        <td className="text-capitalize">{servicio.name}</td>
                                        <td className='text-end'>{formatearMoneda(servicio.basePrice) || "-"}</td>
                                        <td className='text-end'>{formatearMoneda(servicio.salePrice) || "-"}</td>
                                        <td>{servicio.commissionPercentage || "-"}%</td>
                                        <td>
                                            <button className="btn btn-info btn-sm me-2" onClick={() => openEditModal(servicio.id)}>Editar</button>
                                            <button className="btn btn-danger btn-sm" onClick={() => handleDeleteClick(servicio.id)}>Eliminar</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* Modal de confirmación de eliminación */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar eliminación</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Estás seguro de que deseas eliminar este servicio? Esta acción no se puede deshacer.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancelar</Button>
                    <Button variant="danger" onClick={confirmDeleteServicio}>Eliminar</Button>
                </Modal.Footer>
            </Modal>

            {/* Modal de resultado de la eliminación */}
            <Modal show={showResultModal} onHide={() => setShowResultModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{resultVariant === 'success' ? 'Éxito' : 'Error'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{resultMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowResultModal(false)}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

const formatearMoneda = (valor) => {
    if (valor === null || valor === undefined) return '-';
    return Number(valor).toLocaleString('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
  };
  
export default ListTab;
