import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Tab, Nav } from 'react-bootstrap';
import { SVGICON } from '../../constant/theme';
import GridTab from '../AppsMenu/Comercios/GridTab';
import ComercioCanvas from '../../constant/ComercioCanvas';
import DocumentCanvas from '../../constant/DocumentCanvas';
import DianConnectCanvas from '../../constant/DianConnectCanvas';
import ComercioEditCanvas from '../../constant/ComercioEditCanvas';
import ListTab from '../AppsMenu/Comercios/ListTab';
import { getComercios } from '../../../services/ComerciosService';

const ComerciosAdmin = () => {
    const userdata = useRef();
    const documentCanvasRef = useRef();
    const dianConnectCanvasRef = useRef();
    const editCommerceRef = useRef();
    const [comercios, setComercios] = useState([]);
    const userId = JSON.parse(localStorage.getItem('userDetails')).userData.id;

    useEffect(() => {
        loadComercios(); // Cargar los comercios al montar el componente
    }, [userId]);

    const loadComercios = () => {
        getComercios(userId).then(response => {
            setComercios(response.data);
            console.log("DATA COMERCIOS", response.data);
        }).catch(error => {
            console.log('Error al obtener los comercios', error);
        });
    };

    const handleCommerceCreated = () => {
        loadComercios(); // Recargar los comercios después de la creación
    };

    const handleCommerceUpdated = () => {
        loadComercios(); // Recargar los comercios después de una edición
    };

    const handleCommerceDeleted = () => {
        loadComercios(); // Recargar los comercios después de una eliminación
    };

    const openDocumentModal = (id, type, documents) => {
        documentCanvasRef.current.showDocumentModal(id, type, documents);
    };

    const openDianModal = (id, type) => {
        dianConnectCanvasRef.current.showDianModal(id, type);
    };

    const openEditModal = (id) => {
        editCommerceRef.current.showEditCommerceModal(id);
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <Tab.Container defaultActiveKey={'Grid'}>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h4 className="heading mb-0">Comercios</h4>
                            <div className="d-flex align-items-center">
                                <Nav as="ul" className="nav nav-pills mix-chart-tab user-m-tabe" id="pills-tab">
                                    <Nav.Item as="li" className="nav-item" role="presentation">
                                        <Nav.Link as="button" className="nav-link" eventKey={'List'}>
                                            {SVGICON.List}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as="li" className="nav-item">
                                        <Nav.Link as="button" className="nav-link" eventKey={'Grid'}>
                                            {SVGICON.GridDots}
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Link to className="btn btn-primary btn-sm ms-2"
                                    onClick={() => userdata.current.showEmployeModal()}
                                >+ Agregar Comercio
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-12 active-p">
                            <Tab.Content>
                                <Tab.Pane eventKey={'Grid'}>
                                    <GridTab
                                        comercios={comercios}
                                        tableName={"Mis Comercios"}
                                        openDocumentModal={openDocumentModal}
                                        openDianModal={openDianModal}
                                        openEditModal={openEditModal}
                                        onCommerceDeleted={handleCommerceDeleted} 
                                    />
                                </Tab.Pane>
                                <Tab.Pane eventKey={'List'}>
                                    <ListTab
                                        comercios={comercios}
                                        tableName={"Mis Comercios"}
                                        openDocumentModal={openDocumentModal}
                                        openDianModal={openDianModal}
                                        openEditModal={openEditModal}
                                        onCommerceDeleted={handleCommerceDeleted} // Pasar la función para manejar la eliminación
                                    />
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </Tab.Container>
                </div>
            </div>
            <ComercioCanvas
                ref={userdata}
                Title="Agregar Comercio"
                formType="comercio"
                onCommerceCreated={handleCommerceCreated}
            />
            <DocumentCanvas ref={documentCanvasRef} />
            <DianConnectCanvas ref={dianConnectCanvasRef} />
            <ComercioEditCanvas
                ref={editCommerceRef}
                onCommerceUpdated={handleCommerceUpdated}
            />
        </>
    );
};

export default ComerciosAdmin;
