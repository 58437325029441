import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Tab, Nav } from 'react-bootstrap';
import Select from 'react-select';
import PuntosDeVentaGridTab from '../AppsMenu/PuntosVenta/GridTab';
import PuntosVentaCanvas from '../../constant/PuntosVentaCanvas';
import PuntosVentaEditCanvas from '../../constant/PuntosVentaEditCanvas';
import PuntosDeVentaListTab from '../AppsMenu/PuntosVenta/ListTab';
import { getSalesPoints } from '../../../services/PuntosVentaService';
import { getComercios } from '../../../services/ComerciosService';
import { SVGICON } from '../../constant/theme';  // Asegúrate de que esta importación apunte a donde tienes los íconos

const PuntosVentaAdmin = () => {
    const salesPointRef = useRef();
    const editSalesPointRef = useRef();
    const [salesPoints, setSalesPoints] = useState([]);
    const [commerces, setCommerces] = useState([]);
    const [selectedCommerce, setSelectedCommerce] = useState(null);
    const userId = JSON.parse(localStorage.getItem('userDetails')).userData.id;

    useEffect(() => {
        const loadCommerces = async () => {
            try {
                const response = await getComercios(userId);
                const activeCommerces = response.data.map(commerce => ({
                    value: commerce.id,
                    label: commerce.name,
                }));
                setCommerces(activeCommerces);
        
                // Si solo hay un comercio, seleccionarlo automáticamente
                if (activeCommerces.length === 1) {
                    const selectedCommerce = activeCommerces[0];
                    setSelectedCommerce(selectedCommerce);
                    loadSalesPoints(selectedCommerce.value); // Cargar puntos de venta del comercio seleccionado
                }
            } catch (error) {
                console.log('Error al obtener comercios:', error);
                setCommerces([]);
            }
        };        

        loadCommerces();
    }, [userId]);

    useEffect(() => {
        if (selectedCommerce) {
            loadSalesPoints();
        }
    }, [selectedCommerce]);

    const loadSalesPoints = () => {
        getSalesPoints(selectedCommerce.value).then(response => {
            setSalesPoints(response.data || []);
            console.log("DATA PUNTOS DE VENTA", response.data);
        }).catch(error => {
            console.log('Error al obtener los puntos de venta', error);
            setSalesPoints([]);
        });
    };

    const handleSalesPointCreated = () => {
        loadSalesPoints();
    };

    const handleSalesPointUpdated = () => {
        loadSalesPoints();
    };

    const handleSalesPointDeleted = () => {
        loadSalesPoints();
    };

    const openEditModal = (id) => {
        editSalesPointRef.current.showEditSalesPointModal(id);
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <Tab.Container defaultActiveKey={'Grid'}>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h4 className="heading mb-0">Puntos de Venta</h4>
                            <div className="d-flex align-items-center">
                                <Nav as="ul" className="nav nav-pills mix-chart-tab user-m-tabe" id="pills-tab">
                                    <Nav.Item as="li" className="nav-item" role="presentation">
                                        <Nav.Link as="button" className="nav-link" eventKey={'List'}>
                                            {SVGICON.List}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as="li" className="nav-item">
                                        <Nav.Link as="button" className="nav-link" eventKey={'Grid'}>
                                            {SVGICON.GridDots}
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Link
                                    to="#"
                                    className="btn btn-primary btn-sm"
                                    onClick={() => salesPointRef.current.showSalesPointModal()}
                                    disabled={!selectedCommerce}
                                >+ Agregar Punto de Venta
                                </Link>
                            </div>
                        </div>
                        <div className='row mt-3 mb-4 pe-0'>
                            <div className='col-md-3 align-self-center'>
                                <label className="form-label my-0">Selecciona un Comercio <span className="text-danger">*</span></label>
                            </div>
                            <div className='col-md-9 px-0'>
                                <Select
                                    value={selectedCommerce}
                                    onChange={setSelectedCommerce}
                                    options={commerces}
                                    placeholder="Seleccionar Comercio"
                                    className="basic-single"
                                    classNamePrefix="select"
                                />
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div className="col-xl-12 active-p">
                                <Tab.Content>
                                    <Tab.Pane eventKey={'Grid'}>
                                        <PuntosDeVentaGridTab
                                            puntosVenta={salesPoints}
                                            tableName={"Mis Puntos de Venta"}
                                            openEditModal={openEditModal}
                                            onSalesPointDeleted={handleSalesPointDeleted}
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey={'List'}>
                                        <PuntosDeVentaListTab
                                            puntosVenta={salesPoints}
                                            tableName={"Mis Puntos de Venta"}
                                            openEditModal={openEditModal}
                                            onSalesPointDeleted={handleSalesPointDeleted}
                                        />
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>
                    </Tab.Container>
                </div>
            </div>
            <PuntosVentaCanvas
                ref={salesPointRef}
                Title="Agregar Punto de Venta"
                formType="puntoVenta"
                onSalesPointCreated={handleSalesPointCreated}
            />
            <PuntosVentaEditCanvas
                ref={editSalesPointRef}
                onSalesPointUpdated={handleSalesPointUpdated}
            />
        </>
    );
};

export default PuntosVentaAdmin;
