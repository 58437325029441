import React, { useMemo } from 'react';

const VentasResumen = ({ sales }) => {
    console.log('Ventas totalizar', sales);

    // Calculamos las ventas por tipo de pago solo cuando 'sales' cambian
    const { totalsByPaymentType, totalSales } = useMemo(() => {
        const totalsByPaymentType = {};
        let totalSales = 0;

        sales.forEach(sale => {
            const { paymentType, total } = sale;

            // Asegurarse de que 'total' sea tratado como número
            const saleTotal = parseFloat(total) || 0;

            // Validar y filtrar 'paymentType' que sea null o undefined
            const validPaymentType = paymentType || 'Otro';

            totalSales += saleTotal;

            if (!totalsByPaymentType[validPaymentType]) {
                totalsByPaymentType[validPaymentType] = 0;
            }

            // Sumar el total de la venta al tipo de pago correspondiente
            totalsByPaymentType[validPaymentType] += saleTotal;
        });

        return { totalsByPaymentType, totalSales };
    }, [sales]); // Solo recalcular si 'sales' cambia

    return (
        <div className="card">
            <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                    <div className="tbl-caption d-flex justify-content-between align-items-center p-3">
                        <h4 className="heading mb-0">Resumen de Ventas por Tipo de Pago</h4>
                    </div>
                    <table className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0 table-striped">
                        <thead>
                            <tr>
                                <th>Tipo de Pago</th>
                                <th className="text-end">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(totalsByPaymentType).map((paymentType, index) => {
                                const totalAmount = totalsByPaymentType[paymentType];
                                const formattedAmount = isNaN(totalAmount) ? 0 : totalAmount.toFixed(2); // Validar si es número

                                return (
                                    <tr key={index}>
                                        <td>{paymentType}</td>
                                        <td className="text-end">{formatearMoneda(formattedAmount)}</td>
                                    </tr>
                                );
                            })}
                            <tr>
                                <th>Total General</th>
                                <th className="text-end">{formatearMoneda(totalSales)}</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

// Función para formatear los valores como moneda
const formatearMoneda = (valor) => {
    if (valor === null || valor === undefined) return '-';
    return Number(valor).toLocaleString('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
};

export default VentasResumen;
