import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import Select from 'react-select';
import { getAllUserAssignmentsBySalesPoint } from "../../../../services/UsuariosComercioService";

const ModalSeleccionEmpleado = ({ show, onClose, onSave, salesPointId, empleadoPreseleccionado }) => {
  const [empleadoSeleccionado, setEmpleadoSeleccionado] = useState(null);
  const [empleados, setEmpleados] = useState([]);
  const [loading, setLoading] = useState(true);

  const cargarEmpleados = async () => {
    setLoading(true);
    try {
      const response = await getAllUserAssignmentsBySalesPoint(salesPointId);
      const empleadosActivos = response.data.filter(emp => emp.user.status === true);
      setEmpleados(empleadosActivos);
      setLoading(false);
    } catch (error) {
      console.error("Error al cargar empleados:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (show && salesPointId) {
      cargarEmpleados();
    }
  }, [show, salesPointId]);

  // Preseleccionar empleado asignado al abrir el modal
  useEffect(() => {
    if (show && empleadoPreseleccionado) {
      setEmpleadoSeleccionado(empleadoPreseleccionado);
    } else if (show) {
      setEmpleadoSeleccionado(null); // Resetear si no hay empleado preseleccionado
    }
  }, [show, empleadoPreseleccionado]);

  const guardarEmpleado = () => {
    onSave(empleadoSeleccionado || null); // Guardar el empleado seleccionado, si no hay ninguno asignar null
    onClose();
  };  

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Asignar empleado al servicio</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <p>Cargando empleados...</p>
        ) : (
          <div className="form-group">
            <label>Empleados Punto de venta</label>
            <Select
              value={empleadoSeleccionado}
              onChange={(selectedOption) => setEmpleadoSeleccionado(selectedOption)}
              options={empleados.map((emp) => ({
                label: `${emp.user.name}`,
                value: emp.user.id
              }))}
              placeholder="Seleccione un empleado"
              isClearable
              classNamePrefix="select2"
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={guardarEmpleado}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalSeleccionEmpleado;
