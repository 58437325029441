import React, { useState } from 'react';
import { format } from 'date-fns';
import { IMAGES } from '../../../constant/theme';
import { Modal, Button } from 'react-bootstrap';
import { updateProducto, deleteProducto } from '../../../../services/ProductosService';

const GridTab = ({ productos, openEditModal, onProductoDeleted }) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [productoToDelete, setProductoToDelete] = useState(null);
    const [showResultModal, setShowResultModal] = useState(false);
    const [resultMessage, setResultMessage] = useState('');
    const [resultVariant, setResultVariant] = useState('success');
    const [editedQuantities, setEditedQuantities] = useState({}); // Estado para almacenar las cantidades editadas

    const handleDeleteClick = (productoId) => {
        setProductoToDelete(productoId);
        setShowDeleteModal(true);
    };

    const confirmDeleteProducto = async () => {
        try {
            await deleteProducto(productoToDelete);
            setResultMessage('Producto eliminado exitosamente.');
            setResultVariant('success');
            onProductoDeleted(); // Llamar a la función para recargar la tabla
        } catch (error) {
            setResultMessage('Error al eliminar el producto. Intente nuevamente.');
            setResultVariant('danger');
            console.error('Error al eliminar el producto:', error);
        } finally {
            setShowDeleteModal(false);
            setShowResultModal(true);
        }
    };

    const handleQuantityChange = (id, newQuantity) => {
        setEditedQuantities((prevQuantities) => ({
            ...prevQuantities,
            [id]: newQuantity
        }));
    };

    const handleUpdateStock = async (productoId) => {
        const newQuantity = editedQuantities[productoId];
        if (!newQuantity || isNaN(newQuantity)) return;

        try {
            await updateProducto(productoId, { quantity: newQuantity });
            setResultMessage('Stock actualizado exitosamente.');
            setResultVariant('success');
            setShowResultModal(true);
            onProductoDeleted(); // Recargar productos después de la actualización
        } catch (error) {
            setResultMessage('Error al actualizar el stock. Intente nuevamente.');
            setResultVariant('danger');
            setShowResultModal(true);
        }
    };

    return (
        <>
            <div className="row">
                {productos.map((producto, index) => (
                    <div className="col-xl-3 col-lg-4 col-sm-6" key={index}>
                        <div className="card">
                            <div className="card-body">
                                <div className="card-use-box">
                                    <div className="crd-bx-img mb-3">
                                        <img src={producto.images.length ? producto.images[0] : IMAGES.DefaultProduct} className="rounded-circle img-120px" alt="Producto" />
                                        <div className={`active ${producto.quantity > 0 ? 'active' : 'deactive'}`}></div>
                                    </div>
                                    <div className="card__text">
                                        <h4 className="mb-0 text-capitalize">{producto.name}</h4>
                                        <p className='mb-0'>Código de Barras: {producto.barcode || "-"}</p>
                                        <p className="mt-0 d-flex text-center justify-content-center align-items-center">
                                            Cantidad: 
                                            <input
                                                type="number"
                                                className="form-control ms-2"
                                                style={{ maxWidth: '80px'}}
                                                value={editedQuantities[producto.id] ?? producto.quantity}
                                                onChange={(e) => handleQuantityChange(producto.id, e.target.value)}
                                            />
                                            <button
                                                className="btn btn-primary btn-sm ms-2"
                                                onClick={() => handleUpdateStock(producto.id)}
                                            >
                                                <i className="fa-sharp fa-light fa-circle-check"></i>
                                            </button>
                                        </p>
                                        <p>Precio de Compra: {formatearMoneda(producto.purchasePrice) || "-"}</p>
                                        <p>Precio de Venta: {formatearMoneda(producto.salePrice) || "-"}</p>
                                    </div>
                                    <div className="d-flex justify-content-center mt-3">
                                        <button className="btn btn-info btn-sm me-3" onClick={() => openEditModal(producto.id)}>Editar</button>
                                        <button className="btn btn-danger btn-sm" onClick={() => handleDeleteClick(producto.id)}>Eliminar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/* Modal de confirmación de eliminación */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar eliminación</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Estás seguro de que deseas eliminar este producto? Esta acción no se puede deshacer.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancelar</Button>
                    <Button variant="danger" onClick={confirmDeleteProducto}>Eliminar</Button>
                </Modal.Footer>
            </Modal>

            {/* Modal de resultado de la eliminación */}
            <Modal show={showResultModal} onHide={() => setShowResultModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{resultVariant === 'success' ? 'Éxito' : 'Error'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{resultMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowResultModal(false)}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

const formatearMoneda = (valor) => {
    if (valor === null || valor === undefined) return '-';
    return Number(valor).toLocaleString('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
  };

export default GridTab;
