import axios from 'axios';

// Crear un nuevo comercio
export async function createCommerce(formData) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/commerces`, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data' // Indicamos que estamos enviando archivos
        }
    });

    return response;
}

// Obtener comercios asociados a un usuario
export async function getComercios(userId) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/commerces/by-user/${userId}`, {
        headers: {
            Authorization: `Bearer ${token}` // Aquí enviamos el token como Bearer
        }
    });
    console.log("response comercios", response);
    return response;
}

// Servicio para subir documentos del comercio
export async function uploadCommerceDocument(commerceId, dto, file) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;
    console.log("DTO", dto);
    // FormData para subir archivo y otros datos
    const formData = new FormData();
    formData.append('file', file);
    formData.append('documentType', dto.documentType);
    formData.append('documentNumber', dto.documentNumber);

    const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/commerces/${commerceId}/upload`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`, // Aquí enviamos el token como Bearer
            }
        }
    );
    return response;
}

// Obtener documentos asociados a un comercio
export async function getCommerceDocuments(commerceId) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/commerces/${commerceId}/documents`, {
        headers: {
            Authorization: `Bearer ${token}` // Aquí enviamos el token como Bearer
        }
    });
    console.log("response documentos", response);
    return response;
}

// Eliminar documento de un comercio
export async function deleteCommerceDocument(commerceId, documentId) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/commerces/${commerceId}/documents/${documentId}`,
        {
            headers: {
                Authorization: `Bearer ${token}` // Aquí enviamos el token como Bearer
            }
        }
    );
    console.log("Documento eliminado", response);
    return response;
}

export async function updateCommerce(commerceId, formData) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.put(`${process.env.REACT_APP_API_URL}/commerces/${commerceId}`, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data' // Indicamos que estamos enviando archivos
        }
    });

    return response;
}

// Obtener un comercio por ID
export async function getCommerceById(commerceId) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/commerces/${commerceId}`, {
        headers: {
            Authorization: `Bearer ${token}` // Aquí enviamos el token como Bearer
        }
    });

    return response;
}

// Función para eliminar un comercio por su ID
export async function deleteCommerce(commerceId) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    try {
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/commerces/${commerceId}`, {
            headers: {
                Authorization: `Bearer ${token}`, // Aquí enviamos el token como Bearer
            },
        });
        return response;
    } catch (error) {
        console.error('Error al eliminar el comercio:', error);
        throw error; // Lanza el error para que pueda ser capturado por el componente
    }
}

// Conectar un comercio a la DIAN
export async function connectToDian(commerceId, dianData) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    // FormData para subir archivo del certificado y otros datos
    const formData = new FormData();
    formData.append('dianSoftwareId', dianData.dianSoftwareId);
    formData.append('dianSoftwarePin', dianData.dianSoftwarePin);
    formData.append('dianSenderId', dianData.dianSenderId);
    formData.append('dianCertificateFile', dianData.certificateFile);  // Asegúrate de que el nombre coincida
    formData.append('dianCertificatePassword', dianData.certificatePassword);
    formData.append('isProduction', dianData.isProduction);
    formData.append('testSetId', dianData.testSetId);  // Asegúrate de incluir el testSetId

    console.log("FormData:", dianData.isProduction);

    const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/dian/connect/${commerceId}`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`, // Aquí enviamos el token como Bearer
            }
        }
    );

    console.log("Conexión con DIAN:", response);
    return response;
}

// Obtener el estado de la configuración DIAN de un comercio
export async function getDianStatus(commerceId) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/dian/status/${commerceId}`, {
        headers: {
            Authorization: `Bearer ${token}` // Aquí enviamos el token como Bearer
        }
    });

    return response;
}

export async function updateDianConfig(commerceId, dianData) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    // FormData para subir archivo del certificado y otros datos
    const formData = new FormData();
    formData.append('dianSoftwareId', dianData.dianSoftwareId);
    formData.append('dianSoftwarePin', dianData.dianSoftwarePin);
    formData.append('dianSenderId', dianData.dianSenderId);
    formData.append('dianCertificateFile', dianData.certificateFile);  // Asegúrate de que el nombre coincida
    formData.append('dianCertificatePassword', dianData.certificatePassword);
    formData.append('isProduction', dianData.isProduction);
    formData.append('testSetId', dianData.testSetId);  // Asegúrate de incluir el testSetId

    const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/dian/connect/${commerceId}`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`, // Aquí enviamos el token como Bearer
            }
        }
    );

    console.log("Actualización de Conexión con DIAN:", response);
    return response;
}