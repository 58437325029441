import React, { useContext, useEffect, useReducer, useState } from "react";
import Collapse from 'react-bootstrap/Collapse';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux'; // Para usar el dispatch en Redux
import { MenuList } from './Menu';
import { ThemeContext } from "../../../context/ThemeContext";
import { Logout } from '../../../store/actions/AuthActions'; // Tu acción de Logout

// Función para obtener los datos del localStorage (userDetails, selectedCommerce, selectedSalesPoint)
const loadLocalStorageData = () => {
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const selectedCommerce = JSON.parse(localStorage.getItem('selectedCommerce'));
  const selectedSalesPoint = JSON.parse(localStorage.getItem('selectedSalesPoint'));

  return { userDetails, selectedCommerce, selectedSalesPoint };
};

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
};

const SideBar = () => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout, ChangeIconSidebar } = useContext(ThemeContext);
  const [state, setState] = useReducer(reducer, initialState);
  const [userRole, setUserRole] = useState(null); // Estado para el rol del usuario
  const [commerceLabel, setCommerceLabel] = useState('Sistema POS'); // Estado para el nombre del comercio
  const [salesPointLabel, setSalesPointLabel] = useState(''); // Estado para el nombre del punto de venta

  const location = useLocation(); // Hook para obtener la ubicación actual de la ruta
  const navigate = useNavigate(); // Hook para navegar entre rutas
  const dispatch = useDispatch(); // Hook para usar dispatch de Redux

  useEffect(() => {
    // Cargar datos del localStorage al montar el componente
    const { userDetails, selectedCommerce, selectedSalesPoint } = loadLocalStorageData();

    if (userDetails && userDetails.userData) {
      setUserRole(userDetails.userData.role.role); // Establecer el rol del usuario
    }

    // Establecer el nombre del comercio y del punto de venta si existen
    if (selectedCommerce) {
      setCommerceLabel(selectedCommerce.label);
    }
    if (selectedSalesPoint) {
      setSalesPointLabel(` - ${selectedSalesPoint.label}`);
    }

    // Escuchar cambios en el localStorage
    const handleStorageChange = () => {
      const { selectedCommerce, selectedSalesPoint } = loadLocalStorageData();
      if (selectedCommerce) {
        setCommerceLabel(selectedCommerce.label);
      }
      if (selectedSalesPoint) {
        setSalesPointLabel(` - ${selectedSalesPoint.label}`);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const currentPath = location.pathname.split("/").pop(); // Obtener la última parte de la URL
    // Marcar automáticamente el menú como activo si coincide con la URL actual
    MenuList.forEach((menu) => {
      if (menu.to && menu.to.includes(currentPath)) {
        setState({ active: menu.title });
      }
      menu.content?.forEach((subMenu) => {
        if (subMenu.to && subMenu.to.includes(currentPath)) {
          setState({ activeSubmenu: subMenu.title, active: menu.title });
        }
      });
    });
  }, [location]);

  const handleMenuActive = (status) => {
    setState({ active: status });
    if (state.active === status) {
      setState({ active: "" });
    }
  };

  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status });
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" });
    }
  };

  const handleLinkClick = (title, to) => {
    if (title === 'Cerrar Sesión') {
      dispatch(Logout(navigate)); // Llamar al logout y redirigir al usuario
    } else {
      setState({ active: title, activeSubmenu: "" }); // Mantén el estado del ítem activo después del clic
    }
  };

  // Asegurarse de no renderizar el menú si el rol del usuario aún no está disponible
  if (!userRole) {
    return null; // O un spinner de carga, por ejemplo
  }

  return (
    <div
      onMouseEnter={() => ChangeIconSidebar(true)}
      onMouseLeave={() => ChangeIconSidebar(false)}
      className={`deznav border-right ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? "fixed"
          : ""
      }`}
    >
      <div className="deznav-scroll">
        <ul className="metismenu" id="menu">
          {/* Renderizamos el título dinámico aquí */}
          <li className="menu-title mb-1 mt-2">{commerceLabel}{salesPointLabel}</li>

          {MenuList.map((data, index) => {
            let menuClass = data.classsChange;
            if (menuClass === "menu-title") {
              return (
                <li className={`${menuClass} mb-1 mt-2`} key={index}>
                  {data.title}
                </li>
              );
            } else {
              return (
                data.allowedRoles.includes(userRole) && ( // Condición para mostrar los ítems del menú basados en el rol del usuario
                  <li
                    className={`${state.active === data.title ? 'custom-active' : ''}`} // Usamos la clase custom-active
                    key={index}
                  >
                    {data.content && data.content.length > 0 ? (
                      <>
                        <Link
                          to={"#"}
                          className="has-arrow"
                          onClick={() => {
                            handleMenuActive(data.title);
                          }}
                        >
                          <div className="menu-icon">
                            {typeof data.iconStyle === "string" ? (
                              <i className={`${data.iconStyle} menu-icon-fa`}></i>
                            ) : (
                              data.iconStyle
                            )}
                          </div>
                          <span className="nav-text">
                            {data.title}
                            {data.update && data.update.length > 0 ? (
                              <span className="badge badge-xs badge-danger ms-2">
                                {data.update}
                              </span>
                            ) : (
                              ''
                            )}
                          </span>
                        </Link>
                        <Collapse in={state.active === data.title ? true : false}>
                          <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                            {data.content && data.content.map((subItem, subIndex) => {
                              return (
                                <li
                                  key={subIndex}
                                  className={`${
                                    state.activeSubmenu === subItem.title ? "custom-active" : ""
                                  }`}
                                >
                                  {subItem.content && subItem.content.length > 0 ? (
                                    <>
                                      <Link
                                        to={subItem.to}
                                        className={subItem.hasMenu ? 'has-arrow' : ''}
                                        onClick={() => {
                                          handleSubmenuActive(subItem.title);
                                        }}
                                      >
                                        {subItem.title}
                                      </Link>
                                      <Collapse in={state.activeSubmenu === subItem.title ? true : false}>
                                        <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                                          {subItem.content && subItem.content.map((subSubItem, subSubIndex) => {
                                            return (
                                              <li key={subSubIndex}>
                                                <Link
                                                  className={`${location.pathname.includes(subSubItem.to) ? "custom-active" : ""}`}
                                                  to={subSubItem.to}
                                                  onClick={() => handleLinkClick(subItem.title, subItem.to)}
                                                >
                                                  {subSubItem.title}
                                                </Link>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      </Collapse>
                                    </>
                                  ) : (
                                    <Link 
                                      to={subItem.to}
                                      className={`${location.pathname.includes(subItem.to) ? "custom-active" : ""}`}
                                      onClick={() => handleLinkClick(subItem.title, subItem.to)}
                                    >
                                      {subItem.title}
                                    </Link>
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        </Collapse>
                      </>
                    ) : (
                      <Link
                        to={data.to === "/logout" ? "#" : data.to}
                        onClick={() => handleLinkClick(data.title, data.to)}
                        className={`${location.pathname.includes(data.to) ? "custom-active" : ""}`}
                      >
                        <div className="menu-icon">
                          {typeof data.iconStyle === "string" ? (
                            <i className={`${data.iconStyle} menu-icon-fa`}></i>
                          ) : (
                            data.iconStyle
                          )}
                        </div>
                        <span className="nav-text ms-1">
                          {data.title}
                        </span>
                        {data.update && data.update.length > 0 ? (
                          <span className="badge badge-xs badge-danger ms-2">
                            {data.update}
                          </span>
                        ) : (
                          ''
                        )}
                      </Link>
                    )}
                  </li>
                )
              );
            }
          })}
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
