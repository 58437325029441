import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Offcanvas, Alert } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
import { createService } from '../../services/ServiciosService';
import Select from 'react-select';
import { getComercios } from '../../services/ComerciosService';
import { getSalesPoints } from '../../services/PuntosVentaService';

const ServicioCanvas = forwardRef((props, ref) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [basePrice, setBasePrice] = useState('');
    const [salePrice, setSalePrice] = useState('');
    const [commissionPercentage, setCommissionPercentage] = useState('');
    const [commerceId, setCommerceId] = useState(null);
    const [salesPoints, setSalesPoints] = useState([]);
    const [commerces, setCommerces] = useState([]);
    const [salesPointsOptions, setSalesPointsOptions] = useState([]);
    const [addServicio, setAddServicio] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [errors, setErrors] = useState({});

    useImperativeHandle(ref, () => ({
        showServicioModal() {
            setAddServicio(true);
            loadCommerces();
        }
    }));

    const loadCommerces = async () => {
        try {
            const userDetails = JSON.parse(localStorage.getItem('userDetails'));
            const adminId = userDetails?.userData?.id || null;
            const response = await getComercios(adminId);
            const activeCommerces = response.data.map(commerce => ({
                value: commerce.id,
                label: commerce.name,
            }));
            setCommerces(activeCommerces);
        } catch (error) {
            console.error('Error al cargar los comercios:', error);
        }
    };

    const loadSalesPoints = async (commerceId) => {
        try {
            const response = await getSalesPoints(commerceId);
            setSalesPointsOptions(response.data.map(salesPoint => ({
                value: salesPoint.id,
                label: salesPoint.name,
            })));
        } catch (error) {
            console.error('Error al cargar los puntos de venta:', error);
        }
    };

    useEffect(() => {
        if (commerceId) {
            loadSalesPoints(commerceId);
        }
    }, [commerceId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrors({});

        try {
            const formData = new FormData();
            formData.append('name', name.toLowerCase().trim());
            formData.append('description', description);
            formData.append('basePrice', parseFloat(basePrice.replace(/[^0-9.-]+/g, "")));
            formData.append('salePrice', parseFloat(salePrice.replace(/[^0-9.-]+/g, "")));
            formData.append('commissionPercentage', parseFloat(commissionPercentage.replace(/[^0-9.-]+/g, "")));
            formData.append('commerceId', commerceId);
            salesPoints.forEach(salesPoint => formData.append('salesPointIds', salesPoint.value));

            const response = await createService(formData);

            setAlertMessage('Servicio creado exitosamente');
            setAlertVariant('success');
            props.onServicioCreated(response.data);
            setTimeout(() => {
                setAddServicio(false);
                limpiarFormulario();
            }, 1000);
        } catch (error) {
            console.error('Error creando el servicio:', error);
            if (error.response?.data?.message) {
                const validationErrors = error.response.data.message.reduce((acc, curr) => {
                    const field = curr.split(' ')[0];
                    acc[field] = true;
                    return acc;
                }, {});
                setErrors(validationErrors);
            }
            setAlertMessage('Hubo un error creando el servicio. Intente nuevamente.');
            setAlertVariant('danger');
        } finally {
            setLoading(false);
        }
    };

    const limpiarFormulario = () => {
        setName('');
        setDescription('');
        setBasePrice('');
        setSalePrice('');
        setCommissionPercentage('');
        setCommerceId(null);
        setSalesPoints([]);
        setAlertMessage('');
        setAlertVariant('');
        setShowAlert(false);
        setErrors({});
    };

    return (
        <>
            <Offcanvas show={addServicio} onHide={() => setAddServicio(false)} className="offcanvas-end customeoff" placement='end'>
                <div className="offcanvas-header">
                    <h5 className="modal-title">{props.Title}</h5>
                    <button type="button" className="btn-close" onClick={() => setAddServicio(false)}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div className="offcanvas-body">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className="form-label">Nombre del Servicio <span className="text-danger">*</span></label>
                            <input
                                type="text"
                                className={`form-control ${errors.name ? 'border-danger' : ''}`}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Descripción</label>
                            <textarea
                                className={`form-control ${errors.description ? 'border-danger' : ''}`}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Precio Base <span className="text-danger">*</span></label>
                            <NumericFormat
                                className={`form-control ${errors.basePrice ? 'border-danger' : ''}`}
                                value={basePrice}
                                thousandSeparator="."
                                decimalSeparator="," 
                                prefix="$"
                                onValueChange={(values) => setBasePrice(values.value)}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Precio Venta <span className="text-danger">*</span></label>
                            <NumericFormat
                                className={`form-control ${errors.salePrice ? 'border-danger' : ''}`}
                                value={salePrice}
                                thousandSeparator="."
                                decimalSeparator="," 
                                prefix="$"
                                onValueChange={(values) => setSalePrice(values.value)}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Porcentaje de Comisión <span className="text-danger">*</span></label>
                            <NumericFormat
                                className={`form-control ${errors.commissionPercentage ? 'border-danger' : ''}`}
                                value={commissionPercentage}
                                suffix="%"
                                decimalSeparator="," 
                                onValueChange={(values) => setCommissionPercentage(values.value)}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label>Comercio</label>
                            <Select
                                value={commerces.find(c => c.value === commerceId)}
                                onChange={(selectedOption) => setCommerceId(selectedOption?.value || null)}
                                options={commerces}
                                placeholder="Seleccionar Comercio"
                                className={`basic-single ${errors.commerceId ? 'border-danger' : ''}`}
                                classNamePrefix="select"
                                isClearable
                            />
                        </div>
                        <div className="mb-3">
                            <label>Puntos de Venta</label>
                            <Select
                                value={salesPoints}
                                onChange={setSalesPoints}
                                options={salesPointsOptions}
                                placeholder="Seleccionar Puntos de Venta"
                                className={`basic-single ${errors.salesPointIds ? 'border-danger' : ''}`}
                                classNamePrefix="select"
                                isClearable
                                isMulti
                            />
                        </div>

                        {alertMessage && (
                            <Alert variant={alertVariant} className={`fade ${showAlert ? 'show' : ''}`}>
                                {alertMessage}
                            </Alert>
                        )}

                        <div className="mb-5 mt-3">
                            <button type="submit" className="btn btn-primary" disabled={loading}>
                                {loading ? 'Creando...' : 'Crear Servicio'}
                            </button>
                            <button type="button" className="btn btn-secondary ms-2" onClick={() => setAddServicio(false)}>
                                Cancelar
                            </button>
                        </div>
                    </form>
                </div>
            </Offcanvas>
        </>
    );
});

export default ServicioCanvas;
