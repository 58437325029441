import React, { useState } from 'react';
import { format } from 'date-fns';
import { IMAGES } from '../../../constant/theme';
import { Modal, Button } from 'react-bootstrap';
import { deleteCommerce } from '../../../../services/ComerciosService';

const GridTab = ({ comercios, openDocumentModal, openDianModal, openEditModal, onCommerceDeleted }) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [commerceToDelete, setCommerceToDelete] = useState(null);
    const [showResultModal, setShowResultModal] = useState(false);
    const [resultMessage, setResultMessage] = useState('');
    const [resultVariant, setResultVariant] = useState('success');

    // Función para abrir el modal de confirmación de eliminación
    const handleDeleteClick = (commerceId) => {
        setCommerceToDelete(commerceId);
        setShowDeleteModal(true);
    };

    // Función para confirmar la eliminación del comercio
    const confirmDeleteCommerce = async () => {
        try {
            await deleteCommerce(commerceToDelete);
            setResultMessage('Comercio eliminado exitosamente.');
            setResultVariant('success');
            onCommerceDeleted(); // Llamar a la función para recargar la tabla
        } catch (error) {
            setResultMessage('Error al eliminar el comercio. Intente nuevamente.');
            setResultVariant('danger');
            console.error('Error al eliminar el comercio:', error);
        } finally {
            setShowDeleteModal(false);
            setShowResultModal(true);
        }
    };

    return (
        <>
            <div className="row">
                {comercios.map((comercio, index) => (
                    <div className="col-xl-3 col-lg-4 col-sm-6" key={index}>
                        <div className="card">
                            <div className="card-body">
                                <div className="card-use-box">
                                    <div className="crd-bx-img mb-3">
                                        {/* Imagen del comercio o ícono predeterminado */}
                                        <img src={comercio.logoUrl ? comercio.logoUrl : IMAGES.Friends1} className="rounded-circle img-120px" alt="Comercio" />
                                        <div className={`active ${comercio.licenses.length > 0 && comercio.licenses[0].isActive ? 'active' : 'deactive'}`}></div>
                                    </div>
                                    <div className="card__text">
                                        <h4 className="mb-0">{comercio.name}</h4>
                                        <h5 className="mb-0">NIT: {comercio.nit || "-"}</h5>
                                        <p>Expiración Licencia: {comercio.licenses[0]?.expirationDate ? format(new Date(comercio.licenses[0].expirationDate), 'dd/MM/yyyy') : 'N/A'}</p>
                                        <p className='mb-0'>Dirección: {comercio.address || "-"}</p>
                                        <p className="mt-0">Teléfono: {comercio.phone || "-"}</p>
                                    </div>
                                    <ul className="card__info">
                                        <li>
                                            <span className="card__info__stats">Licencia:</span>
                                            <span>
                                                {comercio.licenses.length > 0 && comercio.licenses[0].isActive
                                                    ? 'Activa'
                                                    : 'Inactiva'}
                                            </span>
                                        </li>
                                        <li>
                                            <span className="card__info__stats">PIN:</span>
                                            {comercio.licenses.length > 0 && comercio.licenses[0].isActive ? (
                                                <span className="badge light badge-outline-success">
                                                    <strong> {comercio.licenses[0].licensePin}</strong>
                                                </span>
                                            ) : (
                                                <span className="badge light badge-outline-danger">-</span>
                                            )}
                                        </li>
                                    </ul>
                                    <ul className="post-pos">
                                        <li>
                                            <span className="card__info__stats me-2">Conexión DIAN: </span>
                                            {comercio.statusConnectionDian ? (
                                                <span className="badge light badge-outline-success">Conectado</span>
                                            ) : (
                                                <span className="badge light badge-outline-danger">Desconectado</span>
                                            )}
                                        </li>
                                        <li>
                                            <span className="card__info__stats">Fecha Creación: </span>
                                            <span>
                                                {format(new Date(comercio.createdAt), 'dd/MM/yyyy')}
                                            </span>
                                        </li>
                                    </ul>
                                    <div className="d-flex justify-content-between">
                                        <button
                                            className="btn btn-primary btn-sm"
                                            onClick={() => openDocumentModal(comercio.id, 'commerce', comercio.documents)}
                                        >
                                            Documentos Comercio
                                        </button>
                                        <button
                                            className="btn btn-secondary btn-sm"
                                            onClick={() => openDianModal(comercio.id, 'dian')}
                                        >
                                            Conectar DIAN
                                        </button>
                                    </div>
                                    <div className="d-flex justify-content-center mt-3">
                                        <button
                                            className="btn btn-info btn-sm me-3"
                                            onClick={() => openEditModal(comercio.id)}
                                        >
                                            Editar
                                        </button>
                                        <button
                                            className="btn btn-danger btn-sm"
                                            onClick={() => handleDeleteClick(comercio.id)}
                                        >
                                            Eliminar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/* Modal de confirmación de eliminación */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar eliminación</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Estás seguro de que deseas eliminar este comercio? Esta acción no se puede deshacer.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={confirmDeleteCommerce}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal de resultado de la eliminación */}
            <Modal show={showResultModal} onHide={() => setShowResultModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{resultVariant === 'success' ? 'Éxito' : 'Error'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {resultMessage}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowResultModal(false)}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default GridTab;
