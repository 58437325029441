import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { deleteSalesPoint } from '../../../../services/PuntosVentaService';

const PuntosDeVentaListTab = ({ puntosVenta = [], tableName, openEditModal, onSalesPointDeleted }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [checked, setChecked] = useState([]);
    const [allChecked, setAllChecked] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [salesPointToDelete, setSalesPointToDelete] = useState(null);
    const [showResultModal, setShowResultModal] = useState(false);
    const [resultMessage, setResultMessage] = useState('');
    const [resultVariant, setResultVariant] = useState('success');
    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = puntosVenta.slice(firstIndex, lastIndex);
    const totalPages = Math.ceil(puntosVenta.length / recordsPerPage);
    const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

    const changePage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const prePage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleChecked = (id) => {
        setChecked((prevChecked) =>
            prevChecked.includes(id)
                ? prevChecked.filter((item) => item !== id)
                : [...prevChecked, id]
        );
    };

    const handleCheckedAll = () => {
        if (allChecked) {
            setChecked([]);
        } else {
            setChecked(records.map((puntoVenta) => puntoVenta.id));
        }
        setAllChecked(!allChecked);
    };

    const exportToExcel = () => {
        const dataToExport = puntosVenta.map((puntoVenta) => ({
            'Punto de Venta': puntoVenta.name,
            'Ubicación': puntoVenta.location || "-",
            'Fecha Creación': format(new Date(puntoVenta.createdAt), 'dd/MM/yyyy HH:mm:ss'),
        }));

        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Puntos de Venta');

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'puntos-de-venta.xlsx');
    };

    const handleDeleteClick = (salesPointId) => {
        setSalesPointToDelete(salesPointId);
        setShowDeleteModal(true);
    };

    const confirmDeleteSalesPoint = async () => {
        try {
            await deleteSalesPoint(salesPointToDelete);
            setResultMessage('Punto de venta eliminado exitosamente.');
            setResultVariant('success');
            setChecked(checked.filter(id => id !== salesPointToDelete));
            onSalesPointDeleted(); // Llamar a la función para recargar la tabla
        } catch (error) {
            setResultMessage('Error al eliminar el punto de venta. Intente nuevamente.');
            setResultVariant('danger');
            console.error('Error al eliminar el punto de venta:', error);
        } finally {
            setShowDeleteModal(false);
            setShowResultModal(true);
        }
    };

    return (
        <>
            <div className="card">
                <div className="card-body p-0">
                    <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                        <div className="tbl-caption d-flex justify-content-between align-items-center">
                            <h4 className="heading mb-0">{tableName}</h4>
                            <button className="btn btn-primary light btn-sm me-2" onClick={exportToExcel}>
                                <i className="fa-solid fa-file-excel" /> Exportar Reporte
                            </button>
                        </div>
                        <div id="punto-venta-tbl_wrapper" className="dataTables_wrapper no-footer">
                            <table id="projects-tbl" className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0">
                                <thead>
                                    <tr>
                                        <th>
                                            <div className="form-check custom-checkbox ms-0">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={allChecked}
                                                    onChange={handleCheckedAll}
                                                />
                                                <label className="form-check-label" />
                                            </div>
                                        </th>
                                        <th>Punto de Venta</th>
                                        <th>Ubicación</th>
                                        <th>Fecha Creación</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {records.length > 0 ? (
                                        records.map((puntoVenta) => (
                                            <tr key={puntoVenta.id}>
                                                <td>
                                                    <div className="form-check custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            checked={checked.includes(puntoVenta.id)}
                                                            onChange={() => handleChecked(puntoVenta.id)}
                                                        />
                                                        <label className="form-check-label" />
                                                    </div>
                                                </td>
                                                <td>{puntoVenta.name}</td>
                                                <td>{puntoVenta.location || "-"}</td>
                                                <td>
                                                    {format(new Date(puntoVenta.createdAt), 'dd/MM/yyyy HH:mm:ss')}
                                                </td>
                                                <td>
                                                    <button
                                                        className="btn btn-info btn-sm me-2"
                                                        onClick={() => openEditModal(puntoVenta.id)}
                                                    >
                                                        Editar
                                                    </button>
                                                    <button
                                                        className="btn btn-danger btn-sm"
                                                        onClick={() => handleDeleteClick(puntoVenta.id)}
                                                    >
                                                        Eliminar
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr><td colSpan="5" className="text-center">No hay puntos de venta disponibles.</td></tr>
                                    )}
                                </tbody>
                            </table>

                            <div className="d-sm-flex text-center justify-content-between align-items-center">
                                <div className="dataTables_info">
                                    Mostrando {firstIndex + 1} a {Math.min(lastIndex, puntosVenta.length)} de {puntosVenta.length} entradas
                                </div>
                                <div className="dataTables_paginate paging_simple_numbers justify-content-center">
                                    <Link className="paginate_button previous" to="#" onClick={prePage}>
                                        <i className="fa-solid fa-angle-left" />
                                    </Link>
                                    <span>
                                        {pageNumbers.map((number) => (
                                            <Link
                                                className={`paginate_button ${currentPage === number ? 'current' : ''}`}
                                                key={number}
                                                to="#"
                                                onClick={() => changePage(number)}
                                            >
                                                {number}
                                            </Link>
                                        ))}
                                    </span>
                                    <Link className="paginate_button next" to="#" onClick={nextPage}>
                                        <i className="fa-solid fa-angle-right" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar eliminación</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Estás seguro de que deseas eliminar este punto de venta? Esta acción no se puede deshacer.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={confirmDeleteSalesPoint}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showResultModal} onHide={() => setShowResultModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{resultVariant === 'success' ? 'Éxito' : 'Error'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {resultMessage}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowResultModal(false)}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PuntosDeVentaListTab;
