import axios from 'axios';

// Crear un nuevo servicio
export async function createService(formData) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/services`, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    });

    return response;
}

// Obtener servicios asociados a un usuario
export async function getServices(userId) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/services/by-user/${userId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    return response;
}

// Actualizar un servicio
export async function updateService(serviceId, formData) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.put(`${process.env.REACT_APP_API_URL}/services/${serviceId}`, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    });

    return response;
}

// Obtener un servicio por ID
export async function getServiceById(serviceId) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/services/${serviceId}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    return response;
}

// Eliminar un servicio
export async function deleteService(serviceId) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    try {
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/services/${serviceId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        return response;
    } catch (error) {
        console.error('Error al eliminar el servicio:', error);
        throw error;
    }
}

// Obtener servicios por comercio
export async function getServicesByCommerce(commerceId) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/services/commerce/${commerceId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    });
    return response;
}

// Obtener servicios por punto de venta
export async function getServicesBySalesPoint(salesPointId) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/services/sales-point/${salesPointId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    });
    return response;
}
