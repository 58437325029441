import React, { useState, useEffect } from "react";
import BuscarProductosServicios from "./BuscarProductosServicios";
import CarritoVentas from "./CarritoVentas";
import FormularioVenta from "./FormularioVenta";
import { listTaxes } from "../../../../services/VentaService"; // Servicio para listar impuestos

const CrearVentaAdmin = () => {
  const [carrito, setCarrito] = useState([]);
  const [total, setTotal] = useState(0);
  const [impuestos, setImpuestos] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [taxes, setTaxes] = useState([]);
  const [assignedUser, setAssignedUser] = useState({}); // Aquí agregamos el estado de usuarios asignados

  // Función para obtener commerceId y salePointId del local storage
  const getCommerceAndSalePointIds = () => {
    const commerceData = localStorage.getItem("selectedCommerce");
    const salePointData = localStorage.getItem("selectedSalesPoint");
    const userDetails = localStorage.getItem("userDetails");
  
    const commerceId = commerceData ? JSON.parse(commerceData).value : null;
    const salePointId = salePointData ? JSON.parse(salePointData).value : null;
    const userData = userDetails ? JSON.parse(userDetails).userData : null;
  
    return { commerceId, salePointId, userData, commerceData, salePointData };
  };

  const { commerceId, salePointId, userData, commerceData, salePointData } = getCommerceAndSalePointIds();

  // Obtener lista de impuestos al cargar la página
  useEffect(() => {
    const fetchTaxes = async () => {
      try {
        const response = await listTaxes();
        console.log('Impuestos cargados:', response); // Verificamos que los impuestos se carguen correctamente
        setTaxes(response);
      } catch (error) {
        console.error("Error al obtener impuestos:", error);
      }
    };
  
    fetchTaxes();
  }, []);
  

  const agregarAlCarrito = (item) => {
    const itemExistente = carrito.find(i => i.id === item.id); 
    if (itemExistente) {
      const nuevoCarrito = carrito.map(i =>
        i.id === item.id ? { ...i, cantidad: i.cantidad + 1 } : i
      );
      setCarrito(nuevoCarrito);
    } else {
      setCarrito([...carrito, { ...item, cantidad: 1 }]);
    }
  };

  return (
    <div className="crear-venta-admin container-fluid">
      <div className="row">
        <div className="col-md-5">
          <BuscarProductosServicios agregarAlCarrito={agregarAlCarrito} />
        </div>
        <div className="col-md-7">
          <CarritoVentas
            carrito={carrito}
            setCarrito={setCarrito}
            subtotal={subtotal}
            setSubtotal={setSubtotal}
            impuestos={impuestos}
            setImpuestos={setImpuestos}
            total={total}
            setTotal={setTotal}
            taxes={taxes}
            salesPointId={salePointId}
            assignedUser={assignedUser} // Pasamos el estado actual de usuarios asignados
            setAssignedUser={setAssignedUser} // Pasamos la función de actualización de usuarios asignados
          />
          <FormularioVenta
            carrito={carrito}
            setCarrito={setCarrito} // Pasamos la función para limpiar el carrito
            total={total}
            subtotal={subtotal}
            impuestos={impuestos}
            setImpuestos={setImpuestos} // Pasar la función para limpiar los impuestos
            setSubtotal={setSubtotal} // Pasar la función para limpiar el subtotal
            setTotal={setTotal} // Pasar la función para limpiar el total
            commerceId={commerceId}
            salesPointId={salePointId}
            userData={userData} // Pasamos userData
            assignedUser={assignedUser} // Pasamos el estado de empleados asignados
            setAssignedUser={setAssignedUser} // Aseguramos que podemos limpiar el estado de empleados
            commerceData={commerceData}
            salePointData={salePointData}
          />
        </div>
      </div>
    </div>
  );
};

export default CrearVentaAdmin;
