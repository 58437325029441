import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Tab, Nav } from 'react-bootstrap';
import { SVGICON } from '../../constant/theme';
import GridTab from '../AppsMenu/Productos/GridTab';
import ProductoCanvas from '../../constant/ProductoCanvas';
import ProductoEditCanvas from '../../constant/ProductoEditCanvas';
import ListTab from '../AppsMenu/Productos/ListTab';
import Select from 'react-select';
import { getProductosByCommerce, getProductosBySalesPoint } from '../../../services/ProductosService';
import { getComercios } from '../../../services/ComerciosService';
import { getSalesPoints } from '../../../services/PuntosVentaService';

const ProductosAdmin = () => {
    const userdata = useRef();
    const editProductoRef = useRef(); // Asegúrate de que la referencia está bien inicializada
    const [productos, setProductos] = useState([]);
    const [commerces, setCommerces] = useState([]);
    const [salesPoints, setSalesPoints] = useState([]);
    const [selectedCommerce, setSelectedCommerce] = useState(null);
    const [selectedSalesPoint, setSelectedSalesPoint] = useState(null);
    
    // Obtener detalles del usuario desde el localStorage
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const userId = userDetails.userData.id;
    const userRole = userDetails.userData.role?.role;

    useEffect(() => {
        // Si el rol es 'usuario-comercio', cargar los valores almacenados en localStorage
        if (userRole === 'usuario-comercio') {
            const storedCommerce = JSON.parse(localStorage.getItem('selectedCommerce'));
            const storedSalesPoint = JSON.parse(localStorage.getItem('selectedSalesPoint'));

            if (storedCommerce && storedSalesPoint) {
                setSelectedCommerce(storedCommerce);
                setSelectedSalesPoint(storedSalesPoint);
            }
        } else {
            loadCommerces(); // Cargar comercios para administradores
        }
    }, [userId, userRole]);

    // Cargar productos según los filtros seleccionados (comercio/punto de venta)
    useEffect(() => {
        if (selectedSalesPoint) {
            loadProductosBySalesPoint(selectedSalesPoint.value);
        } else if (selectedCommerce) {
            loadProductosByCommerce(selectedCommerce.value);
        } else {
            setProductos([]); // Limpiar productos si no hay filtros seleccionados
        }
    }, [selectedCommerce, selectedSalesPoint]);

    const loadCommerces = async () => {
        try {
            const response = await getComercios(userId);
            const activeCommerces = response.data.map(commerce => ({
                value: commerce.id,
                label: commerce.name,
            }));
            setCommerces(activeCommerces);
    
            // Si solo hay un comercio, seleccionarlo automáticamente
            if (activeCommerces.length === 1) {
                const selectedCommerce = activeCommerces[0];
                setSelectedCommerce(selectedCommerce);
                loadSalesPoints(selectedCommerce.value); // Cargar puntos de venta del comercio seleccionado
            }
        } catch (error) {
            console.log('Error al obtener comercios:', error);
            setCommerces([]);
        }
    };    

    const loadSalesPoints = async (commerceId) => {
        try {
            const response = await getSalesPoints(commerceId);
            const points = response.data.map(salesPoint => ({
                value: salesPoint.id,
                label: salesPoint.name,
            }));
            setSalesPoints(points);
        } catch (error) {
            console.log('Error al obtener puntos de venta:', error);
            setSalesPoints([]);
        }
    };

    const loadProductosByCommerce = async (commerceId) => {
        try {
            const response = await getProductosByCommerce(commerceId);
            setProductos(response.data);
        } catch (error) {
            console.log('Error al obtener productos por comercio:', error);
        }
    };

    const loadProductosBySalesPoint = async (salesPointId) => {
        try {
            const response = await getProductosBySalesPoint(salesPointId);
            setProductos(response.data);
        } catch (error) {
            console.log('Error al obtener productos por punto de venta:', error);
        }
    };

    const handleProductoCreated = () => {
        if (selectedSalesPoint) {
            loadProductosBySalesPoint(selectedSalesPoint.value);
        } else if (selectedCommerce) {
            loadProductosByCommerce(selectedCommerce.value);
        }
    };

    const handleProductoUpdated = () => {
        handleProductoCreated();
    };

    const handleProductoDeleted = () => {
        handleProductoCreated();
    };

    const openEditModal = (id) => {
        // Llamada a la referencia del modal de edición y pasando el id del producto
        if (editProductoRef.current) {
            console.log('Abriendo modal de edición con ID:', id);
            editProductoRef.current.showEditProductoModal(id);
        } else {
            console.error('Referencia del modal de edición no está disponible.');
        }
    };    

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <Tab.Container defaultActiveKey={'List'}>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h4 className="heading mb-0">Productos</h4>
                            <div className="d-flex align-items-center">
                                <Nav as="ul" className="nav nav-pills mix-chart-tab user-m-tabe" id="pills-tab">
                                    <Nav.Item as="li" className="nav-item">
                                        <Nav.Link as="button" className="nav-link" eventKey={'List'}>
                                            {SVGICON.List}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as="li" className="nav-item">
                                        <Nav.Link as="button" className="nav-link" eventKey={'Grid'}>
                                            {SVGICON.GridDots}
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                {userRole === 'administrador' && (
                                <Link to className="btn btn-primary btn-sm ms-2"
                                    onClick={() => userdata.current.showProductoModal()}
                                >+ Agregar Producto
                                </Link>
                                )}
                            </div>
                        </div>
                        {/* Mostrar los filtros de comercio y punto de venta si el rol no es 'usuario-comercio' */}
                        {userRole !== 'usuario-comercio' && (
                            <>
                                <div className='row mt-3 mb-4 pe-0'>
                                    <div className='col-md-3 align-self-center'>
                                        <label className="form-label my-0">Filtrar por Comercio</label>
                                    </div>
                                    <div className='col-md-9 px-0'>
                                        <Select
                                            value={selectedCommerce}
                                            onChange={selectedOption => {
                                                setSelectedCommerce(selectedOption);
                                                setSelectedSalesPoint(null); // Limpiar puntos de venta al cambiar comercio
                                                if (selectedOption) {
                                                    loadSalesPoints(selectedOption.value);
                                                } else {
                                                    setSalesPoints([]); // Limpiar puntos de venta si no hay comercio seleccionado
                                                    setProductos([]);
                                                }
                                            }}
                                            options={commerces}
                                            placeholder="Seleccionar Comercio"
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isClearable
                                        />
                                    </div>
                                </div>
                                {selectedCommerce && (
                                    <div className='row mt-0 mb-4 pe-0'>
                                        <div className='col-md-3 align-self-center'>
                                            <label className="form-label my-0">Filtrar por Punto de Venta</label>
                                        </div>
                                        <div className='col-md-9 px-0'>
                                            <Select
                                                value={selectedSalesPoint}
                                                onChange={setSelectedSalesPoint}
                                                options={salesPoints}
                                                placeholder="Seleccionar Punto de Venta"
                                                className="basic-single"
                                                classNamePrefix="select"
                                                isClearable
                                            />
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                        <div className="col-xl-12 active-p">
                            <Tab.Content>
                                <Tab.Pane eventKey={'Grid'}>
                                    <GridTab
                                        productos={productos}
                                        tableName={"Mis Productos"}
                                        openEditModal={openEditModal}
                                        onProductoDeleted={handleProductoDeleted} 
                                    />
                                </Tab.Pane>
                                <Tab.Pane eventKey={'List'}>
                                    <ListTab
                                        productos={productos}
                                        tableName={"Mis Productos"}
                                        openEditModal={openEditModal}
                                        onProductoDeleted={handleProductoDeleted}
                                    />
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </Tab.Container>
                </div>
            </div>
            <ProductoCanvas
                ref={userdata}
                Title="Agregar Producto"
                formType="producto"
                onProductoCreated={handleProductoCreated}
            />
            <ProductoEditCanvas
                ref={editProductoRef}
                onProductoUpdated={handleProductoUpdated} // Asegúrate de que el evento de actualización llama correctamente el modal
            />
        </>
    );
};

export default ProductosAdmin;
