import React, { useState, useEffect } from 'react';
import InicioGrid from './InicioDashboardGrid';

const InicioAdmin = () => {
  const [userName, setUserName] = useState('');

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (userDetails && userDetails?.userData?.name) {
      setUserName(userDetails.userData.name);
    }
  }, []); // El efecto se ejecuta solo una vez, cuando se monta el componente

  return (
    <>
      <div className="container-fluid">
        <h3 className="mb-4">Bienvenido <span className="text-secondary">{userName}</span> a <span className="text-secondary">Tú</span> Sistema POS</h3>
        <InicioGrid />
      </div>
    </>
  );
};

export default InicioAdmin;
