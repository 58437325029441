import axios from 'axios';

// Crear un nuevo producto
export async function createProducto(formData) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/products`, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }
    });

    return response;
}

// Obtener productos asociados a un usuario
export async function getProductos(userId) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/products/by-user/${userId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    console.log("response productos", response);
    return response;
}

// Actualizar un producto
export async function updateProducto(productoId, formData) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;
    console.log("Enviando servicio de actualiacion producto", productoId);
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/products/${productoId}`, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });

    return response;
}

// Obtener un producto por ID
export async function getProductoById(productoId) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/products/${productoId}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    return response;
}

// Eliminar un producto
export async function deleteProducto(productoId) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    try {
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/products/${productoId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        return response;
    } catch (error) {
        console.error('Error al eliminar el producto:', error);
        throw error;
    }
}

// Obtener productos por comercio
export async function getProductosByCommerce(commerceId) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/products/commerce/${commerceId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    });
    return response;
}

// Obtener productos por punto de venta
export async function getProductosBySalesPoint(salesPointId) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const tokenDetails = JSON.parse(tokenDetailsString);
    const token = tokenDetails.token;

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/products/sales-point/${salesPointId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    });
    return response;
}