import React, { useEffect } from 'react';
import Select from 'react-select';// Usamos react-select para simular select2

const SelectCategory = ({ selectedCategory, setSelectedCategory }) => {
    // Aquí definimos un listado de categorías de comercio en Colombia
    const categoryOptions = [
        { value: 'papeleria', label: 'Papelería' },
        { value: 'restaurante', label: 'Restaurante' },
        { value: 'panaderia', label: 'Panadería' },
        { value: 'supermercado', label: 'Supermercado' },
        { value: 'ferreteria', label: 'Ferretería' },
        { value: 'farmacia', label: 'Farmacia' },
        { value: 'boutique', label: 'Boutique' },
        { value: 'tienda-de-ropa', label: 'Tienda de Ropa' },
        { value: 'tienda-de-comestibles', label: 'Tienda de Comestibles' },
        { value: 'cafe', label: 'Café' },
        { value: 'bar', label: 'Bar' },
        { value: 'salon-de-belleza', label: 'Salón de Belleza' },
        { value: 'gimnasio', label: 'Gimnasio' },
        { value: 'lavanderia', label: 'Lavandería' },
        { value: 'negocio', label: 'Negocio de barrio' },
    ];

    useEffect(() => {
        // Asegúrate de que la categoría preseleccionada esté establecida correctamente en el estado
        if (selectedCategory && !categoryOptions.find(category => category.value === selectedCategory.value)) {
            setSelectedCategory(null);
        }
    }, [selectedCategory]);

    return (
        <div className="mb-3">
            <label className="form-label">Categoría del Comercio <span className="text-danger">*</span></label>
            <Select
                value={selectedCategory}
                onChange={(selected) => setSelectedCategory(selected)}
                options={categoryOptions}
                placeholder="Seleccione una categoría"
                isClearable
            />
        </div>
    );
};

export default SelectCategory;
