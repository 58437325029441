import React from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

const ListTab = ({ clientes, tableName }) => {

    return (
        <div className="card">
            <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                    <div className="tbl-caption d-flex justify-content-between align-items-center">
                        <h4 className="heading mb-0">{tableName}</h4>
                    </div>
                    <div id="clientes-tbl_wrapper" className="dataTables_wrapper no-footer">
                        <table id="clientes-tbl" className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0">
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Documento</th>
                                    <th>Teléfono</th>
                                    <th>Correo</th>
                                    <th>Fecha de Registro</th>
                                </tr>
                            </thead>
                            <tbody>
                                {clientes.length > 0 ? (
                                    clientes.map((cliente) => (
                                        <tr key={cliente.id}>
                                            <td className="text-capitalize">{cliente.name}</td>
                                            <td>{cliente.documentType} - {cliente.document}</td>
                                            <td>{cliente.phone || '-'}</td>
                                            <td>{cliente.email || '-'}</td>
                                            <td>{format(new Date(cliente.createdAt), 'dd/MM/yyyy HH:mm:ss')}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5" className="text-center">No hay clientes disponibles</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        <div className="d-sm-flex text-center justify-content-between align-items-center">
                            <div className="dataTables_info">
                                Mostrando 1 a {clientes.length} de {clientes.length} entradas
                            </div>
                            <div className="dataTables_paginate paging_simple_numbers justify-content-center">
                                {/* Aquí podrías agregar paginación si fuera necesario */}
                                <Link className="paginate_button previous" to="#">
                                    <i className="fa-solid fa-angle-left" />
                                </Link>
                                <Link className="paginate_button next" to="#">
                                    <i className="fa-solid fa-angle-right" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListTab;
