import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';
import { getAllCommerceAssignmentsByUser, getAllSalesPointAssignmentsByUserAndCommerce } from '../../../services/UsuariosComercioService'; // Ajusta la ruta del archivo

const CommerceSelectionModal = ({ show, onSave }) => {
  const [commerceOptions, setCommerceOptions] = useState([]);
  const [selectedCommerce, setSelectedCommerce] = useState(null);
  const [salesPointOptions, setSalesPointOptions] = useState([]);
  const [selectedSalesPoint, setSelectedSalesPoint] = useState(null);

  useEffect(() => {
    const fetchCommerces = async () => {
      try {
        const userDetails = JSON.parse(localStorage.getItem('userDetails'));
        const userId = userDetails?.userData?.id; // Ajusta según tus datos
        const response = await getAllCommerceAssignmentsByUser(userId);
  
        // Revisa la estructura de la respuesta
        console.log("Comercios asociados al usuario: ", response);
  
        // Mapea correctamente los comercios
        const commerces = response.data.map(item => ({
          value: item.commerce.id,
          label: item.commerce.name
        }));
  
        setCommerceOptions(commerces);
      } catch (error) {
        console.error('Error fetching commerces:', error);
      }
    };
  
    fetchCommerces();
  }, []);  

  const handleCommerceChange = async (selectedOption) => {
    setSelectedCommerce(selectedOption);
    setSelectedSalesPoint(null);

    if (selectedOption) {
      try {
        const userDetails = JSON.parse(localStorage.getItem('userDetails'));
        const userId = userDetails?.userData?.id;
        
        // Llamar al servicio con el userId y commerceId (selectedOption.value)
        const response = await getAllSalesPointAssignmentsByUserAndCommerce(userId, selectedOption.value);

        // Mapeo de los puntos de venta
        const salesPoints = response.data.map(item => ({
          value: item.salesPoint.id,
          label: item.salesPoint.name
        }));
        
        setSalesPointOptions(salesPoints);
      } catch (error) {
        console.error('Error fetching sales points:', error);
      }
    }
  };

  const handleSave = () => {
    if (selectedCommerce && selectedSalesPoint) {
      localStorage.setItem('selectedCommerce', JSON.stringify(selectedCommerce));
      localStorage.setItem('selectedSalesPoint', JSON.stringify(selectedSalesPoint));
      
      // Notifica otros componentes sobre los cambios en el localStorage
      window.dispatchEvent(new Event('storage'));
      
      onSave();
    }
  };

  return (
    <Modal
      show={show}
      backdrop="static"
      centered
    >
      <Modal.Header>
        <Modal.Title>Seleccione su Comercio y Punto de Venta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <label>Seleccione un Comercio:</label>
          <Select
            options={commerceOptions}
            value={selectedCommerce}
            onChange={handleCommerceChange}
            placeholder="Seleccione un comercio"
          />
        </div>
        <div className="mb-3">
          <label>Seleccione un Punto de Venta:</label>
          <Select
            options={salesPointOptions}
            value={selectedSalesPoint}
            onChange={setSelectedSalesPoint}
            placeholder="Seleccione un punto de venta"
            isDisabled={!selectedCommerce}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSave} disabled={!selectedCommerce || !selectedSalesPoint}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CommerceSelectionModal;
